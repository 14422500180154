import React, { useState } from 'react'
import {
  SettingSectionHeading,
  SettingSectionSubHeading,
} from '../settings-section-heading'
import { AutomationTable } from './table/automation-table'
import { Link } from 'react-router-dom'
import { Sparkle } from '@phosphor-icons/react'
import Button from '../../../../components/ui/button'
import { AutomationsTemplatesModal } from './modals/automations-templates-modal'
import { useAutomations } from './hooks/use-automations'
import Loader from '../../../../components/ui/loader'
import { useOnboardingDisplay } from './hooks/use-onboarding-display'
import { AutomationOnboarding } from './automation-onboarding'
import { automationLearnMoreLink } from './tools/constants'
import { OwnersProvider } from './contexts/owners-context'

function AutomationsList() {
  const [isOpen, setIsOpen] = useState(false)
  const { isFirstLoading, isTotalEmpty, ...automation } = useAutomations({
    onlyTemplates: false,
  })
  const { isDisplayed, setDisplayed } = useOnboardingDisplay(automation)

  if (isFirstLoading) return <Loader minHeight='28rem' />
  if (isTotalEmpty && !isDisplayed)
    return <AutomationOnboarding onStart={() => setDisplayed(true)} />

  return (
    <div className='tw-p-6'>
      <div className='tw-flex tw-flex-wrap tw-items-center tw-gap-3 md:tw-flex-nowrap'>
        <div className='tw-flex-1'>
          <SettingSectionHeading learnMoreLink={automationLearnMoreLink}>
            Automations
          </SettingSectionHeading>

          <SettingSectionSubHeading className='tw-mb-0'>
            Set up reminders for your company
          </SettingSectionSubHeading>
        </div>

        <button
          onClick={() => setIsOpen(true)}
          className='tw-flex tw-flex-row tw-items-center tw-gap-1'
        >
          <Sparkle size={20} />
          <span className='tw-font-bold'>Templates</span>
        </button>
        <Button
          to='/automations/add'
          tag={Link}
          color='link'
          className='tw-align-self-center tw-mr-2 !tw-px-0'
        >
          Add New
        </Button>
      </div>

      <AutomationTable onShowAll={() => setIsOpen(true)} {...automation} />

      <AutomationsTemplatesModal
        isOpen={isOpen}
        toggle={() => setIsOpen((isOpen) => !isOpen)}
      />
    </div>
  )
}

export function AutomationsTab() {
  return (
    <OwnersProvider>
      <AutomationsList />
    </OwnersProvider>
  )
}
