import { useReactFlow, useStore } from '@xyflow/react'
import { flushSync } from 'react-dom'

import { d3HierarchyLayout } from './d3-auto-layout'

export function useFitLayout({ options }) {
  const { setNodes, setEdges, fitView } = useReactFlow()

  const elements = useStore((state) => {
    return { nodes: state.nodes, edges: state.edges }
  })

  function fitLayout() {
    // Pass in a clone of the nodes and edges so that we don't mutate the
    // original elements.
    const nodes = elements.nodes.map((node) => ({ ...node }))
    const edges = elements.edges.map((edge) => ({ ...edge }))

    const { nodes: nextNodes, edges: nextEdges } = d3HierarchyLayout({
      nodes,
      edges,
      options,
    })

    flushSync(() => {
      setNodes(nextNodes)
      setEdges(nextEdges)
    })

    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        fitView()
      })
    })
  }

  return { fitLayout }
}
