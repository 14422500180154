import { eachMonthOfInterval, endOfMonth, format, startOfMonth } from 'date-fns'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Card, Modal, ModalBody, ModalHeader } from 'reactstrap'
import toastr from 'toastr'

import { useSelector } from 'react-redux'
import documentReady from '../../assets/images/document_success.svg'
import emptyTable from '../../assets/images/empty_icon.svg'
import { HeaderLogo } from '../../components/Common/header-logo'
import { ModalCloseButton } from '../../components/Common/modal-close-button'
import { CustomDropDown } from '../../components/Dropdowns/custom-drop-down'
import CustomDateRange from '../../components/Forms/custom-date-range'
import CustomSelect from '../../components/Forms/CustomSelect/CustomSelect'
import Button from '../../components/ui/button'
import { CheckItem } from '../../components/ui/check-item'
import DataTable from '../../components/ui/data-table'
import Loader from '../../components/ui/loader'
import PageHeading from '../../components/ui/page-heading'
import { useFetch } from '../../helpers/hooks'
import {
  checkExportStatus,
  exportContractsReport,
  exportExpensesReport,
  exportInvoicesReport,
  exportTeamsReport,
  exportTimeOffHistoryReport,
  exportTimeOffPerMonthReport,
  exportTimeOffReport,
  exportTransactionsReport,
  exportWorksReport,
  getAttributes,
  getContractList,
  getContractsReport,
  getDirectManagersList,
  getExpensesReport,
  getInvoicesReport,
  getTeamsReport,
  getTimeOffHistoryReport,
  getTimeOffPerMonthReport,
  getTimeOffReport,
  getTransactionsReport,
  getWorksReport,
} from '../../services/api'
import { getDepartmentsList } from '../../services/api-org-charts'
import { getTimeOffPolicies } from '../../services/api-time-off-policies'
import { getErrorMessage } from '../../utils/get-errors'
import { mapCountryToOption, mapListToOption } from '../../utils/map-to-option'
import { CONTRACT_TYPES_SELECT_OPTIONS } from '../Contract/utils/constants'
import { DepartmentColor } from '../org-chart/department-colors'
import FEATURE_FLAGS from '../../config/feature-flags'
import { getFullName } from '../../utils/get-full-name'

const reportTypes = {
  contracts_report: { label: 'Contracts report', value: 'contract' },
  invoices_report: { label: 'Invoices report', value: 'invoice' },
  expenses_report: { label: 'Expenses report', value: 'expense' },
  transactions_report: { label: 'Transactions report', value: 'trx' },
  team_report: { label: 'Team report', value: 'team' },
  work_report: { label: 'Work report', value: 'work' },
  time_off_report: {
    label: 'Time off report',
    value: 'time_off',
  },
  time_off_history_report: {
    label: 'Time off balance report',
    value: 'time_off_history',
  },
  time_off_per_month_report: {
    label: 'Time off per month report',
    value: 'time_off_per_month',
  },
  other_report: { label: 'Other', value: 'other' },
}
const contractTypes = [
  { label: 'Fixed', value: 'fixed' },
  { label: 'PAYG', value: 'payg' },
  { label: 'Milestone', value: 'milestone' },
  { label: 'Direct Employee', value: 'direct_employee' },
  { label: 'Full Time', value: 'full_time' },
]
// const employeeTypes = [
//   { label: 'Worker', value: 'contractor' },
//   { label: 'Employee', value: 'employee' },
// ]
const teamContractStatuses = [
  { label: 'Ongoing', value: 4 },
  { label: 'Amended', value: 8 },
  { label: 'Ended', value: 5 },
  { label: 'Terminated', value: 7 },
]
const otherReportTypes = {
  contracts_with_no_work: {
    label: 'Contracts with no submitted work',
    value: 'contracts_with_no_work',
  },
}

const workStatuses = [
  { label: 'Approved', value: 3 },
  { label: 'Pending approval', value: 2 },
  { label: 'Not submitted', value: 1 },
  { label: 'Declined', value: 4 },
]

const contractStatuses = [
  { label: 'Pending invite', value: 1 },
  { label: 'Pending contractor signature', value: 2 },
  { label: 'Pending client signature', value: 3 },
  { label: 'Ongoing', value: 4 },
  { label: 'Amended', value: 8 },
  { label: 'Ended', value: 5 },
  { label: 'Cancelled', value: 6 },
  { label: 'Terminated', value: 7 },
]

const TEAMS_DEFAULT_COLUMNS = [
  { label: 'Contract ID', value: 'contract_ref' },
  { label: 'Role', value: 'role' },
  { label: 'Full name', value: 'name' },
  { label: 'Email', value: 'email' },
  { label: 'Country of residence', value: 'country_of_residence' },
  { label: 'Nationality', value: 'nationality' },
  { label: 'Date of birth', value: 'date_of_birth' },
  { label: 'Tenure', value: 'tenure' },
  {
    label: 'Department',
    value: 'department',
  },
  {
    label: 'Direct Manager',
    value: 'direct_manager',
  },
  { label: 'Phone', value: 'phone' },
  {
    label: 'Contract Type',
    value: 'contract_type',
  },
]
const TRANSACTIONS_DEFAULT_COLUMNS = [
  { label: 'Transaction ID', value: 'id' },
  { label: 'Date', value: 'date' },
  { label: 'Type', value: 'type' },
  { label: 'Status', value: 'status' },
  { label: 'Contract ID', value: 'contract_id' },
  { label: 'Role', value: 'role' },
  { label: 'Worker name', value: 'contractor' },
  { label: 'Worker email', value: 'contractor_email' },
  { label: 'Work Details', value: 'work_details' },
  { label: 'Amount', value: 'amount' },
  { label: 'Currency', value: 'payment_currency' },
  { label: 'Transaction Amount', value: 'transaction_amount' },
  { label: 'Transaction Currency', value: 'transaction_currency' },
  { label: 'Transaction Total', value: 'transaction_total' },
  { label: 'Tags', value: 'tags' },
]
const EXPENSES_DEFAULT_COLUMNS = [
  { label: 'Expense', value: 'name' },
  { label: 'Contract ID', value: 'contract_ref' },
  { label: 'Worker name', value: 'contractor' },
  { label: 'Category', value: 'category' },
  { label: 'Date', value: 'date' },
  { label: 'Amount', value: 'amount' },
  { label: 'Currency', value: 'currency' },
  { label: 'Converted Amount', value: 'converted_amount' },
  { label: 'Contract Currency', value: 'contract_currency' },
  { label: 'Status', value: 'status' },
]
const CONTRACTS_DEFAULT_COLUMNS = [
  { label: 'Contract ID', value: 'id' },
  { label: 'Role', value: 'role' },
  { label: 'Worker name', value: 'name' },
  { label: 'Profile Type', value: 'profile_type' },
  { label: 'Creation date', value: 'created_at' },
  { label: 'Start date', value: 'start_date' },
  { label: 'End date', value: 'end_date' },
  { label: 'Amount', value: 'amount' },
  { label: 'Currency', value: 'currency' },
  { label: 'Contract Type', value: 'type' },
  { label: 'Status', value: 'status' },
  { label: 'Tag', value: 'tag' },
  { label: 'Notice Period', value: 'notice_period' },
]
const WORK_DEFAULT_COLUMNS = [
  { label: 'Work', value: 'name' },
  { label: 'Contract ID', value: 'contract_ref' },
  { label: 'Worker name', value: 'contractor' },
  { label: 'Submission Date', value: 'date' },
  { label: 'Rate', value: 'rate' },
  { label: 'Amount', value: 'amount' },
  { label: 'Currency', value: 'currency' },
  { label: 'Status', value: 'status' },
]
const INVOICES_DEFAULT_COLUMNS = [
  { label: 'Invoice ID', value: 'id' },
  { label: 'Contract ID', value: 'contract_id' },
  { label: 'Worker name', value: 'contractor' },
  { label: 'Date', value: 'date' },
  { label: 'Work details', value: 'work_details' },
  { label: 'Amount', value: 'amount' },
  { label: 'Currency', value: 'payment_currency' },
  { label: 'Paid Amount', value: 'paid_amount' },
  { label: 'Paid Currency', value: 'paid_currency' },
  { label: 'Total', value: 'total' },
  { label: 'Currency', value: 'currency' },
  { label: 'Tax Residence', value: 'tax_residence' },
]
const TIMEOFF_PER_MONTH_DEFAULT_COLUMNS = [
  { label: 'Month', value: 'month' },
  { label: 'Total requested and approved', value: 'total' },
]
const TIMEOFF_DEFAULT_COLUMNS = [
  { label: 'Contract ID', value: 'contract_id' },
  { label: 'Worker name', value: 'employee_name' },
  { label: 'From', value: 'from' },
  { label: 'To', value: 'to' },
  { label: 'Policy type', value: 'type' },
  { label: 'Total Days', value: 'total' },
  { label: 'Status', value: 'status' },
  { label: 'Reason', value: 'reason' },
]
const TIMEOFF_HISTORY_DEFAULT_COLUMNS = [
  { label: 'Contract ID', value: 'contract_id' },
  { label: 'Worker name', value: 'employee_name' },
  { label: 'Policy', value: 'policy_name' },
  { label: 'Approved days', value: 'approved_days' },
  { label: 'Pending approval days', value: 'pending_approval_days' },
  { label: 'Balance', value: 'total_time_off' },
]

const dateFormatValue = 'yyyy-MM'
const dateFormatLabel = 'MMMM yyy'

export default function CustomReports() {
  const { countries, time_off_types: timeOffTypes } = useSelector(
    (state) => state.Layout?.staticData ?? {},
  )

  const { data: policies, isLoading: policiesLoading } = useFetch({
    action: getTimeOffPolicies,
    autoFetch: true,
    initResult: [],
    onError: (error) => {
      toastr.error(error)
    },
  })

  const policyOptions = useMemo(
    () => policies.map((p) => ({ name: p.name, id: p.id })),
    [policies],
  )

  const months = useMemo(() => {
    return eachMonthOfInterval({
      start: new Date(2020, 0),
      end: new Date(),
    })
      .reverse()
      .map((d) => ({
        value: format(d, dateFormatValue),
        label: format(d, dateFormatLabel),
      }))
  }, [])
  const [openExportModal, setOpenExportModal] = useState(null)

  const initialTableFilters = {
    months: [],
    start_date: startOfMonth(new Date()),
    end_date: endOfMonth(new Date()),
    date: null,
    contract_status_ids: [],
    contract_types: [],
    contract_ids: [],
    status_ids: [],
    work_status_ids: [],
    contract_type: null,
    country_id: null,
    country_ids: [],
    time_off_type_id: null,
    time_off_policy_id: null,
    department_id: null,
    employee_type: null,
    department_ids: [],
    direct_manager_contract_ids: [],
  }
  const [tableDataFilters, setTableDataFilters] = useState(initialTableFilters)

  const [filterReportType, setFilterReportType] = useState(
    reportTypes.contracts_report.value,
  )
  const [otherReport, setOtherReport] = useState(
    otherReportTypes.contracts_with_no_work,
  )

  const exportReportsActions = [
    {
      label: 'Excel',
      onClick: () => setOpenExportModal('excel'),
    },
    { label: 'CSV', onClick: () => setOpenExportModal('csv') },
  ]

  const { data: contractsList, isLoading: contractsLoading } = useFetch({
    action: getContractList,
    autoFetch: true,
  })

  const { data: directManagersList, isLoading: directManagersLoading } =
    useFetch({
      action: getDirectManagersList,
      autoFetch: true,
    })

  const groupedContractListOptions = useMemo(
    () =>
      contractsList?.reduce((acc, contract) => {
        const _option = {
          value: contract?.id,
          label: `${contract.contractor?.full_name || ''} [${contract.name} / ${
            contract?.ref
          }]`,
        }

        if (acc?.map((p) => p.label)?.includes(contract?.type)) {
          const foundIndex = acc.findIndex((p) => p.label === contract?.type)
          acc[foundIndex].options.push(_option)
        } else {
          acc.push({
            label: contract?.type,
            options: [_option],
          })
        }

        return acc
      }, []),
    [contractsList],
  )

  const directManagersOptions = useMemo(
    () =>
      directManagersList?.map((dm) => ({
        label: getFullName(dm?.contractor) + ` (${dm.role})`,
        value: dm?.id,
        id: dm?.id,
      })),
    [directManagersList],
  )

  const { data: departmentsList, isLoading: departmentsLoading } = useFetch({
    action: getDepartmentsList,
    autoFetch: true,
  })

  const contractsTableData = useFetch({
    action: getContractsReport,
    autoFetch: false,
  })

  const invoicesTableData = useFetch({
    action: getInvoicesReport,
    autoFetch: false,
  })

  const transactionsTableData = useFetch({
    action: getTransactionsReport,
    autoFetch: false,
  })

  const expensesTableData = useFetch({
    action: getExpensesReport,
    autoFetch: false,
  })

  const teamsTableData = useFetch({
    action: getTeamsReport,
    autoFetch: false,
  })

  const worksTableData = useFetch({
    action: getWorksReport,
    autoFetch: false,
  })

  const timeOffsPerMonthTableData = useFetch({
    action: getTimeOffPerMonthReport,
    autoFetch: false,
  })

  const timeOffTableData = useFetch({
    action: getTimeOffReport,
    autoFetch: false,
  })

  const timeOffHistoryTableData = useFetch({
    action: getTimeOffHistoryReport,
    autoFetch: false,
  })

  const showCustomFields = ['work', 'contract'].includes(filterReportType)

  const { data: customFields } = useFetch(
    {
      action: getAttributes,
      autoFetch: showCustomFields,
      initResult: [],
      body: { active: 1, area: filterReportType },
    },
    [showCustomFields, filterReportType],
  )

  const reportColumns = useMemo(() => {
    switch (filterReportType) {
      case reportTypes.contracts_report.value:
        return CONTRACTS_DEFAULT_COLUMNS
      case reportTypes.other_report.value:
        switch (otherReport.value) {
          case 'contracts_with_no_work':
            return CONTRACTS_DEFAULT_COLUMNS
        }
        break
      case reportTypes.invoices_report.value:
        return INVOICES_DEFAULT_COLUMNS
      case reportTypes.expenses_report.value:
        return EXPENSES_DEFAULT_COLUMNS
      case reportTypes.transactions_report.value:
        return TRANSACTIONS_DEFAULT_COLUMNS
      case reportTypes.team_report.value:
        return TEAMS_DEFAULT_COLUMNS
      case reportTypes.work_report.value:
        return WORK_DEFAULT_COLUMNS
      case reportTypes.time_off_per_month_report.value:
        return TIMEOFF_PER_MONTH_DEFAULT_COLUMNS
      case reportTypes.time_off_report.value:
        return TIMEOFF_DEFAULT_COLUMNS
      case reportTypes.time_off_history_report.value:
        return TIMEOFF_HISTORY_DEFAULT_COLUMNS
    }
  }, [filterReportType, otherReport])

  useEffect(() => {
    switch (filterReportType) {
      case reportTypes.contracts_report.value:
        contractsTableData.startFetch({
          months: tableDataFilters?.months,
          status_ids: tableDataFilters?.status_ids,
          contract_types: tableDataFilters?.contract_types,
          selected_columns: CONTRACTS_DEFAULT_COLUMNS.map((e) => e.value),
        })
        break
      case reportTypes.other_report.value:
        switch (otherReport.value) {
          case 'contracts_with_no_work':
            contractsTableData.startFetch({
              months: tableDataFilters?.months,
              no_work: 1,
              contract_types: tableDataFilters?.contract_types,
              selected_columns: CONTRACTS_DEFAULT_COLUMNS.map((e) => e.value),
            })
            break
        }
        break
      case reportTypes.invoices_report.value:
        invoicesTableData.startFetch({
          months: tableDataFilters?.months,
          selected_columns: INVOICES_DEFAULT_COLUMNS.map((e) => e.value),
        })
        break
      case reportTypes.expenses_report.value:
        expensesTableData.startFetch({
          months: tableDataFilters?.months,
          selected_columns: EXPENSES_DEFAULT_COLUMNS.map((e) => e.value),
        })
        break
      case reportTypes.transactions_report.value:
        transactionsTableData.startFetch({
          months: tableDataFilters?.months,
          selected_columns: TRANSACTIONS_DEFAULT_COLUMNS.map((e) => e.value),
        })
        break
      case reportTypes.team_report.value:
        teamsTableData.startFetch({
          contract_status_ids: tableDataFilters?.contract_status_ids,
          selected_columns: TEAMS_DEFAULT_COLUMNS.map((e) => e.value),
          contract_types: tableDataFilters?.contract_types,
          country_ids: tableDataFilters?.country_ids,
          contract_ids: tableDataFilters?.contract_ids,
          department_ids: tableDataFilters?.department_ids,
          direct_manager_contract_ids:
            tableDataFilters?.direct_manager_contract_ids,
        })
        break
      case reportTypes.work_report.value:
        worksTableData.startFetch({
          months: tableDataFilters?.months,
          work_status_ids: tableDataFilters?.work_status_ids,
          selected_columns: WORK_DEFAULT_COLUMNS.map((e) => e.value),
        })
        break
      case reportTypes.time_off_per_month_report.value:
        timeOffsPerMonthTableData.startFetch()
        break
      case reportTypes.time_off_report.value:
        timeOffTableData.startFetch({
          start_date: tableDataFilters?.start_date,
          end_date: tableDataFilters?.end_date,
          country_id: tableDataFilters?.country_id,
          department_id: tableDataFilters?.department_id,
          time_off_type_id: tableDataFilters?.time_off_type_id,
          contract_type: tableDataFilters?.contract_type,
          contract_ids: tableDataFilters?.contract_ids,
          employee_type: tableDataFilters?.employee_type,
        })
        break
      case reportTypes.time_off_history_report.value:
        timeOffHistoryTableData.startFetch({
          date: tableDataFilters?.date,
          country_id: tableDataFilters?.country_id,
          department_id: tableDataFilters?.department_id,
          time_off_policy_id: tableDataFilters?.time_off_policy_id,
          contract_type: tableDataFilters?.contract_type,
          employee_type: tableDataFilters?.employee_type,
          contract_ids: tableDataFilters?.contract_ids,
        })
        break
    }
  }, [tableDataFilters])

  useEffect(() => {
    // reset filters
    setTableDataFilters(initialTableFilters)
  }, [filterReportType])

  const filteredDataTable = useMemo(() => {
    switch (filterReportType) {
      case reportTypes.contracts_report.value:
        return contractsTableData.data?.list || []
      case reportTypes.invoices_report.value:
        return invoicesTableData.data?.list || []
      case reportTypes.expenses_report.value:
        return expensesTableData.data?.list || []
      case reportTypes.transactions_report.value:
        return transactionsTableData.data?.list || []
      case reportTypes.team_report.value:
        return teamsTableData.data?.list || []
      case reportTypes.work_report.value:
        return worksTableData.data?.list || []
      case reportTypes.time_off_per_month_report.value:
        return timeOffsPerMonthTableData.data?.list || []
      case reportTypes.time_off_report.value:
        return timeOffTableData.data?.list || []
      case reportTypes.time_off_history_report.value:
        return timeOffHistoryTableData.data?.list || []
      case reportTypes.other_report.value:
        switch (otherReport.value) {
          case 'contracts_with_no_work':
            return contractsTableData.data?.list || []
        }
    }
  }, [
    filterReportType,
    otherReport.value,
    contractsTableData.data,
    invoicesTableData.data,
    transactionsTableData.data,
    expensesTableData.data,
    teamsTableData.data,
    worksTableData.data,
    timeOffsPerMonthTableData.data,
    timeOffTableData.data,
    timeOffHistoryTableData.data,
  ])

  const getTableHeader = (tableData) => {
    return tableData
      ? Object.entries(tableData?.columns).map(([key, value]) => {
          return { Header: value, accessor: key }
        })
      : []
  }

  const tableHeader = useMemo(() => {
    switch (filterReportType) {
      case reportTypes.contracts_report.value:
        return getTableHeader(contractsTableData.data)
      case reportTypes.invoices_report.value:
        return getTableHeader(invoicesTableData.data)
      case reportTypes.expenses_report.value:
        return getTableHeader(expensesTableData.data)
      case reportTypes.transactions_report.value:
        return getTableHeader(transactionsTableData.data)
      case reportTypes.team_report.value:
        return getTableHeader(teamsTableData.data)
      case reportTypes.work_report.value:
        return getTableHeader(worksTableData.data)
      case reportTypes.time_off_per_month_report.value:
        return getTableHeader(timeOffsPerMonthTableData.data)
      case reportTypes.time_off_report.value:
        return getTableHeader(timeOffTableData.data)
      case reportTypes.time_off_history_report.value:
        return getTableHeader(timeOffHistoryTableData.data)
      case reportTypes.other_report.value:
        switch (otherReport.value) {
          case 'contracts_with_no_work':
            return getTableHeader(contractsTableData.data)
        }
        break
      default:
        return []
    }
  }, [
    filterReportType,
    otherReport,
    contractsTableData.data,
    invoicesTableData.data,
    expensesTableData.data,
    transactionsTableData.data,
    teamsTableData.data,
    worksTableData.data,
    timeOffsPerMonthTableData.data,
    timeOffTableData.data,
    timeOffHistoryTableData.data,
  ])

  const isTableDataLoading =
    contractsTableData.isLoading ||
    transactionsTableData.isLoading ||
    invoicesTableData.isLoading ||
    expensesTableData.isLoading ||
    worksTableData.isLoading ||
    timeOffsPerMonthTableData.isLoading ||
    timeOffTableData.isLoading ||
    teamsTableData.isLoading ||
    timeOffHistoryTableData.isLoading

  return (
    <div className='page-content'>
      <PageHeading>
        <PageHeading.Title subtitle='Export detailed reports'>
          Generate report
        </PageHeading.Title>
      </PageHeading>

      <div className='tw-mb-5 tw-flex tw-flex-col tw-flex-wrap tw-gap-3 md:tw-flex-row'>
        <CustomSelect
          placeholder='Report type'
          defaultValue={reportTypes.contracts_report}
          options={Object.values(reportTypes)}
          wrapperStyles={{ minWidth: 192 }}
          onChange={(reportType) => {
            setFilterReportType(reportType?.value)
          }}
        />
        <CustomSelect
          wrapperClassName={
            reportTypes.team_report.value === filterReportType
              ? ''
              : 'tw-hidden'
          }
          placeholder='Contract status'
          isMulti
          options={teamContractStatuses}
          wrapperStyles={{ minWidth: 192 }}
          onChange={(_teamContractStatuses) => {
            // For team report
            setTableDataFilters((prevState) => ({
              ...prevState,
              contract_status_ids: _teamContractStatuses?.map((c) => c?.value),
            }))
          }}
        />
        <CustomSelect
          wrapperClassName={
            reportTypes.contracts_report.value === filterReportType
              ? ''
              : 'tw-hidden'
          }
          placeholder='Contract status'
          isMulti
          options={contractStatuses}
          wrapperStyles={{ minWidth: 192 }}
          onChange={(_contractStatuses) => {
            // For contract report
            setTableDataFilters((prevState) => ({
              ...prevState,
              status_ids: _contractStatuses?.map((c) => c?.value),
            }))
          }}
        />
        <CustomSelect
          wrapperClassName={
            [
              reportTypes.contracts_report.value,
              reportTypes.team_report.value,
            ].includes(filterReportType)
              ? ''
              : 'tw-hidden'
          }
          placeholder='Contract types'
          isMulti
          options={contractTypes}
          wrapperStyles={{ minWidth: 192 }}
          onChange={(_contractTypes) => {
            // For contract report
            setTableDataFilters((prevState) => ({
              ...prevState,
              contract_types: _contractTypes?.map((c) => c?.value),
            }))
          }}
        />
        <CustomSelect
          wrapperClassName={
            reportTypes.work_report.value === filterReportType
              ? ''
              : 'tw-hidden'
          }
          placeholder='All works'
          isMulti
          options={workStatuses}
          wrapperStyles={{ minWidth: 192 }}
          onChange={(_workStatuses) => {
            setTableDataFilters((prevState) => ({
              ...prevState,
              work_status_ids: _workStatuses?.map((c) => c?.value),
            }))
          }}
        />
        <CustomSelect
          wrapperClassName={
            reportTypes.other_report.value === filterReportType
              ? ''
              : 'tw-hidden'
          }
          placeholder='Select type'
          wrapperStyles={{ minWidth: 172 }}
          value={otherReport}
          options={Object.values(otherReportTypes)}
          onChange={setOtherReport}
        />
        <CustomSelect
          wrapperClassName={
            [
              reportTypes.invoices_report.value,
              reportTypes.expenses_report.value,
              reportTypes.transactions_report.value,
              reportTypes.work_report.value,
              reportTypes.other_report.value,
            ].includes(filterReportType)
              ? ''
              : 'tw-hidden'
          }
          isMulti
          placeholder='Months'
          options={months}
          value={months.filter((_m) =>
            tableDataFilters.months?.includes(_m.value),
          )}
          wrapperStyles={{ minWidth: 172 }}
          onChange={(_months) => {
            setTableDataFilters((prevState) => ({
              ...prevState,
              months: _months?.map((c) => c?.value),
            }))
          }}
        />

        {/* Might be needed later */}
        {/* <CustomDatePicker
          wrapperStyles={{ minWidth: 140 }}
          wrapperClassName={
            reportTypes.time_off_history_report.value === filterReportType
              ? ''
              : 'tw-hidden'
          }
          clearable
          placeholder='Filter by date'
          maxDate={endOfYear(new Date())}
          value={tableDataFilters.date}
          handleOnChange={(newDate) => {
            setTableDataFilters((prev) => ({
              ...prev,
              date: newDate,
            }))
          }}
          handleClear={() => {
            setTableDataFilters((prev) => ({
              ...prev,
              date: null,
            }))
          }}
        /> */}

        <CustomDateRange
          wrapperStyles={{ minWidth: 220 }}
          wrapperClassName={
            reportTypes.time_off_report.value === filterReportType
              ? ''
              : 'tw-hidden'
          }
          name='dateRange'
          value={[tableDataFilters?.start_date, tableDataFilters?.end_date]}
          placeholder='Select date range'
          clearable
          handleClear={() => {
            setTableDataFilters((prevState) => ({
              ...prevState,
              start_date: null,
              end_date: null,
            }))
          }}
          onChange={(newDates) => {
            setTableDataFilters((prevState) => ({
              ...prevState,
              start_date: newDates?.[0],
              end_date: newDates?.[1],
            }))
          }}
        />
        <CustomSelect
          wrapperStyles={{ minWidth: 220 }}
          wrapperClassName={
            [
              reportTypes.team_report.value,
              reportTypes.time_off_report.value,
              reportTypes.time_off_history_report.value,
            ].includes(filterReportType)
              ? ''
              : 'tw-hidden'
          }
          placeholder='Worker'
          isMulti
          options={groupedContractListOptions}
          isClearable
          clearValue={() => {
            setTableDataFilters((prevState) => ({
              ...prevState,
              contract_ids: [],
            }))
          }}
          onChange={(_contractTypes) => {
            setTableDataFilters((prevState) => ({
              ...prevState,
              contract_ids: _contractTypes?.map((c) => c?.value),
            }))
          }}
          isLoading={contractsLoading}
          isDisabled={contractsLoading}
        />
        <CustomSelect
          wrapperStyles={{ minWidth: 220 }}
          wrapperClassName={
            reportTypes.team_report.value === filterReportType
              ? ''
              : 'tw-hidden'
          }
          placeholder='Direct Managers'
          isMulti
          options={directManagersOptions}
          isClearable
          clearValue={() => {
            setTableDataFilters((prevState) => ({
              ...prevState,
              direct_manager_contract_ids: [],
            }))
          }}
          onChange={(dmContracts) => {
            setTableDataFilters((prevState) => ({
              ...prevState,
              direct_manager_contract_ids: dmContracts?.map((c) => c?.id),
            }))
          }}
          isLoading={directManagersLoading}
          isDisabled={directManagersLoading}
        />
        <CustomSelect
          wrapperStyles={{ minWidth: 200 }}
          wrapperClassName={
            [
              reportTypes.time_off_report.value,
              reportTypes.time_off_history_report.value,
            ].includes(filterReportType)
              ? ''
              : 'tw-hidden'
          }
          placeholder='Contract type'
          options={CONTRACT_TYPES_SELECT_OPTIONS}
          isClearable
          clearValue={() =>
            setTableDataFilters((prevState) => ({
              ...prevState,
              contract_type: null,
            }))
          }
          onChange={(_contractType) =>
            setTableDataFilters((prevState) => ({
              ...prevState,
              contract_type: _contractType?.value,
            }))
          }
        />
        <CustomSelect
          wrapperStyles={{ minWidth: 200 }}
          wrapperClassName={
            reportTypes.time_off_report.value === filterReportType
              ? ''
              : 'tw-hidden'
          }
          placeholder='Policy type'
          options={timeOffTypes?.map(({ id, name }) => {
            return {
              label: name,
              value: id,
            }
          })}
          isClearable
          clearValue={() =>
            setTableDataFilters((prevState) => ({
              ...prevState,
              time_off_type_id: null,
            }))
          }
          onChange={(_timeOff) =>
            setTableDataFilters((prevState) => ({
              ...prevState,
              time_off_type_id: _timeOff?.value,
            }))
          }
        />
        <CustomSelect
          wrapperStyles={{ minWidth: 200 }}
          wrapperClassName={
            reportTypes.time_off_history_report.value === filterReportType
              ? ''
              : 'tw-hidden'
          }
          placeholder='Policy'
          isDisabled={policiesLoading}
          options={policyOptions?.map(mapListToOption)}
          isClearable
          clearValue={() =>
            setTableDataFilters((prevState) => ({
              ...prevState,
              time_off_policy_id: null,
            }))
          }
          onChange={(_policy) =>
            setTableDataFilters((prevState) => ({
              ...prevState,
              time_off_policy_id: _policy?.value,
            }))
          }
        />
        <CustomSelect
          wrapperStyles={{ minWidth: 220 }}
          wrapperClassName={
            [
              reportTypes.time_off_report.value,
              reportTypes.time_off_history_report.value,
            ].includes(filterReportType)
              ? ''
              : 'tw-hidden'
          }
          placeholder='Department'
          options={departmentsList?.map(({ id, name, color }) => {
            return {
              label: name,
              value: id,
              icon: <DepartmentColor color={color} />,
            }
          })}
          isClearable
          clearValue={() =>
            setTableDataFilters((prevState) => ({
              ...prevState,
              department_id: null,
            }))
          }
          onChange={(_dept) =>
            setTableDataFilters((prevState) => ({
              ...prevState,
              department_id: _dept?.value,
            }))
          }
          isLoading={departmentsLoading}
          isDisabled={departmentsLoading}
        />
        <CustomSelect
          wrapperStyles={{ minWidth: 220 }}
          wrapperClassName={
            reportTypes.team_report.value === filterReportType
              ? ''
              : 'tw-hidden'
          }
          placeholder='Departments'
          options={departmentsList?.map(({ id, name, color }) => {
            return {
              label: name,
              value: id,
              icon: <DepartmentColor color={color} />,
            }
          })}
          isMulti
          isClearable
          clearValue={() =>
            setTableDataFilters((prevState) => ({
              ...prevState,
              department_id: null,
            }))
          }
          onChange={(_depts) =>
            setTableDataFilters((prevState) => ({
              ...prevState,
              department_ids: _depts.map((_dept) => _dept?.value),
            }))
          }
          isLoading={departmentsLoading}
          isDisabled={departmentsLoading}
        />
        {/* @todo: (Worker type filter) to be deleted */}
        {/* <CustomSelect
          wrapperStyles={{ minWidth: 220 }}
          wrapperClassName={
            [
              reportTypes.time_off_report.value,
              reportTypes.time_off_history_report.value,
            ].includes(filterReportType)
              ? ''
              : 'tw-hidden'
          }
          placeholder='Worker type'
          options={employeeTypes}
          isClearable
          clearValue={() =>
            setTableDataFilters((prevState) => ({
              ...prevState,
              employee_type: null,
            }))
          }
          onChange={(_dept) =>
            setTableDataFilters((prevState) => ({
              ...prevState,
              employee_type: _dept?.value,
            }))
          }
        /> */}
        <CustomSelect
          wrapperStyles={{ minWidth: 220 }}
          wrapperClassName={
            [
              reportTypes.time_off_report.value,
              reportTypes.time_off_history_report.value,
            ].includes(filterReportType)
              ? ''
              : 'tw-hidden'
          }
          isClearable
          clearValue={() =>
            setTableDataFilters((prevState) => ({
              ...prevState,
              country_id: null,
            }))
          }
          placeholder='Country'
          options={countries?.map((c) => mapCountryToOption(c))}
          onChange={(_country) =>
            setTableDataFilters((prevState) => ({
              ...prevState,
              country_id: _country?.id,
            }))
          }
        />
        <CustomSelect
          wrapperStyles={{ minWidth: 220 }}
          wrapperClassName={
            reportTypes.team_report.value === filterReportType
              ? ''
              : 'tw-hidden'
          }
          isClearable
          isMulti
          clearValue={() =>
            setTableDataFilters((prevState) => ({
              ...prevState,
              country_ids: [],
            }))
          }
          placeholder='Countries'
          options={countries?.map((c) => mapCountryToOption(c))}
          onChange={(_countries) =>
            setTableDataFilters((prevState) => ({
              ...prevState,
              country_ids: _countries.map((_country) => _country?.id),
            }))
          }
        />
      </div>

      <Card className='rp-shadow-2 px-4 py-4'>
        <div className='border-bottom pb-3 mb-4 d-flex justify-content-between align-items-center'>
          <HeaderLogo />

          <CustomDropDown
            isDisabled={!filteredDataTable?.length}
            title='Export report'
            options={exportReportsActions}
          />
        </div>

        <div className='d-flex flex-column gap-8 mb-4'>
          {/* TODO: fetch info from backend when it's ready */}
          {/* {info.map(({ label, value }, index) => {
            return (
              <p key={index} className='text-zinc-700 mb-0'>
                {label}: {value}
              </p>
            )
          })} */}
        </div>

        <div className='mb-4'>
          {isTableDataLoading ? (
            <div
              style={{ minHeight: 229.2 }}
              className='d-flex justify-content-center align-items-center'
            >
              <Loader />
            </div>
          ) : filteredDataTable?.length ? (
            <DataTable
              responsive
              rowClassName='tw-text-center md:tw-text-left'
              columns={tableHeader}
              data={filteredDataTable}
              headClassName='py-2 tw-text-center md:tw-text-left'
            />
          ) : (
            <div className='text-center'>
              <img
                src={emptyTable}
                alt='Empty table image'
                style={{ width: 160, height: 178 }}
                className='d-inline-block'
              />
              <p className='text-secondary mb-5'>
                Sorry, but no data to display.
              </p>
            </div>
          )}

          {/* TODO: Pass all the filters */}
          {!!openExportModal && (
            <ExportModal
              exportFileType={openExportModal}
              exportReportType={filterReportType}
              otherReportType={otherReport?.value}
              isOpen={!!openExportModal}
              toggle={() => setOpenExportModal(null)}
              availableColumns={reportColumns}
              customFields={showCustomFields ? customFields : []}
              tableDataFilters={tableDataFilters}
            />
          )}
        </div>

        <div className='text-center'>
          <CustomDropDown
            isDisabled={!filteredDataTable?.length}
            title='Export to view all records'
            options={exportReportsActions}
            containerClassName='d-inline-block'
          />
        </div>
      </Card>
    </div>
  )
}

function ExportModal({
  isOpen,
  toggle,
  exportFileType,
  exportReportType,
  otherReportType,
  availableColumns,
  customFields = [],
  tableDataFilters,
}) {
  const ExportStatuses = {
    FAILED: 'failed',
    READY: 'ready',
    PENDING: 'pending',
  }
  const [selectedColumns, setSelectedColumns] = useState(availableColumns)

  const checkDownloadStatus = useFetch(
    {
      action: checkExportStatus,
      autoFetch: false,
      onComplete: (data) => {
        if (data?.status === ExportStatuses.FAILED) {
          toastr.error('Failed to export')
          toggle()
        }
      },
      onError: (err) => {
        toastr.error(
          typeof err === 'string'
            ? err
            : err?.message || 'Something went wrong',
        )
      },
    },
    [],
  )

  function getRequiredAction() {
    switch (exportReportType) {
      case reportTypes.transactions_report.value:
        return exportTransactionsReport
      case reportTypes.expenses_report.value:
        return exportExpensesReport
      case reportTypes.invoices_report.value:
        return exportInvoicesReport
      case reportTypes.team_report.value:
        return exportTeamsReport
      case reportTypes.work_report.value:
        return exportWorksReport
      case reportTypes.contracts_report.value:
        return exportContractsReport
      case reportTypes.time_off_report.value:
        return exportTimeOffReport
      case reportTypes.time_off_history_report.value:
        return exportTimeOffHistoryReport
      case reportTypes.time_off_per_month_report.value:
        return exportTimeOffPerMonthReport
      case reportTypes.other_report.value:
        switch (otherReportType) {
          case 'contracts_with_no_work':
            return exportContractsReport
        }
        break
      default:
        return exportContractsReport
    }
  }

  function getRequiredBody() {
    const body = {
      format: exportFileType,
      selected_columns: selectedColumns.map((e) => e.value),
    }

    switch (exportReportType) {
      case reportTypes.team_report.value:
        return {
          ...body,
          contract_status_ids: tableDataFilters?.contract_status_ids,
          contract_types: tableDataFilters?.contract_types,
          country_ids: tableDataFilters?.country_ids,
          contract_ids: tableDataFilters?.contract_ids,
          department_ids: tableDataFilters?.department_ids,
          direct_manager_contract_ids:
            tableDataFilters?.direct_manager_contract_ids,
        }
      case reportTypes.contracts_report.value:
        return {
          ...body,
          months: tableDataFilters?.months,
          status_ids: tableDataFilters?.status_ids,
          contract_types: tableDataFilters?.contract_types,
        }
      case reportTypes.work_report.value:
        return {
          ...body,
          months: tableDataFilters?.months,
          work_status_ids: tableDataFilters?.work_status_ids,
        }
      case reportTypes.time_off_per_month_report.value:
        return body
      case reportTypes.time_off_report.value:
        return {
          ...body,
          start_date: tableDataFilters?.start_date,
          end_date: tableDataFilters?.end_date,
          contract_type: tableDataFilters?.contract_type,
          country_id: tableDataFilters?.country_id,
          department_id: tableDataFilters?.department_id,
          time_off_type_id: tableDataFilters?.time_off_type_id,
          contract_ids: tableDataFilters?.contract_ids,
          employee_type: tableDataFilters?.employee_type,
        }
      case reportTypes.time_off_history_report.value:
        return {
          ...body,
          date: tableDataFilters?.date,
          contract_type: tableDataFilters?.contract_type,
          country_id: tableDataFilters?.country_id,
          department_id: tableDataFilters?.department_id,
          time_off_policy_id: tableDataFilters?.time_off_policy_id,
          employee_type: tableDataFilters?.employee_type,
          contract_ids: tableDataFilters?.contract_ids,
        }
      case reportTypes.other_report.value:
        switch (otherReportType) {
          case 'contracts_with_no_work':
            return {
              ...body,
              contract_types: tableDataFilters?.contract_types,
              months: tableDataFilters?.months,
              no_work: 1,
            }
        }
        break
      default:
        return { ...body, months: tableDataFilters?.months }
    }
  }

  const autoExportReportType = [
    reportTypes.time_off_report.value,
    reportTypes.time_off_history_report.value,
    reportTypes.time_off_per_month_report.value,
  ].includes(exportReportType)

  const sendExportRequest = useFetch(
    {
      action: getRequiredAction(),
      autoFetch: autoExportReportType,
      body: getRequiredBody(),
      onError: (err) => {
        toastr.error(getErrorMessage(err))
        toggle()
      },
    },
    [],
  )

  useEffect(() => {
    const timeIntervalId = setInterval(() => {
      if (sendExportRequest.data?.id) {
        checkDownloadStatus.startFetch({
          exportId: sendExportRequest.data?.id,
        })
      }
    }, 5000)

    if (checkDownloadStatus.data?.url) {
      clearInterval(timeIntervalId)
    }

    return () => clearInterval(timeIntervalId)
  }, [sendExportRequest.data, checkDownloadStatus])

  function downloadFile(url, name) {
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', name)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const selectedDefinedFieldsValues = selectedColumns
    .filter((column) => column.type !== 'custom-field')
    .map((column) => column.value)

  const isAllDefinedFieldsSelected =
    availableColumns?.length > 0 &&
    availableColumns.every((column) =>
      selectedDefinedFieldsValues.includes(column.value),
    )

  const customFieldColumns = customFields.map((field) => {
    // `attribute_ID` is a BE convention
    const value = `attribute_${field.id}`
    return { label: field.name, value, type: 'custom-field' }
  })

  const selectedCustomFieldsValues = selectedColumns
    .filter((column) => column.type === 'custom-field')
    .map((column) => column.value)

  const isAllCustomFieldsSelected = customFieldColumns.every((column) =>
    selectedCustomFieldsValues.includes(column.value),
  )

  const isColumnChecked = useCallback(
    (column) => selectedColumns.some((item) => item.value === column.value),
    [selectedColumns],
  )

  function toggleSelectGroup(checked, groupColumns) {
    setSelectedColumns((prevSelected) => {
      const groupValues = groupColumns.map((item) => item.value)

      const prevWithoutGroup = prevSelected.filter(
        (item) => !groupValues.includes(item.value),
      )

      if (checked) {
        // Add group columns to selected columns
        return [...prevWithoutGroup, ...groupColumns]
      } else {
        // Remove group columns from selected columns
        return prevWithoutGroup
      }
    })
  }

  function toggleItem(checked, column) {
    if (checked) {
      setSelectedColumns((prevSelected) => [...prevSelected, column])
    } else {
      setSelectedColumns((prevSelected) =>
        prevSelected.filter((item) => item.value !== column.value),
      )
    }
  }

  return (
    <Modal
      unmountOnClose
      centered={true}
      isOpen={isOpen}
      toggle={toggle}
      keyboard={true}
      backdrop={true}
    >
      {!checkDownloadStatus.isLoading &&
      checkDownloadStatus.data?.status === ExportStatuses.READY ? (
        <>
          <ModalHeader
            toggle={toggle}
            close={<ModalCloseButton toggle={toggle} />}
          />
          <ModalBody>
            <div
              className='w-100 py-4 text-center'
              style={{ minHeight: '500px' }}
            >
              <div className='pt-3'>
                <img
                  src={documentReady}
                  alt='Document ready image'
                  className='m-auto'
                  style={{ width: 160, height: 178 }}
                />
                <p className='font-size-24 pb-3'>Your report is ready</p>
                <Button
                  onClick={() => {
                    downloadFile(
                      checkDownloadStatus.data?.url,
                      sendExportRequest.data?.path,
                    )
                    toggle()
                  }}
                >
                  Download report
                </Button>
              </div>
            </div>
          </ModalBody>
        </>
      ) : sendExportRequest.isLoading ||
        checkDownloadStatus.isLoading ||
        (sendExportRequest.completed && !checkDownloadStatus.data) ||
        checkDownloadStatus.data?.status === ExportStatuses.PENDING ? (
        <>
          <ModalHeader
            toggle={toggle}
            close={<ModalCloseButton toggle={toggle} />}
          />
          <ModalBody>
            <div
              className='w-100 py-4 text-center'
              style={{ minHeight: '500px' }}
            >
              <div className='d-flex flex-column justify-content-around pt-4'>
                <Loader />
                <p className='font-size-24'>We are preparing your report</p>
              </div>
            </div>
          </ModalBody>
        </>
      ) : (
        <>
          <ModalHeader
            toggle={toggle}
            close={<ModalCloseButton toggle={toggle} />}
          >
            <span className='rp-font-semibold font-size-20'>
              Customize your report
            </span>
            <br />
            <span className='rp-font-medium text-text-60 font-size-14'>
              Select the columns you want to include in the report
            </span>
          </ModalHeader>
          <ModalBody>
            <div className='d-flex flex-column gap-16'>
              <CheckListGroup>
                <CheckListItem style={{ flexBasis: '100%' }}>
                  <CheckItem
                    label='All columns'
                    labelClassName='rp-font-semibold font-size-14 text-text-black'
                    name='all-columns'
                    checked={isAllDefinedFieldsSelected}
                    indeterminate={
                      selectedDefinedFieldsValues?.length &&
                      !isAllDefinedFieldsSelected
                    }
                    onChange={(event) => {
                      toggleSelectGroup(event.target.checked, availableColumns)
                    }}
                    disabled={!availableColumns?.length}
                  />
                  <hr className='mb-0' />
                </CheckListItem>

                {availableColumns?.map((column) => {
                  return (
                    <CheckListItem key={column.value}>
                      <CheckItem
                        label={column.label}
                        name={column.value}
                        labelClassName='rp-font-semibold font-size-14 text-text-black'
                        checked={isColumnChecked(column)}
                        onChange={(event) => {
                          toggleItem(event.target.checked, column)
                        }}
                      />
                      <hr className='mb-0' />
                    </CheckListItem>
                  )
                })}
              </CheckListGroup>

              {customFields?.length <= 0 || !customFields ? null : (
                <CheckListGroup>
                  <CheckListItem style={{ flexBasis: '100%' }}>
                    <CheckItem
                      label='All custom fields'
                      name='all-custom-fields'
                      labelClassName='rp-font-semibold font-size-14 text-text-black'
                      checked={isAllCustomFieldsSelected}
                      indeterminate={
                        selectedCustomFieldsValues?.length &&
                        !isAllCustomFieldsSelected
                      }
                      onChange={(t) => {
                        toggleSelectGroup(t.target.checked, customFieldColumns)
                      }}
                    />
                    <hr className='mb-0' />
                  </CheckListItem>

                  {customFieldColumns?.map((column) => {
                    return (
                      <CheckListItem key={column.value}>
                        <CheckItem
                          label={column.label}
                          name={column.value}
                          labelClassName='rp-font-semibold font-size-14 text-text-black'
                          checked={isColumnChecked(column)}
                          onChange={(event) => {
                            toggleItem(event.target.checked, column)
                          }}
                        />
                        <hr className='mb-0' />
                      </CheckListItem>
                    )
                  })}
                </CheckListGroup>
              )}

              <Button
                className='mt-4'
                onClick={() => {
                  sendExportRequest.startFetch()
                }}
                disabled={
                  sendExportRequest.isLoading || !selectedColumns.length
                }
              >
                Export Report
              </Button>
            </div>
          </ModalBody>
        </>
      )}
    </Modal>
  )
}

function CheckListGroup({ children }) {
  return (
    <div
      className='border rp-rounded-xl p-4 d-flex justify-content-between'
      style={{
        flexWrap: 'wrap',
        '--check-gutters': '1rem',
        gap: 'var(--check-gutters)',
      }}
    >
      {children}
    </div>
  )
}

function CheckListItem({
  children,
  style = { flexBasis: 'calc(50% - var(--check-gutters))' },
}) {
  return <div style={style}>{children}</div>
}
