import {
  ArrowRight,
  ArrowSquareOut,
  CalendarCheck,
  Check,
  DotsThreeOutline,
  Eye,
  IdentificationCard,
  Money,
  SealCheck,
  ShieldPlus,
  UploadSimple,
  Warning,
  X,
} from '@phosphor-icons/react'
import cx from 'classnames'
import { add, format } from 'date-fns'
import qs from 'query-string'
import React, { useEffect, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Card,
  CardBody,
  Carousel,
  CarouselIndicators,
  CarouselItem,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  UncontrolledTooltip,
} from 'reactstrap'
import toastr from 'toastr'
import { v4 as uuidv4 } from 'uuid'

import ConfirmationModal from '../../../components/Common/ConfirmationModal'
import Button from '../../../components/ui/button'
import { CONTRACT_TYPES } from '../../../core/config/contract-types'
import {
  CONTRACT_STATUS,
  TERMINATE_CONTRACT_TYPE,
  userTypes,
} from '../../../helpers/enum'
import { useFetch, usePermissions } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import {
  amendContract,
  assignContractToSignatory,
  cancelAmendContract,
  cancelContract,
  cancelContractTermination,
  cancelFullTimeInvitation,
  cancelInvitation,
  deScheduledTerminate,
  downloadAdminQuote,
  downloadFullTimeOfferLetter,
  downloadContract as downloadPdf,
  downloadQuote,
  inviteFullTimeEmployee,
  requestContractTerminationDetails,
  signContract,
  terminateContract,
  updateContract,
  updateDEContract,
  updateFullTimeContract,
  uploadContractFileV2,
} from '../../../services/api'
import { updateContractRef } from '../../../store/profile/actions'
import { track } from '../../../utils/analytics'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'

import { Avatar, cn } from 'ui'
import DropzoneInput from '../../../components/Common/dropzone-input'
import { PermissionTooltip } from '../../../components/permission-tooltip'
import Alert from '../../../components/ui/alert'
import IconButton from '../../../components/ui/icon-button'
import Loader from '../../../components/ui/loader'
import FEATURE_FLAGS from '../../../config/feature-flags'
import { formatEOSPlanData } from '../../../helpers/eos-plans-helpers'
import { definiteValueDe } from '../../../helpers/lists'
import {
  getBackgroundCheck,
  getCountryFeatures,
} from '../../../services/api-compliance'
import openFileV2 from '../../../utils/file/open-v2'
import { getErrorMessage } from '../../../utils/get-errors'
import { getFullName } from '../../../utils/get-full-name'
import isNill from '../../../utils/is-nill'
import { PERMISSION_GROUP } from '../../CompanySetting/manage-role'
import { DownloadReport } from '../../Documents/client-documents/review-document-details'
import { RequestBgCheckModal } from '../../Documents/components/request-bg-check-modal'
import { AddEquipmentModal } from '../ContractPage/Equipments'
import { CONTRACT_TYPE_KEY } from '../CreateContract/ContractSelect'
import { FormSectionHr } from '../CreateContract/components/form-section-title'
import { UploadAreaContent } from '../bulk-creation/upload-flow'
import CancelAmendmentModal from '../components/CancelAmendmentModal'
import AnnexForm from '../components/Forms/AnnexForm'
import ComplianceForm from '../components/Forms/ComplianceForm'
import FormsModal from '../components/Forms/FormsModal'
import InfoForm from '../components/Forms/InfoForm'
import PaymentForm from '../components/Forms/PaymentForm'
import AllowancesForm from '../components/Forms/allowances-form'
import EmployeeInfoForm from '../components/Forms/empoyee-info-form'
import KYCModal from '../components/KYCModal'
import RequestTerminationModal from '../components/RequestTerminationModal'
import RequiredBankAccountModal from '../components/RequiredBankAccountModal'
import TerminationModal from '../components/TerminationModal'
import { AmendModal } from '../components/amend-modal'
import DeTerminationModal from '../components/de-termination-modal'
import CardContact, { ActionButton } from './card-contact'
import FulltimeDetailBlocks from './components/FulltimeDetailBlocks'
import ModalInvitation from './components/ModalInvitation'
import SignModal from './components/SignModal'
import {
  ContractBadge,
  ContractTimeline,
  ContractTimelineHeader,
} from './contract-timeline'
import {
  CorClientAction,
  CorContractorAction,
  CorRpAction,
} from './cor-actions'
import DeDetails from './de-details'
import { ReactivateAction } from './reactivate-action'

const annexesCols = [
  { label: 'Name' },
  { label: 'Created At', style: { textAlign: 'right', paddingRight: '50px' } },
]
const editedAnnexesCols = [
  { label: 'Name' },
  { label: 'Created At', style: { textAlign: 'right', paddingRight: '50px' } },
  { label: 'Action', style: { textAlign: 'right', paddingRight: '50px' } },
]

const contractReport = {
  type: {
    title: 'Contract Type',
    icon: <IdentificationCard size={24} weight='duotone' />,
  },
  startDate: {
    title: 'Start Date',
    icon: <CalendarCheck size={24} weight='duotone' />,
  },
  nextPayment: {
    title: 'Next Payment',
    icon: <Money size={24} weight='duotone' />,
  },
}

const backgroundCheckType = {
  request: 'request',
  requested: 'requested',
  inProgress: 'inProgress',
  complete: 'complete',
}

const getBgCheckStatus = (status, consent) => {
  if (
    status?.toLowerCase() === 'pending' &&
    consent?.toLowerCase() === 'pending'
  ) {
    return backgroundCheckType.requested
  }
  if (
    status?.toLowerCase() === 'pending' &&
    consent?.toLowerCase() === 'done'
  ) {
    return backgroundCheckType.inProgress
  }
  if (status?.toLowerCase() === 'returned') {
    return backgroundCheckType.complete
  }
  return backgroundCheckType.request
}

const ContractDetail = ({
  data,
  updateContractDetail = () => {},
  fetchingContractDetailsData,
  getContractDetailsLoading,
  insurancePlansLoading,
  showInsuranceAllowanceTab,
  onShowTerminatedModal = () => {},
  onShowEndedModal = () => {},
  isAdmin,
  isEmployee,
}) => {
  const [reports, setReports] = useState([
    contractReport.type,
    contractReport.startDate,
    contractReport.nextPayment,
  ])
  const history = useHistory()
  const location = history.location

  const [showInvitePopup, setShowInvitePopup] = useState(false)
  const [showInviteEmployee, setShowInviteEmployee] = useState(false)
  const [showUploadConfirmationModal, setShowUploadConfirmationModal] =
    useState(false)
  const [showUploadFileModal, setShowUploadFileModal] = useState(false)
  const [kycRequired, setKycRequired] = useState(false)
  const [showSignModal, setShowSignModal] = useState(false)
  const [bankAccountRequired, setBankAccountRequired] = useState(false)
  const [showNeedApprovalModal, setShowNeedApprovalModal] = useState(false)
  const [showNeedKyb, setShowNeedKyb] = useState(false)

  const user = useSelector((state) => state.Account?.user)
  const loginToken = useSelector((state) => state.Login?.loginToken)
  const userProfile = useSelector((state) => state.userProfile?.userProfile)
  const contract = useSelector((state) => state.Contract?.details)

  const isClient = user?.type === userTypes.COMPANY

  const dispatch = useDispatch()

  const [downloading, setDownloading] = useState(false)
  const [showTerminateConfirmation, setShowTerminateConfirmation] =
    useState(false)
  const [deTerminationOpen, setDeTerminationOpen] = useState(false)
  const [showEquipmentModal, setShowEquipmentModal] = useState(false)
  const [showNeedToCompleteProfile, setShowNeedToCompleteProfile] =
    useState(false)
  const [editPayment, setEditPayment] = useState(false)
  const [editInfo, setEditInfo] = useState(false)
  const [editEmpInfo, setEditEmpInfo] = useState(false)
  const [editAllowances, setEditAllowances] = useState(false)
  const [editCompliance, setEditCompliance] = useState(false)
  const [addAnnex, setAddAnnex] = useState(false)
  const [canEdit, setCanEdit] = useState(false)
  const [canAmend, setCanAmend] = useState(false)
  const [cancelConfirmation, setCancelConfirmation] = useState(false)
  const [cancelAmendConfirmation, setCancelAmendConfirmation] = useState(false)
  const [amendConfirmation, setAmendConfirmation] = useState(false)
  const [requestTerminationFT, setRequestTerminationFT] = useState(false)
  const [showTerminationDetails, setShowTerminationDetails] = useState(false)
  const [amendCancellation, setAmendCancellation] = useState(false)
  const [amendData, setAmendData] = useState(null)
  const [showFulltimePayment, setShowFulltimePayment] = useState(false)
  const editInfoRef = useRef(null)
  const editEmpInfoRef = useRef(null)
  const editAllowancesRef = useRef(null)
  const editPaymentRef = useRef(null)
  const editComplianceRef = useRef(null)
  const annexRef = useRef(null)
  const [isUploading, setIsUploading] = useState(false)
  const [isDownloadOpen, setIsDownloadOpen] = useState(false)
  const [menu, setMenu] = useState(false)
  const [loadingSignature, setLoadingSignature] = useState(false)
  const [defaultAnnexes, setDefaultAnnexes] = useState(
    data?.annexes ? data.annexes : [],
  )
  const [newAnnexes, setNewAnnexes] = useState([])
  const [annexesToRemove, setAnnexesToRemove] = useState([])
  const [deChanges, setDeChanges] = useState()
  const [deKey, setDeKey] = useState(uuidv4())
  const [showBgCheckModal, setShowBgCheckModal] = useState(false)
  const [bgCheckStatus, setBgCheckStatus] = useState()

  const isFullTime = data?.type === CONTRACT_TYPES.FULL_TIME
  const isDeContract = data?.type === CONTRACT_TYPES.DIRECT_EMPLOYEE

  const contractAmendable = data?.can_amend && !canAmend
  const fulltimeContractAmendableCheck =
    isFullTime && (data?.amended || data?.has_future_amendment)

  const contractor =
    contract?.employee?.first_name ??
    contract?.contractor?.first_name ??
    contract?.employee?.email ??
    contract?.contractor?.email

  const terminationDetails = useFetch(
    {
      action: requestContractTerminationDetails,
      autoFetch: data?.pending_termination_request_id,
      body: {
        contract_id: data?.id,
        request_id: data?.pending_termination_request_id,
      },
      onError: (err) => {
        toastr.error(err)
      },
    },
    [data?.id, data?.pending_termination_request_id],
  )
  useEffect(() => {
    setDefaultAnnexes(data?.annexes)
  }, [data])
  const cancel = useFetch({
    action: cancelInvitation,
    onComplete: () => updateContractDetail(false),
    onError: (err) => {
      toastr.error(err)
    },
  })
  const cancelEmployee = useFetch({
    action: cancelFullTimeInvitation,
    onComplete: () => updateContractDetail(false),
    onError: (err) => {
      toastr.error(err)
    },
  })
  const terminate = useFetch({
    action: terminateContract,
    onComplete: () => {
      updateContractDetail(false)
    },
    onError: (data) => {
      toastr.error(getErrorMessage(data))
    },
  })
  const cancelTermination = useFetch({
    action: cancelContractTermination,
    onComplete: () => {
      updateContractDetail(false)
      setShowTerminationDetails(false)
    },
    onError: (err) => {
      toastr.error(err)
    },
  })
  const {
    startFetch: scheduleTermination,
    isLoading: scheduleTerminationLoading,
  } = useFetch({
    action: deScheduledTerminate,
    onComplete: () => {
      updateContractDetail(false)
    },
    onError: (err) => toastr.error(getErrorMessage(err)),
  })
  const update = useFetch({
    action: deChanges ? updateDEContract : updateContract,
    onComplete: () => {
      setNewAnnexes([])
      setAnnexesToRemove([])
      updateContractDetail(false)
      setShowUploadFileModal(false)

      setCanAmend(false)
      setCanEdit(false)
      setDeChanges(null)
      setDeKey(uuidv4())
      toastr.success('Contract updated successfully')
    },
    onError: (err) => {
      toastr.error(err)
    },
  })
  const updateFullTime = useFetch({
    action: updateFullTimeContract,
    onComplete: () => {
      toastr.success('Contract updated successfully')
      setNewAnnexes([])
      setAnnexesToRemove([])
      updateContractDetail(false)

      setCanAmend(false)
      setAmendData(null)
      setCanEdit(false)
      setDeChanges(null)
      setDeKey(uuidv4())
    },
    onError: (err) => {
      toastr.error(err)
    },
  })
  const amend = useFetch({
    action: amendContract,
    onComplete: () => {
      updateContractDetail(false)
      setAmendData(null)

      setCanAmend(false)
      setCanEdit(false)
      setDeChanges(null)
      setDeKey(uuidv4())
      toastr.success(
        data.file && isDeContract
          ? 'These changes are not automatically amended to the contract.'
          : 'Contract amended successfully.',
      )
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const cancelAmend = useFetch({
    action: cancelAmendContract,
    onComplete: () => {
      updateContractDetail()
      setCancelAmendConfirmation(false)
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const {
    startFetch: getBackgroundCheckAction,
    setData,
    data: backgroundCheck,
  } = useFetch(
    {
      action: getBackgroundCheck,
      autoFetch: (data?.employee?.id ?? data?.contractor?.id) && isClient,
      onError: (err) => {
        setData(null)
        if (err !== 'Not Found') {
          toastr.error(err)
        }
      },
      onComplete: (data) =>
        setBgCheckStatus({
          status: data?.applicant?.status,
          consent: data?.userActivityLog?.workerConsent?.status,
        }),
      body: { userId: data?.employee?.id ?? data?.contractor?.id },
    },
    [data?.employee?.id, data?.contractor?.id],
  )

  const { startFetch: uploadContractFile, isLoading: isUploadingContractFile } =
    useFetch({
      action: uploadContractFileV2,
      onComplete: (file) => {
        update.startFetch({ contract_id: data?.id, file: file?.path })
        setShowUploadConfirmationModal(false)
      },
      onError: (err) => {
        toastr.error(err)
      },
    })

  const { hasAccess } = usePermissions()

  useEffect(() => {
    setDefaultAnnexes(data?.annexes)
  }, [])

  const cancelFetch = useFetch({
    action: cancelContract,
    onComplete: () => {
      updateContractDetail()
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const inviteEmployee = useFetch({
    action: inviteFullTimeEmployee,
    onComplete: () => updateContractDetail(false),
    onError: (err) => {
      toastr.error(err)
    },
  })

  const handleCancelContractClick = () => {
    window.analytics.track('Clicked cancel', {
      contract_id: data?.ref,
      contract_type: data?.type,
    })
    cancelFetch.startFetch({
      contract_id: data?.id,
    })
  }

  const cancelInvite = () => {
    cancel.startFetch({ contract_id: data?.id })
  }
  const cancelFullTimeInvite = () => {
    cancelEmployee.startFetch({ contract_id: data?.id })
  }

  const handleTerminateContract = (amount, terminationDate, terminationWay) => {
    track('Clicked terminate', {
      contract_id: data?.ref,
      contract_type: data?.type,
    })
    const body = {
      contract_id: data.id,
    }
    if (terminationWay === TERMINATE_CONTRACT_TYPE.NOW) {
      setShowTerminateConfirmation(false)
      if (!isNill(amount)) {
        body.amount = amount
      }
      body.termination_date = format(new Date(), 'yyyy-MM-dd')
      terminate.startFetch(body)
    } else if (terminationWay === TERMINATE_CONTRACT_TYPE.LATER) {
      if (!isNill(amount)) {
        body.last_payment_amount = amount
      }
      body.termination_date = format(new Date(terminationDate), 'yyyy-MM-dd')
      scheduleTermination(body)
    }
  }
  const handleDeleteAnnex = (anx) => {
    if (anx?.id) {
      const tmp1 = [...defaultAnnexes]
      const index = tmp1.findIndex((e) => e.id === anx.id)
      tmp1.splice(index, 1)
      setDefaultAnnexes(tmp1)
      setAnnexesToRemove([...annexesToRemove, anx?.id])
      setAmendData({
        ...amendData,
        contract_id: data?.id,
        annexes_removed: [...annexesToRemove, anx?.id],
        annexes: newAnnexes,
      })
    } else {
      const tmp2 = [...newAnnexes]
      const index = tmp2.findIndex((e) => e.path === anx.path)
      tmp2.splice(index, 1)
      setNewAnnexes(tmp2)
      setAmendData({
        ...amendData,
        contract_id: data?.id,
        annexes: tmp2,
      })
    }
  }

  useEffect(() => {
    const newData = { ...data }
    const getStatusCard = () => {
      switch (data?.status?.id) {
        case 6:
          return {
            title: 'Cancelled',
            color: '!tw-text-systemRed',
            description: '--',
          }
        case 7:
          return {
            title: 'Terminated',
            description: terminationDate,
            color: '!tw-text-systemRed',
          }
        case 5:
          return {
            title: 'Ended',
            description: endDate,
            color: '!tw-text-systemRed',
          }
        default:
          return {
            title: 'Next Payment',
            description: newData?.next_payment,
            color: '!tw-text-primary',
          }
      }
    }
    setReports([
      { ...contractReport.type, description: newData?.type },
      {
        ...contractReport.startDate,
        description:
          newData?.type !== 'Milestones' ? newData?.start_date : '--',
      },
      { ...contractReport.nextPayment, ...getStatusCard() },
    ])
  }, [data])

  const { startFetch: downloadOfferLetter } = useFetch({
    action: downloadFullTimeOfferLetter,
    onComplete: (r) => {
      setDownloading(false)
      openFileV2(r.data, {
        name: `${data?.ref}-offer-letter.pdf`,
        type: 'application/pdf',
        download: true,
      })
    },
    onError: (e) => {
      toastr.error(e)
      setDownloading(false)
    },
  })

  const { getInputProps, getRootProps } = useDropzone({
    accept: { 'application/pdf': ['.pdf'] },
    multiple: false,
    onDropAccepted: (files) => {
      setDeChanges({ tempUploadFile: files[0], directUpload: true })
    },
  })

  useEffect(() => {
    if (deChanges?.tempUploadFile && deChanges?.directUpload) {
      uploadContractFile({ file: deChanges.tempUploadFile })
    }
  }, [deChanges])

  const handleDownloadContract = () => {
    track('Clicked on download contract', {
      contract_id: data.ref,
      contract_type: data?.type,
    })

    setDownloading(true)
    downloadPdf(
      data?.ref,
      isAdmin ? loginToken : user?.token,
      data?.file,
      isAdmin,
    )
      .then((r) => {
        setDownloading(false)
        const url = window.URL.createObjectURL(new Blob([r.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${data?.ref}.pdf`) // or any other extension
        document.body.appendChild(link)
        link.click()
      })
      .catch(() => {
        setDownloading(false)
      })
  }
  const handleDownloadOfferLetter = () => {
    track('Clicked on download contract', {
      contract_id: data.ref,
      contract_type: data?.type,
    })
    setDownloading(true)
    downloadOfferLetter({ contract_id: data?.id }, user?.token)
  }

  const [previewing, setPreviewing] = useState(false)

  const handlePreviewContract = () => {
    setPreviewing(true)
    downloadPdf(
      data?.ref,
      isAdmin ? loginToken : user?.token,
      data?.file,
      isAdmin,
    )
      .then((r) => {
        const file = new Blob([r.data], { type: 'application/pdf' })
        const url = URL.createObjectURL(file)
        window.open(url)
      })
      .finally(() => {
        setPreviewing(false)
      })
  }

  const { startFetch: downloadQuotation } = useFetch({
    action: isAdmin ? downloadAdminQuote : downloadQuote,
    withAdminAccess: isAdmin,
    onComplete: (resp) => {
      setDownloading(false)

      const url = window.URL.createObjectURL(new Blob([resp]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `quotation-${data?.ref}.pdf`) // or any other extension
      document.body.appendChild(link)
      link.click()
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const { data: countryFeaturesData } = useFetch(
    {
      action: getCountryFeatures,
      body: {
        limit: 500,
        isoCode:
          contract?.employee?.citizen?.iso3 ??
          contract?.contractor?.country?.iso3,
      },
      autoFetch:
        contract?.employee?.citizen?.iso3 ||
        contract?.contractor?.country?.iso3,
      initResult: [],
      onError: (err) => {
        toastr.error(err)
      },
    },
    [contract?.employee?.citizen?.iso3, contract?.contractor?.country?.iso3],
  )

  const handleDownloadQuote = () => {
    setDownloading(true)

    downloadQuotation({ contract_id: data?.id })
  }

  const terminationDate = data?.termination_date
  const endDate = data?.status?.id === 5 && data?.end_date

  const formatter = getCurrencyFormatter(
    amendData?.currency_id?.code || data?.currency?.code,
  )

  function createMarkup(text) {
    return { __html: text }
  }

  const handleSignClick = () => {
    setLoadingSignature(true)
    if (!data.signatory && user?.type === userTypes.COMPANY) {
      assignContractToSignatory(user?.token, {
        contract_id: data?.id,
        signatory_id: user?.id,
      })
        .then(
          (r) => {
            if (r.data.success) {
              handleSignContract()
            }
          },
          (err) => {
            setLoadingSignature(false)
            toastr.error(
              err.response?.data?.data?.error ??
                err.message ??
                'Error while signing the contract',
            )
          },
        )
        .catch((error) => {
          setLoadingSignature(false)
          toastr.error(
            error.response?.data?.data?.error ??
              'Error while signing the contract',
          )
        })
    } else {
      handleSignContract()
    }
  }

  const handleSignContract = () => {
    signContract(user?.token, {
      name: `${userProfile?.first_name || ''} ${
        userProfile?.middle_name ? userProfile?.middle_name + ' ' : ''
      }${userProfile?.last_name || ''}`,
      contract_id: data?.id,
    })
      .then(
        (r) => {
          setLoadingSignature(false)
          if (r.data.success) {
            if (
              isFullTime &&
              user?.type === userTypes?.COMPANY &&
              !data?.amended &&
              hasAccess(permissions.PrepareTransactions)
            ) {
              setShowFulltimePayment(true)
            }

            setShowSignModal(false)
          }
          updateContractDetail(false)
        },
        (err) => {
          toastr.error(
            err.response?.data?.data?.error ??
              err.message ??
              'An error occurred',
          )
          setLoadingSignature(false)
        },
      )
      .catch((error) => {
        toastr.error(error.response?.data?.data?.error ?? 'An error occurred')
        setLoadingSignature(false)
      })
  }
  const handleSaveAmendChanges = async () => {
    const isContractDefinite = deChanges?.employment_term === definiteValueDe
    if (deChanges) {
      const { jurisdiction_eos_plan_inputs: JurisdictionPlanInputs } =
        formatEOSPlanData(deChanges)

      const body = {
        contract_id: deChanges?.id,
        end_date:
          deChanges?.end_date ??
          (isContractDefinite ? deChanges.end_date : undefined),
        amount: deChanges?.amount,
        department: deChanges?.department ?? undefined,
        notice_period: deChanges?.notice_period,
        employee_identifier: deChanges?.employee_identifier || undefined,
        visa_number: deChanges?.visa_number || '',
        jurisdiction_eos_plan_inputs: JurisdictionPlanInputs,
        employment_term: deChanges?.employment_term,
        seniority: deChanges?.seniority,
        department_id: deChanges?.department_id ?? undefined,
        is_head_of_department: deChanges?.is_head_of_department ?? undefined,
        allowances: deChanges?.allowances?.map((_a) => ({
          id: _a.id,
          name: _a.name,
          amount: _a.amount,
        })),
        ...(deChanges.status?.id !== CONTRACT_STATUS.ONGOING.value && {
          jurisdiction_id: deChanges?.jurisdiction_id,
          name: deChanges?.name || deChanges?.title,
          start_date: deChanges?.start_date,
        }),
        ...(deChanges?.probation_period && {
          probation_period: deChanges?.probation_period,
        }),
        ...(data?.is_first_payment_editable && {
          first_payment_date: deChanges?.first_payment_date,
          prorata_amount: deChanges?.prorata_amount || undefined,
        }),
        ...(data?.is_first_payment_editable &&
          deChanges?.prorata_amount && {
            prorata_amount: deChanges?.prorata_amount,
          }),
      }
      update.startFetch(body)
    } else {
      const body = {
        ...amendData,
        frequency_id: amendData?.frequency_id?.value,
        currency_id: amendData?.currency_id?.value,
        occurrence_id: amendData?.occurrence_id?.value,
        rate_id: amendData?.rate_id?.value,
      }
      delete body.currency

      if (canEdit) {
        if (isFullTime) {
          const fullTimeBody = {
            ...amendData,
            job_title: amendData?.name,
            job_description: amendData?.scope,
            amount: parseFloat(amendData?.amount || data?.amount),
            contract_id: amendData?.contract_id,
          }
          delete fullTimeBody.scope
          updateFullTime.startFetch(fullTimeBody)
        } else {
          update.startFetch(body)
        }
      } else if (canAmend) {
        // TODO: check the backend to see if we can remove the scope
        amend.startFetch(body)
      }
      setCanAmend(false)
      setCanEdit(false)
    }
  }

  const handleCancelAmendChanges = () => {
    setDeChanges(null)
    setAmendData(null)
    setCanAmend(false)
    setCanEdit(false)
    setDefaultAnnexes(data?.annexes)
    setNewAnnexes([])
    setAnnexesToRemove([])
    updateContractDetail(false)
    setAmendCancellation(false)
  }
  const updateTabLink = (tab) => {
    let query = qs.parse(location.search)
    query = { ...query, tab }
    const searchString = qs.stringify(query)

    history.push({ search: searchString })
  }

  const handleKybProceed = () => {
    setShowNeedKyb(false)
    if (['rejected', 'pending'].includes(userProfile?.kyb_status)) {
      const redirect = encodeURI(location.pathname + location.search)
      const url = '/registration-document?redirect=' + redirect
      history.push(url)
    }
  }

  const isContractTerminated =
    data?.status?.id === CONTRACT_STATUS.TERMINATED.value

  const showTerminateButton =
    data?.can_terminate &&
    !isContractTerminated &&
    hasAccess(permissions.TerminateContracts) &&
    (isFullTime
      ? data?.status?.id !== CONTRACT_STATUS.ONGOING.value
      : !data?.is_scheduled_terminated)

  const showCancelTermination =
    !isContractTerminated &&
    data?.is_scheduled_terminated === 1 &&
    hasAccess(permissions.TerminateContracts)

  const showRequestTerminationBtn =
    isFullTime &&
    data?.status?.name?.toLowerCase() === 'ongoing' &&
    !data?.is_scheduled_terminated &&
    hasAccess(permissions.TerminateContracts)

  function check() {
    let checkPassed = true

    const kycNotVerified =
      userProfile?.kyc_verified !== 1 &&
      userProfile?.type === userTypes.COMPANY &&
      userProfile?.is_company_creator

    if (
      // Check client company KYB
      ['submitted', 'pending', 'rejected'].includes(userProfile?.kyb_status)
    ) {
      setShowNeedKyb(true)
      checkPassed = false
    } else if (
      // Check client KYC
      data?.kyc === 1 &&
      kycNotVerified
    ) {
      setKycRequired(true)
      checkPassed = false
    } else if (terminationDate) {
      onShowTerminatedModal()
      checkPassed = false
    } else if (endDate) {
      onShowEndedModal()
      checkPassed = false
    }

    return checkPassed
  }

  function handleInviteContractor() {
    if (!check()) {
      return
    }

    if (!userProfile?.approved) {
      setShowNeedApprovalModal(true)
    } else {
      setShowInvitePopup(true)
    }
  }

  if (getContractDetailsLoading) {
    return <Loader minHeight='30rem' />
  }

  const isCor = data?.is_cor === 1
  const isContractor = userProfile?.type === userTypes.CONTRACTOR

  const corClientSignedSow = data?.cor?.is_sow_client_signed === 1

  const canViewContractActivities =
    (!isEmployee && hasAccess(permissions.ViewContractsActivity)) || isAdmin

  const showBackgroundCheckFeature =
    FEATURE_FLAGS.DOCUMENTS_BG_CHECK &&
    user?.type === userTypes.COMPANY &&
    ![
      CONTRACT_STATUS.PENDING_INVITE.value,
      CONTRACT_STATUS.CANCELLED.value,
      CONTRACT_STATUS.ENDED.value,
      CONTRACT_STATUS.TERMINATED.value,
    ].includes(contract?.status?.id) &&
    contractor &&
    hasAccess(permissions.ViewContractsDocuments)

  return (
    <>
      <Row className='p-0 m-0'>
        <Col
          xs={12}
          md={12}
          xl={isEmployee ? 12 : 9}
          className='pl-0 pr-0 pr-xl-3'
        >
          <div className='d-flex flex-wrap flex-column flex-md-row gap-24 mb-3 mb-md-4'>
            {reports.map((report, key) => (
              <div className='flex-1' key={'_col_' + key}>
                <Card
                  className='mb-0 bg-white rounded'
                  style={{ boxShadow: '0px 1px 0px #DFE1E6' }}
                >
                  <Row className='m-0 p-0 w-100'>
                    <Col
                      md={12}
                      className='d-flex justify-content-center gap-8 p-3 p-md-4 p-card-col m-0 border-bottom'
                    >
                      {report.icon}

                      <Media
                        body
                        className={cn(
                          `!tw-flex !tw-items-center !tw-font-semibold`,
                          { [report.color]: key === 2 },
                        )}
                      >
                        <h6 className='tw-mb-0 tw-text-sm tw-text-current lg:tw-text-base'>
                          {report.title}
                        </h6>
                      </Media>
                    </Col>
                    <Col
                      md={12}
                      className='p-card-col !tw-m-0 !tw-flex !tw-p-4 md:!tw-p-6'
                    >
                      <h6
                        className={cn(
                          'tw-mb-0 tw-truncate tw-text-[22px] md:tw-text-[19px]',
                          { [report.color]: key === 2 },
                        )}
                      >
                        {report.description}
                      </h6>
                    </Col>
                  </Row>
                </Card>
              </div>
            ))}
          </div>

          <div className='d-flex flex-column gap-16 mb-3 mb-md-4'>
            {isCor ? (
              <>
                <PartyCard
                  show={!!data?.client && (isAdmin || !isContractor)}
                  action={
                    <CorClientAction
                      contract={data}
                      updateContractDetail={updateContractDetail}
                      inviteContractor={handleInviteContractor}
                    />
                  }
                >
                  <CardContact
                    label='Company'
                    contract={data}
                    user={data?.signatory || data?.client}
                  />
                </PartyCard>

                <PartyCard
                  show={!!data?.cor}
                  action={
                    <CorRpAction
                      contract={data}
                      updateContractDetail={updateContractDetail}
                    />
                  }
                >
                  <CardContact
                    isRp
                    label='Client'
                    contract={data}
                    user={data?.cor}
                  />
                </PartyCard>

                <PartyCard
                  show={!!data?.contractor}
                  action={
                    <CorContractorAction
                      contract={data}
                      updateContractDetail={updateContractDetail}
                    />
                  }
                >
                  <CardContact
                    label={`${data?.contractor_name || 'Contractor'}`}
                    user={data?.contractor}
                    isContractor
                    contract={data}
                    isKyc={data?.contractor?.kyc_level_2 === 'Verified'}
                    showRpBadge
                  />
                </PartyCard>
              </>
            ) : (
              <>
                <PartyCard
                  show={!!data?.client}
                  action={
                    <>
                      <ActionButton
                        type='client'
                        isAdmin={isAdmin}
                        data={data}
                        clientSigned={data?.client_signed}
                        contractorSigned={!!data?.contractor_signed}
                        onCancel={cancelInvite}
                        onInvite={handleInviteContractor}
                        onSign={() => {
                          if (!check()) {
                            return
                          }

                          if (data?.can_sign) {
                            setShowSignModal(true)
                          } else {
                            setShowNeedToCompleteProfile(true)
                          }
                        }}
                        onUpdateContract={() => updateContractDetail(false)}
                      />

                      {data?.status?.id === 3 && isFullTime && (
                        <Dropdown
                          isOpen={isDownloadOpen}
                          toggle={() => setIsDownloadOpen((t) => !t)}
                        >
                          <DropdownToggle
                            tag={Button}
                            outline
                            size='sm'
                            className='border-0'
                            loading={downloading}
                            disabled={downloading}
                          >
                            Download
                          </DropdownToggle>
                          <DropdownMenu className='dropdown-menu' flip={false}>
                            <DropdownItem onClick={handleDownloadContract}>
                              SOW
                            </DropdownItem>
                            <DropdownItem onClick={handleDownloadQuote}>
                              Quotation
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      )}
                    </>
                  }
                  bottomChildren={
                    isDeContract &&
                    data?.jurisdiction_name && (
                      <div className='tw-flex tw-items-center tw-gap-6 md:tw-gap-2'>
                        <div className='tw-flex'>
                          <img
                            src={`/flags/${data?.jurisdiction_country?.iso2?.toLowerCase()}.svg`}
                            className='tw-h-6 tw-w-6 tw-rounded-xl tw-object-cover'
                            alt=''
                          />
                          <Avatar
                            size='sm'
                            className='tw--ml-[5px] tw-border tw-border-solid tw-border-primary-100'
                            name={
                              data?.client?.first_name || data?.client?.email
                            }
                            photo={data?.entity?.official_logo}
                          />
                        </div>
                        <div>
                          Employed by{' '}
                          <span className='tw-font-bold'>
                            {data?.entity?.name}, {data?.jurisdiction_name},{' '}
                            {data?.entity?.country?.name}
                          </span>
                        </div>
                      </div>
                    )
                  }
                >
                  <CardContact
                    label='Company'
                    contract={data}
                    user={data?.signatory || data?.client}
                    key={'_client_' + data?.client?.id}
                  />
                </PartyCard>

                <PartyCard
                  show={!!data?.contractor}
                  action={
                    <ActionButton
                      isAdmin={isAdmin}
                      type='contractor'
                      data={data}
                      clientSigned={data?.client_signed}
                      contractorSigned={!!data?.contractor_signed}
                      onCancel={cancelInvite}
                      onInvite={() => {
                        if (terminationDate) {
                          onShowTerminatedModal()
                        } else if (endDate) {
                          onShowEndedModal()
                        } else {
                          setShowInvitePopup(true)
                        }
                      }}
                      onEmployeeInvite={() => {
                        setShowInviteEmployee(true)
                      }}
                      onSign={() => {
                        if (terminationDate) {
                          onShowTerminatedModal()
                        } else if (endDate) {
                          onShowEndedModal()
                        } else if (
                          data?.kyc === 1 &&
                          userProfile?.kyc_verified !== 1
                        ) {
                          setKycRequired(true)
                        } else if (data.can_sign) {
                          setShowSignModal(true)
                        } else {
                          setShowNeedToCompleteProfile(true)
                        }
                      }}
                      onUpdateContract={() => updateContractDetail(false)}
                      isLoading={cancel.isLoading}
                    />
                  }
                >
                  <CardContact
                    label={`${data?.contractor_name || 'Contractor'}`}
                    user={data?.contractor}
                    isContractor
                    isFteProvider={data?.type === CONTRACT_TYPES.FULL_TIME}
                    contract={data}
                    isKyc={data?.contractor?.kyc_level_2 === 'Verified'}
                  />
                </PartyCard>

                <PartyCard
                  show={
                    !!data?.employee &&
                    data?.status?.id === CONTRACT_STATUS.ONGOING.value &&
                    (user?.type === userTypes.CONTRACTOR ||
                      !!data?.employee_invited)
                  }
                  action={
                    <ActionButton
                      isAdmin={isAdmin}
                      type='employee'
                      data={data}
                      clientSigned={data?.client_signed}
                      contractorSigned={!!data?.contractor_signed}
                      employeeInvitation={!!data?.employee_invitation_url}
                      onCancel={cancelFullTimeInvite}
                      onInvite={() =>
                        inviteEmployee.startFetch({
                          email: data?.employee?.email,
                          contract_id: data?.id,
                        })
                      }
                      onUpdateContract={() => updateContractDetail(false)}
                      isLoading={
                        cancelEmployee.isLoading || inviteEmployee.isLoading
                      }
                    />
                  }
                >
                  <CardContact
                    label='Employee'
                    user={data?.employee}
                    isContractor
                    contract={data}
                  />
                </PartyCard>
              </>
            )}

            <KYCModal
              toggle={() => setKycRequired(false)}
              isOpen={kycRequired}
              proceed={() => {
                setKycRequired(false)
                dispatch(updateContractRef({ ref: data?.ref, type: 'kyc' }))
                if (isClient) {
                  history.push('/profile-settings?tab=verification')
                } else {
                  history.push('/settings#kyc')
                }
              }}
              message={
                isClient
                  ? 'You have to verify your identity before you can invite others'
                  : undefined
              }
            />

            <RequiredBankAccountModal
              toggle={() => setBankAccountRequired(false)}
              isOpen={bankAccountRequired}
              proceed={() => {
                setBankAccountRequired(false)
                history.push('/settings#accounts')
              }}
            />
            <ConfirmationModal
              toggle={() => setShowNeedApprovalModal(false)}
              isOpen={showNeedApprovalModal}
              title='Need to approve your account'
              onConfirm={() => {
                window.Intercom('show')
                setShowNeedApprovalModal(false)
              }}
              message='Your account is not approved yet please contact support to get approved.'
              caption='OK'
            />
            <ConfirmationModal
              toggle={() => setShowNeedKyb(false)}
              isOpen={showNeedKyb}
              onConfirm={handleKybProceed}
              title='Company details confirmation'
              {...getKybModalText(userProfile?.kyb_status)}
            />
          </div>

          <Row className='p-0 m-0 mb-3'>
            <Col className='p-0 m-0'>
              {isFullTime ? (
                <FulltimeDetailBlocks
                  canEdit={canEdit}
                  amendData={amendData}
                  canAmend={canAmend}
                  setEditInfo={setEditInfo}
                  setEditEmpInfo={setEditEmpInfo}
                  setEditAllowances={setEditAllowances}
                  setEditPayment={setEditPayment}
                />
              ) : isDeContract ? (
                <DeDetails
                  contract={data}
                  editing={canEdit || canAmend}
                  setDeChanges={setDeChanges}
                  key={deKey}
                  isAmending={canAmend}
                />
              ) : (
                <DetailsWrapper>
                  <Media>
                    <Media
                      className='!tw-overflow-clip'
                      style={{ overflowClipMargin: 12 }}
                      body
                    >
                      <Col className='p-0 m-0'>
                        <Row className='justify-content-between align-items-start p-0 m-0'>
                          <h6 className='tw-mb-4 tw-text-base tw-font-bold tw-text-black md:tw-mb-6'>
                            Overview
                          </h6>

                          {(canEdit || canAmend) && (
                            <button
                              className='btn btn-link text-primary p-0 font-size-14'
                              onClick={() => setEditInfo(true)}
                            >
                              Edit
                            </button>
                          )}
                        </Row>
                      </Col>
                      <div className='mb-3 mb-md-4'>
                        <h5 className='tw-text-sm'>Tax Residence</h5>
                        <Col>
                          <Row className='tw-items-center'>
                            <h6 className='tw-mb-0 tw-text-sm tw-font-light tw-text-disabled'>
                              {amendData?.taxResidence?.label ||
                                data?.tax_residence?.name}
                            </h6>
                            <img
                              src={
                                amendData?.taxResidence?.flag ||
                                (!data?.tax_residence?.iso2
                                  ? undefined
                                  : `/flags/${data?.tax_residence.iso2?.toLowerCase()}.svg`)
                              }
                              className='mx-1'
                              alt=''
                              style={{
                                width: '1rem',
                                height: '1rem',
                                borderRadius: '0.5rem',
                                marginRight: 10,
                                objectFit: 'cover',
                              }}
                            />
                          </Row>
                        </Col>
                      </div>
                      <h5 className='tw-text-[13px] tw-text-black md:tw-text-sm'>
                        Role
                      </h5>
                      <p className='tw-text-sm tw-font-normal tw-text-disabled md:tw-text-[13px]'>
                        {amendData?.name || data?.name}
                      </p>
                    </Media>
                  </Media>

                  <h5 className='tw-text-[13px] tw-text-black md:tw-text-sm'>
                    Scope of Work
                  </h5>
                  <div
                    className='text-secondary font-size-14'
                    dangerouslySetInnerHTML={createMarkup(
                      amendData?.job_description ||
                        amendData?.scope ||
                        data?.job_description ||
                        data?.scope,
                    )}
                  />

                  {!data?.milestones && <FormSectionHr className='tw-mx-0' />}

                  <Row>
                    <Col>
                      <div>
                        <Media>
                          <Media
                            className='!tw-overflow-clip'
                            style={{ overflowClipMargin: 12 }}
                            body
                          >
                            <Col>
                              <Row className='justify-content-between'>
                                <h6 className='tw-items-center tw-text-base tw-font-bold tw-text-black'>
                                  Payment schedule
                                </h6>
                                {(canEdit || canAmend) && !data?.milestones && (
                                  <button
                                    className='btn btn-link text-primary p-0'
                                    onClick={() => setEditPayment(true)}
                                  >
                                    Edit
                                  </button>
                                )}
                              </Row>
                            </Col>
                            <Row className='mt-md-4 mt-3 p-0 m-0'>
                              {!data?.milestones && (
                                <>
                                  {(!!amendData?.frequency ||
                                    !!data?.frequency) && (
                                    <Col
                                      className='pl-0 mb-3 mb-md-0'
                                      xs={12}
                                      md={4}
                                    >
                                      <div className='tw-mb-4 lg:tw-mb-6'>
                                        <h5 className='tw-text-[13px] lg:tw-text-sm'>
                                          Frequency
                                        </h5>
                                        <h6 className='tw-text-[13px] tw-font-light tw-text-disabled lg:tw-text-sm'>
                                          {amendData?.frequency_id?.label ||
                                            data?.frequency?.name}
                                        </h6>
                                      </div>
                                    </Col>
                                  )}
                                  {(!!amendData?.start_date ||
                                    !!data?.start_date) && (
                                    <Col
                                      className='pl-0 mb-3 mb-md-0'
                                      xs={12}
                                      md={4}
                                    >
                                      <h5 className='tw-text-[13px] lg:tw-text-sm'>
                                        Start Date
                                      </h5>
                                      <h6 className='tw-text-[13px] tw-font-light tw-text-disabled lg:tw-text-sm'>
                                        {format(
                                          new Date(
                                            amendData?.start_date ||
                                              data?.start_date,
                                          ),
                                          amendData?.start_date
                                            ? 'yyyy-MM-dd'
                                            : 'MMM dd, yyyy',
                                        )}
                                      </h6>
                                    </Col>
                                  )}
                                  {(!!amendData?.end_date ||
                                    !!data?.end_date) && (
                                    <Col
                                      className='pl-0 mb-3 mb-md-0'
                                      xs={12}
                                      md={4}
                                    >
                                      <div className='mb-3 mb-md-4'>
                                        <h5 className='tw-text-[13px] lg:tw-text-sm'>
                                          End Date
                                        </h5>
                                        <h6 className='tw-text-[13px] tw-font-light tw-text-disabled lg:tw-text-sm'>
                                          {format(
                                            new Date(
                                              amendData?.end_date ||
                                                data?.end_date,
                                            ),
                                            amendData?.end_date
                                              ? 'yyyy-MM-dd'
                                              : 'MMM dd, yyyy',
                                          )}
                                        </h6>
                                      </div>
                                    </Col>
                                  )}
                                  {(!!amendData?.amount || !!data?.amount) && (
                                    <Col
                                      className='pl-0 mb-3 mb-md-0'
                                      xs={12}
                                      md={4}
                                    >
                                      <h5 className='tw-text-[13px] lg:tw-text-sm'>
                                        Amount
                                      </h5>
                                      <h6 className='tw-text-[13px] tw-font-light tw-text-disabled lg:tw-text-sm'>
                                        {formatter.format(
                                          amendData?.amount || data?.amount,
                                        )}{' '}
                                        {amendData?.rate_id?.label ||
                                          data?.rate_id?.name ||
                                          ''}
                                      </h6>
                                    </Col>
                                  )}
                                  {(!!data?.first_payment_date ||
                                    !!data?.first_payment_date) && (
                                    <Col className='pl-0' xs={12} md={4}>
                                      <div className='mb-3 mb-md-4'>
                                        <h5 className='tw-text-[13px] lg:tw-text-sm'>
                                          First Payment
                                        </h5>
                                        <h6 className='tw-text-[13px] tw-font-light tw-text-disabled lg:tw-text-sm'>
                                          {' '}
                                          {format(
                                            new Date(
                                              amendData?.first_payment_date ||
                                                data?.first_payment_date,
                                            ),
                                            'yyyy-MM-dd',
                                          )}
                                        </h6>
                                      </div>
                                    </Col>
                                  )}

                                  {!!(
                                    amendData?.occurrence_id || data?.occurrence
                                  ) && (
                                    <Col className='pl-0' xs={12} md={4}>
                                      <h5 className='tw-text-[13px] lg:tw-text-sm'>
                                        Occurrence
                                      </h5>
                                      <h6 className='tw-text-[13px] tw-font-light tw-text-disabled lg:tw-text-sm'>
                                        {amendData?.occurrence_id?.label ||
                                          data?.occurrence?.name}
                                      </h6>
                                    </Col>
                                  )}
                                  {((!!amendData?.prorata_amount &&
                                    amendData?.first_payment_prorata) ||
                                    (!!data?.first_payment_prorata &&
                                      !amendData)) && (
                                    <Col className='pl-0' xs={12} md={4}>
                                      <h5 className='tw-text-[13px] lg:tw-text-sm'>
                                        Prorata on first payment
                                      </h5>
                                      <h6 className='tw-text-[13px] tw-font-light tw-text-disabled lg:tw-text-sm'>
                                        {formatter.format(
                                          amendData?.prorata_amount ||
                                            data?.first_payment_amount,
                                        )}
                                      </h6>
                                    </Col>
                                  )}
                                </>
                              )}
                            </Row>
                          </Media>
                        </Media>
                      </div>
                    </Col>
                  </Row>
                  <FormSectionHr className='tw-mx-0' />

                  <Row>
                    <Col>
                      <div>
                        <Media>
                          <Media
                            className='!tw-overflow-clip'
                            style={{ overflowClipMargin: 12 }}
                            body
                          >
                            <Col className='!tw-p-0'>
                              <Col>
                                <Row className='justify-content-between'>
                                  <h6 className='tw-items-center tw-text-base tw-font-bold tw-text-black'>
                                    Compliance
                                  </h6>
                                  {(canEdit || canAmend) && (
                                    <button
                                      className='btn btn-link text-primary p-0'
                                      onClick={() => setEditCompliance(true)}
                                    >
                                      Edit
                                    </button>
                                  )}
                                </Row>
                              </Col>
                              <Row className='mt-md-4 mt-3 p-0 m-0'>
                                <Col
                                  className='pl-0 mb-3 mb-md-0'
                                  xs={12}
                                  md={4}
                                >
                                  <div className='mb-3 mb-md-4'>
                                    <h5 className='tw-text-[13px] lg:tw-text-sm'>
                                      Notice Period
                                    </h5>
                                    <h6 className='tw-text-[13px] tw-font-light tw-text-disabled lg:tw-text-sm'>
                                      {amendData?.notice_period ||
                                        data?.notice_period}
                                    </h6>
                                  </div>
                                </Col>
                                <Col className='!tw-pl-0' xs={12} md={4}>
                                  <>
                                    <h5 className='tw-text-[13px] lg:tw-text-sm'>
                                      {amendData?.template_id
                                        ? 'You are using your saved contract template ' +
                                          amendData?.template?.label
                                        : !!amendData?.file ||
                                            data?.template_type === 'uploaded'
                                          ? 'You are using an uploaded contract'
                                          : amendData?.rw_file
                                            ? "You are using RemotePass's template for " +
                                              amendData?.taxResidence?.label
                                            : data?.template_type === 'custom'
                                              ? 'You are using your saved contract template ' +
                                                data?.template_name
                                              : data?.template_type ===
                                                  'uploaded'
                                                ? 'You are using an uploaded contract'
                                                : data?.template_type ===
                                                    'default'
                                                  ? "You are using RemotePass's template for " +
                                                    data?.tax_residence?.name
                                                  : ''}
                                    </h5>
                                  </>
                                </Col>
                              </Row>
                            </Col>
                          </Media>
                        </Media>
                      </div>
                    </Col>
                  </Row>
                  {(canEdit || canAmend || data?.annexes?.length > 0) && (
                    <FormSectionHr className='tw-mx-0' />
                  )}
                  {(canEdit || canAmend || data?.annexes?.length > 0) && (
                    <Row>
                      <Col>
                        <div>
                          <Media>
                            <Media
                              className='!tw-overflow-clip'
                              style={{ overflowClipMargin: 12 }}
                              body
                            >
                              <Col className='p-0'>
                                <Col>
                                  <Row className='justify-content-between'>
                                    <h6 className='tw-text-base tw-font-bold tw-text-black'>
                                      Annexes
                                    </h6>
                                    {(canEdit || canAmend) && !addAnnex && (
                                      <button
                                        className='btn btn-link text-primary p-0'
                                        onClick={() => setAddAnnex(true)}
                                      >
                                        Add
                                      </button>
                                    )}
                                  </Row>
                                  <Row className='justify-content-center p-0 pt-4'>
                                    {canEdit || canAmend ? (
                                      <Table>
                                        {(data?.annexes.length > 0 ||
                                          newAnnexes.length > 0) && (
                                          <thead>
                                            <tr>
                                              {editedAnnexesCols.map(
                                                (
                                                  { className, label, style },
                                                  index,
                                                ) => {
                                                  return (
                                                    <th
                                                      className={className}
                                                      style={style}
                                                      key={index}
                                                    >
                                                      {label}
                                                    </th>
                                                  )
                                                },
                                              )}
                                            </tr>
                                          </thead>
                                        )}
                                        <tbody>
                                          <EditedAnnexes
                                            annexes={
                                              [
                                                ...(defaultAnnexes ?? []),
                                                ...newAnnexes,
                                              ] ?? []
                                            }
                                            deleteAnnex={handleDeleteAnnex}
                                            canEdit={canEdit}
                                          />
                                        </tbody>
                                      </Table>
                                    ) : (
                                      <Table>
                                        <thead>
                                          <tr>
                                            {annexesCols.map(
                                              (
                                                { className, label, style },
                                                index,
                                              ) => {
                                                return (
                                                  <th
                                                    className={className}
                                                    style={style}
                                                    key={index}
                                                  >
                                                    {label}
                                                  </th>
                                                )
                                              },
                                            )}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <Annexes
                                            annexes={data?.annexes ?? []}
                                          />
                                        </tbody>
                                      </Table>
                                    )}
                                  </Row>
                                </Col>
                              </Col>
                            </Media>
                          </Media>
                        </div>
                      </Col>
                    </Row>
                  )}
                </DetailsWrapper>
              )}
            </Col>
          </Row>
        </Col>

        {canViewContractActivities && (
          <Col xs={12} md={12} xl={3} className='p-0 m-0'>
            <FeatureBanners
              userProfile={userProfile}
              contractor={contractor}
              bgCheckStatus={bgCheckStatus}
              countryFeaturesData={countryFeaturesData}
              backgroundCheck={backgroundCheck}
              showBackgroundCheckFeature={showBackgroundCheckFeature}
              openBackgroundModal={() => {
                setShowBgCheckModal(true)
              }}
              insurancePlansLoading={insurancePlansLoading}
              showInsuranceAllowanceTab={showInsuranceAllowanceTab}
              openInsuranceTab={() => {
                updateTabLink('allowance')
              }}
            />

            <Card
              style={{
                backgroundColor: '#FFFFFF',
                boxShadow: '0px 1px 0px #DFE1E6',
                borderRadius: 4,
                height: 'fit-content',
              }}
            >
              <CardBody className='p-0 m-0'>
                <ContractTimelineHeader
                  amended={data?.amended}
                  status={data?.status}
                  lastTimeline={data?.timeline?.[data?.timeline?.length - 1]}
                  ignoreAmendment={isDeContract && data?.file}
                />

                <ContractTimeline timeline={data?.timeline} />
              </CardBody>
              <div className='p-3 p-md-4 border-top'>
                {data?.offer_letter ? (
                  <Dropdown isOpen={menu} toggle={() => setMenu((t) => !t)}>
                    <DropdownToggle
                      className='btn btn-block h-100 m-0 p-0 header-item waves-effect text-primary rp-font-bold'
                      id='withdraw-method-dropdown'
                      tag='button'
                      loading={downloading}
                      disabled={!hasAccess(permissions.DownloadContracts)}
                    >
                      Download
                    </DropdownToggle>
                    <DropdownMenu className='w-100'>
                      <DropdownItem tag='a' onClick={handleDownloadContract}>
                        Contract Agreement
                      </DropdownItem>

                      <DropdownItem tag='a' onClick={handleDownloadOfferLetter}>
                        Offer Letter
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                ) : contract?.file || !isDeContract ? (
                  <div className='tw-flex tw-items-center tw-gap-2'>
                    <Button
                      disabled={
                        !hasAccess(permissions.DownloadContracts) || downloading
                      }
                      onClick={handleDownloadContract}
                      loading={downloading}
                      block
                    >
                      Download
                    </Button>
                    {isDeContract && contract.file && isClient ? (
                      <>
                        <UncontrolledTooltip
                          placement='bottom'
                          target='upload-contract-btn'
                        >
                          Upload new contract file
                        </UncontrolledTooltip>
                        <IconButton
                          id='upload-contract-btn'
                          className='!tw-mt-0 tw-shrink-0 !tw-rounded !tw-border !tw-border-surface-30'
                          disabled={
                            !hasAccess(permissions.DownloadContracts) ||
                            downloading
                          }
                          size={44}
                          onClick={() => setShowUploadConfirmationModal(true)}
                          loading={update.isLoading || isUploadingContractFile}
                          color='transparent'
                          icon={<UploadSimple size={20} />}
                          block
                        />
                      </>
                    ) : null}
                  </div>
                ) : null}

                {isDeContract && !contract.file && isClient ? (
                  <Button
                    disabled={
                      !hasAccess(permissions.DownloadContracts) || downloading
                    }
                    loading={update.isLoading || isUploadingContractFile}
                    icon={<UploadSimple size={20} />}
                    block
                    {...getRootProps()}
                  >
                    Upload Contract
                    <input {...getInputProps()} />
                  </Button>
                ) : null}

                <Button
                  className={cx('mt-3', { 'text-muted': previewing })}
                  onClick={handlePreviewContract}
                  color='light'
                  outline
                  block
                  disabled={previewing}
                  loading={previewing}
                >
                  {previewing ? 'Previewing ..' : 'Preview'}
                </Button>

                {!showTerminateButton ? null : (
                  <Button
                    disabled={isAdmin || terminate.isLoading}
                    onClick={() => {
                      if (isDeContract) {
                        setDeTerminationOpen(true)
                      } else {
                        setShowTerminateConfirmation(true)
                      }
                    }}
                    className={cx('mt-3', {
                      'text-muted': terminate.isLoading,
                    })}
                    loading={terminate.isLoading}
                    color='light'
                    outline
                    block
                  >
                    Terminate
                  </Button>
                )}

                {isContractor ? null : (
                  <ReactivateAction
                    contract={data}
                    isAdmin={isAdmin}
                    onUpdate={updateContractDetail}
                  />
                )}

                {!showCancelTermination ? null : (
                  <>
                    <Button
                      disabled={isAdmin}
                      onClick={() => {
                        setShowTerminateConfirmation(true)
                      }}
                      className='mt-3'
                      color='light'
                      outline
                      block
                    >
                      Termination scheduled
                    </Button>
                  </>
                )}
                {data?.can_cancel && !isFullTime && (
                  <>
                    <Button
                      disabled={
                        isAdmin ||
                        cancelFetch.isLoading ||
                        !hasAccess(permissions.cancelContracts)
                      }
                      loading={cancelFetch.isLoading}
                      onClick={() => setCancelConfirmation(true)}
                      className={cx('mt-3', {
                        'text-muted': cancelFetch.isLoading,
                      })}
                      color='light'
                      block
                      outline
                    >
                      Cancel Contract
                    </Button>
                    <ConfirmationModal
                      isOpen={cancelConfirmation}
                      toggle={() => {
                        setCancelConfirmation(false)
                      }}
                      message='Are you sure you want to cancel this contract? This action is irreversible and the contractor will be notified by email'
                      onConfirm={handleCancelContractClick}
                    />
                  </>
                )}

                {showRequestTerminationBtn && (
                  <>
                    <Button
                      onClick={() => {
                        if (data?.pending_termination_request_id) {
                          setShowTerminationDetails(true)
                        } else {
                          setRequestTerminationFT(true)
                        }
                      }}
                      className='d-flex mt-3'
                      color='light'
                      block
                      outline
                      icon={
                        data?.pending_termination_request_id ? (
                          <Eye size={20} color='#000000' />
                        ) : null
                      }
                    >
                      {data?.pending_termination_request_id
                        ? 'Termination Request'
                        : 'Request Termination'}
                    </Button>
                    {showTerminationDetails && terminationDetails?.data ? (
                      <DetailsModal
                        isOpen={showTerminationDetails}
                        title='Termination Request'
                        toggle={() => setShowTerminationDetails(false)}
                        data={terminationDetails?.data}
                        onCancel={() =>
                          cancelTermination?.startFetch({
                            contract_id: data?.id,
                          })
                        }
                        loading={cancelTermination?.isLoading}
                      />
                    ) : null}
                    {requestTerminationFT ? (
                      <RequestTerminationModal
                        show={requestTerminationFT}
                        hide={() => {
                          setRequestTerminationFT(false)
                        }}
                        contract={data}
                        updated={() => {
                          setRequestTerminationFT(false)
                          updateContractDetail(false)
                        }}
                      />
                    ) : null}
                  </>
                )}

                {!contractAmendable ? null : (
                  <>
                    {fulltimeContractAmendableCheck ? (
                      <UncontrolledTooltip
                        placement='bottom'
                        target='amend-contract-btn'
                      >
                        The current amendment was submitted after the payroll
                        cut-off date and will be reviewed in the next monthly
                        cycle
                      </UncontrolledTooltip>
                    ) : null}
                    <PermissionTooltip
                      showing={!hasAccess(permissions.AmendContracts)}
                      area={PERMISSION_GROUP.CONTRACTS.name}
                      id='amend-contract-btn-tooltip'
                    >
                      <Button
                        id='amend-contract-btn'
                        onClick={() => {
                          data?.file && isDeContract
                            ? setCanAmend(true)
                            : setAmendConfirmation(true)
                        }}
                        disabled={
                          isAdmin ||
                          !hasAccess(permissions.AmendContracts) ||
                          amend.isLoading ||
                          fulltimeContractAmendableCheck
                        }
                        loading={amend.isLoading}
                        className='!tw-mt-4 disabled:!tw-text-text-90'
                        color='light'
                        block
                        outline
                      >
                        {data.file && isDeContract ? 'Edit' : 'Amend'}
                      </Button>
                    </PermissionTooltip>

                    <AmendModal
                      isOpen={amendConfirmation}
                      toggle={() => {
                        setAmendConfirmation(false)
                      }}
                      onProceed={() => {
                        setCanAmend(true)
                        setAmendConfirmation(false)
                      }}
                      isFullTime={isFullTime}
                    />
                  </>
                )}

                {data?.can_edit && (
                  <PermissionTooltip
                    showing={!hasAccess(permissions.UpdateContracts)}
                    area={PERMISSION_GROUP.CONTRACTS.name}
                    id='update-contract-btn-tooltip'
                  >
                    <Button
                      onClick={() => {
                        setCanEdit(true)
                      }}
                      disabled={
                        isAdmin ||
                        update.isLoading ||
                        !hasAccess(permissions.UpdateContracts) ||
                        canEdit
                      }
                      loading={update.isLoading}
                      className='mt-3'
                      color='light'
                      block
                      outline={!canEdit}
                    >
                      {canEdit ? 'Editing' : 'Edit Contract'}
                    </Button>
                  </PermissionTooltip>
                )}
                {data?.can_edit &&
                  data?.template_type !== 'uploaded' &&
                  !isFullTime &&
                  FEATURE_FLAGS.REMOTEPASS_EOR && (
                    <PermissionTooltip
                      showing={!hasAccess(permissions.UpdateContracts)}
                      area={PERMISSION_GROUP.CONTRACTS.name}
                      id='update-template-btn-tooltip'
                    >
                      <Button
                        onClick={() => {
                          history.push({
                            pathname: '/template-editor',
                            state: { contract_id: data?.id },
                          })
                        }}
                        disabled={
                          isAdmin ||
                          !hasAccess(permissions.UpdateContracts) ||
                          canEdit
                        }
                        color='light'
                        block
                        outline={!canEdit}
                        className='mt-3'
                      >
                        {canEdit ? 'Editing' : 'Edit Template'}
                      </Button>
                    </PermissionTooltip>
                  )}

                {!!data?.amended &&
                  user?.type === userTypes.COMPANY &&
                  (isCor ? !corClientSignedSow : true) && (
                    <>
                      <PermissionTooltip
                        showing={!hasAccess(permissions.cancelAmendment)}
                        area={PERMISSION_GROUP.CONTRACTS.name}
                        id='cancel-amendment-btn-tooltip'
                      >
                        <Button
                          onClick={() => setCancelAmendConfirmation(true)}
                          disabled={
                            isAdmin ||
                            !hasAccess(permissions.cancelAmendment) ||
                            cancelAmend.isLoading
                          }
                          loading={cancelAmend.isLoading}
                          className='mt-3'
                          color='light'
                          outline
                          block
                        >
                          {`Cancel ${
                            data?.file && isDeContract ? 'Edit' : 'Amendment'
                          }`}
                        </Button>
                      </PermissionTooltip>
                      <ConfirmationModal
                        isOpen={cancelAmendConfirmation}
                        loading={cancelAmend.isLoading}
                        toggle={() => {
                          setCancelAmendConfirmation(false)
                        }}
                        message={`Are you sure you want to cancel this contract ${
                          data?.file && isDeContract ? 'Edit' : 'Amendment'
                        }? This action is irreversible`}
                        onConfirm={() => {
                          cancelAmend.startFetch({ contract_id: data?.id })
                        }}
                      />
                    </>
                  )}

                {data?.status?.id === CONTRACT_STATUS.ONGOING.value &&
                  !isFullTime &&
                  user?.type === userTypes.COMPANY && (
                    <PermissionTooltip
                      showing={!hasAccess(permissions.addEquipment)}
                      area={PERMISSION_GROUP.CONTRACTS.name}
                      id='add-equipment-btn-tooltip'
                    >
                      <Button
                        disabled={
                          isAdmin || !hasAccess(permissions.addEquipment)
                        }
                        onClick={() => setShowEquipmentModal(true)}
                        className='mt-3'
                        color='light'
                        outline
                        block
                      >
                        Add Equipment
                      </Button>
                    </PermissionTooltip>
                  )}

                <TerminationModal
                  loading={
                    getContractDetailsLoading ||
                    scheduleTerminationLoading ||
                    fetchingContractDetailsData
                  }
                  isOpen={showTerminateConfirmation}
                  toggle={() => setShowTerminateConfirmation(false)}
                  onTerminationConfirm={handleTerminateContract}
                  onCancelTerminationConfirm={() => updateContractDetail(false)}
                  contract={data}
                />

                {!deTerminationOpen ? null : (
                  <DeTerminationModal
                    isOpen={deTerminationOpen}
                    contract={data}
                    toggle={() => setDeTerminationOpen((open) => !open)}
                    onConfirm={() => updateContractDetail(false)}
                  />
                )}
              </div>
              <ConfirmationModal
                title='Incomplete Profile'
                message='You need to complete your profile before signing this contract'
                isOpen={showNeedToCompleteProfile}
                toggle={() => {
                  setShowNeedToCompleteProfile(false)
                }}
                caption='Complete profile now'
                onConfirm={() => {
                  dispatch(updateContractRef({ ref: data?.ref }))
                  history.push('/settings')
                }}
              />
            </Card>
          </Col>
        )}
      </Row>

      {(canAmend || canEdit) && (
        <div
          id='footer-amend'
          className='tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-z-10 lg:tw-left-[--user-sidebar-width]'
        >
          <Container fluid={true}>
            <Row
              className='bg-soft-primary p-4 justify-content-center gap-16'
              style={{ backdropFilter: 'blur(2px)' }}
            >
              <Button
                disabled={amend.isLoading || update.isLoading}
                onClick={() => {
                  if (!amendData && !deChanges) {
                    handleCancelAmendChanges()
                  } else {
                    setAmendCancellation(true)
                  }
                }}
                color='danger'
                outline
              >
                Cancel
              </Button>
              <Button
                disabled={
                  (!amendData && !deChanges) ||
                  amend.isLoading ||
                  update.isLoading
                }
                loading={amend.isLoading || update.isLoading}
                onClick={handleSaveAmendChanges}
              >
                Apply changes
              </Button>
              <CancelAmendmentModal
                isOpen={amendCancellation}
                toggle={() => {
                  setAmendCancellation(false)
                }}
                onAgree={handleCancelAmendChanges}
              />
            </Row>
          </Container>
        </div>
      )}

      {editPayment && (
        <FormsModal
          title={isFullTime ? 'Amend compensation' : 'Edit Payment'}
          isOpen={editPayment}
          compact={isFullTime}
          toggle={() => {
            setEditPayment(false)
          }}
          onConfirm={() => {
            editPaymentRef.current.submit()
          }}
        >
          <PaymentForm
            ref={editPaymentRef}
            data={data}
            isAdmin={isAdmin}
            amendData={amendData}
            isAmend={canAmend || canEdit}
            onSubmit={(v) => {
              const body = { ...amendData, contract_id: data?.id, ...v }
              if (!v.end_date) {
                delete body?.end_date
                delete data?.end_date
              }
              if (
                body.amount &&
                body.currency_id &&
                (body.first_payment_date || isFullTime) &&
                body.frequency_id &&
                body.occurrence_id &&
                body.start_date &&
                body.start_date !== 'Invalid date'
              ) {
                setAmendData(body)
                setEditPayment(false)
              } else {
                toastr.error('Missing required field')
              }
            }}
            isUpdate
          />
        </FormsModal>
      )}

      <FormsModal
        title='Edit employee info'
        isOpen={editEmpInfo}
        compact
        toggle={() => {
          setEditEmpInfo(false)
        }}
        onConfirm={() => {
          editEmpInfoRef.current.requestSubmit()
        }}
      >
        <EmployeeInfoForm
          ref={editEmpInfoRef}
          data={data}
          amendData={amendData}
          onSubmit={(v) => {
            setAmendData({ ...amendData, contract_id: data?.id, ...v })
            setEditEmpInfo(false)
          }}
        />
      </FormsModal>

      <FormsModal
        title='Edit Allowances'
        isOpen={editAllowances}
        compact
        toggle={() => {
          setEditAllowances(false)
        }}
        onConfirm={() => {
          editAllowancesRef.current.submit()
        }}
      >
        <AllowancesForm
          ref={editAllowancesRef}
          data={data}
          amendData={amendData}
          onSubmit={(v) => {
            setAmendData({ ...amendData, contract_id: data?.id, ...v })
            setEditAllowances(false)
          }}
        />
      </FormsModal>
      <FormsModal
        title='Edit Info'
        isOpen={editInfo}
        toggle={() => {
          setEditInfo(false)
        }}
        onConfirm={() => {
          editInfoRef.current.submit()
        }}
      >
        <InfoForm
          ref={editInfoRef}
          data={data}
          amendData={amendData}
          onSubmit={(v) => {
            setAmendData({ ...amendData, contract_id: data?.id, ...v })
            setEditInfo(false)
          }}
        />
      </FormsModal>
      <FormsModal
        title='Edit Compliance'
        isOpen={editCompliance}
        toggle={() => {
          setEditCompliance(false)
        }}
        onConfirm={() => editComplianceRef.current.requestSubmit()}
        isLoading={isUploading}
        isActionsDisabled={isUploading}
      >
        <ComplianceForm
          ref={editComplianceRef}
          data={data}
          amendData={amendData}
          isAmend={canAmend}
          onUploadStatusChanged={setIsUploading}
          onSubmit={(v, file, notice, contractMedia, template) => {
            if (contractMedia === -1 || !contractMedia) {
              toastr.error('Contract is missing', 'error')
              return
            }
            if (
              contractMedia === CONTRACT_TYPE_KEY.YOUR_TEMPLATE &&
              !template
            ) {
              toastr.error('Please select one of your templates', 'error')
              return
            }
            const body = {
              ...amendData,
              contract_id: data?.id,
              ...v,
              notice_period: notice,
              client_can_submit: true,
              file,
              template_id: template?.value,
              template,
            }
            if (contractMedia === CONTRACT_TYPE_KEY.RP_TEMPLATE) {
              body.rw_file = true
            }
            setAmendData(body)
            setEditCompliance(false)
          }}
          isAdmin={isAdmin}
          uploaded={data?.uploaded}
        />
      </FormsModal>
      <FormsModal
        title='Add Annex'
        isOpen={addAnnex}
        toggle={() => {
          setAddAnnex(false)
        }}
        onConfirm={() => annexRef.current.submit()}
        isLoading={isUploading}
      >
        <AnnexForm
          ref={annexRef}
          newAnnexes={newAnnexes}
          data={defaultAnnexes}
          toggle={() => setAddAnnex(false)}
          onUpdateContract={() => updateContractDetail(false)}
          onUploadStatusChanged={setIsUploading}
          onSubmit={(name, path) => {
            if (!name) {
              toastr.error('Name is missing', 'error')
              return
            }
            if (!path) {
              toastr.error('File is missing', 'error')
              return
            }
            const annexesToAdd = [...newAnnexes]
            annexesToAdd.push({ name, path })
            setNewAnnexes(annexesToAdd)
            const body = {
              ...amendData,
              contract_id: data?.id,
              annexes: annexesToAdd,
            }
            setAmendData(body)
            setAddAnnex(false)
          }}
          handleDeleteAnnex={handleDeleteAnnex}
        />
      </FormsModal>
      <ModalInvitation
        show={showInvitePopup}
        type={user?.type === userTypes.CONTRACTOR ? 'client' : 'contractor'}
        data={data}
        hide={() => setShowInvitePopup(false)}
        message={`Invite your ${
          user?.type === userTypes.COMPANY
            ? data?.contractor_name?.toLowerCase()
            : 'client'
        }`}
        onUpdate={() => {
          updateContractDetail(false)
          setShowInvitePopup(false)
        }}
      />
      <ModalInvitation
        show={showInviteEmployee}
        type='employee'
        data={data}
        hide={() => setShowInviteEmployee(false)}
        message='Invite your employee'
        onUpdate={() => {
          updateContractDetail(false)
          setShowInviteEmployee(false)
        }}
      />
      {showSignModal && (
        <SignModal
          data={data}
          downloading={downloading}
          handleDownloadContract={handleDownloadContract}
          handleSignClick={handleSignClick}
          loadingSignature={loadingSignature}
          setShowSignModal={setShowSignModal}
          showSignModal={showSignModal}
        />
      )}

      {showFulltimePayment && (
        <ConfirmationModal
          isOpen={showFulltimePayment}
          message='Next step, process the deposit payment to activate the employee onboarding process.'
          onConfirm={() => {
            history.push(`/contract/detail?id=${data?.ref}&tab=payments`)
            setShowFulltimePayment(false)
          }}
          title='Deposit Payment'
          caption='Pay'
          toggle={() => setShowFulltimePayment(false)}
        />
      )}
      {showUploadConfirmationModal && (
        <ConfirmationModal
          isOpen={showUploadConfirmationModal}
          title={
            <div className='tw-flex tw-flex-col tw-gap-2'>
              <Warning color='gold' size={24} />
              <div className='tw-text-xl tw-font-medium'>
                Are you sure you want to upload a new contract?
              </div>
              <div className='tw-text-sm tw-font-light tw-text-text-80'>
                Uploading a new contract will replace the existing one.
              </div>
            </div>
          }
          loading={isUploadingContractFile || update.isLoading}
          onConfirm={() => {
            setShowUploadConfirmationModal(false)
            setShowUploadFileModal(true)
          }}
          caption='Yes, Upload new contract'
          negativeCaption='No, Close'
          toggle={() => setShowUploadConfirmationModal(false)}
        />
      )}

      {showUploadFileModal && (
        <Modal
          centered
          size='lg'
          isOpen={showUploadFileModal}
          toggle={() => setShowUploadFileModal(false)}
        >
          <ModalHeader toggle={() => setShowUploadFileModal(false)}>
            <div className='tw-flex tw-flex-col tw-gap-2'>
              <UploadSimple size={24} />
              <div className='tw-text-xl tw-font-medium'>
                Upload new contract
              </div>
              <div className='tw-text-sm tw-font-light tw-text-text-80'>
                Keep your agreements current by uploading the latest version
              </div>
            </div>
          </ModalHeader>
          <ModalBody className='tw-flex tw-flex-col tw-gap-4'>
            <DropzoneInput
              accept={{ 'application/pdf': ['.pdf'] }}
              multiple={false}
              onDropAccepted={(files) => {
                setDeChanges({ tempUploadFile: files[0], directUpload: false })
              }}
              className='tw-flex tw-items-center tw-justify-between tw-p-6'
            >
              <UploadAreaContent
                description='Upload a valid contract'
                startClassName='tw-text-black'
                title={
                  deChanges?.tempUploadFile
                    ? deChanges?.tempUploadFile?.name
                    : 'New Contract'
                }
                acceptLabel='*PDF only'
              />
            </DropzoneInput>
            <Alert
              className='!tw-bg-systemGold-20 !tw-text-text-80'
              color='warning'
              customIcon={<Warning color='gold' />}
            >
              Uploading a new contract will replace the existing one.
            </Alert>
          </ModalBody>
          <ModalFooter>
            <Button
              className='!tw-border-surface-30'
              outline
              color='secondary'
              onClick={() => setShowUploadFileModal(false)}
            >
              Cancel
            </Button>

            <Button
              onClick={() =>
                uploadContractFile({ file: deChanges.tempUploadFile })
              }
              disabled={!deChanges?.tempUploadFile}
              loading={isUploadingContractFile || update.isLoading}
            >
              Done
            </Button>
          </ModalFooter>
        </Modal>
      )}

      {showEquipmentModal && (
        <AddEquipmentModal
          contractId={data?.id}
          isOpen={showEquipmentModal}
          toggle={() => {
            setShowEquipmentModal(false)
          }}
          onEquipmentAdded={() => {
            updateContractDetail()
            updateTabLink('equipment')
          }}
        />
      )}

      {
        // Should only be rendered when opened
        showBgCheckModal && (
          <RequestBgCheckModal
            isOpen
            toggle={() => setShowBgCheckModal(false)}
            itemsToCheck={[
              {
                name: getFullName(data?.employee ?? data?.contractor),
                raw: {
                  id: data?.id,
                  contractor: data?.employee ?? data?.contractor,
                  ref: data?.ref,
                },
                status: (
                  <ContractBadge
                    amended={data?.amended}
                    status={data?.status}
                    lastTimeline={data?.timeline?.[data?.timeline?.length - 1]}
                  />
                ),
              },
            ]}
            onSuccess={() =>
              getBackgroundCheckAction({
                userId: data?.employee?.id ?? data?.contractor?.id,
              })
            }
          />
        )
      }
    </>
  )
}

function PartyCard({ children, show, action, bottomChildren }) {
  if (!show) {
    return null
  }

  return (
    <div className='rp-shadow-2 rounded flex-wrap align-items-center justify-content-md-between tw-flex tw-bg-white'>
      <div className='justify-content-between align-items-center tw-flex'>
        {children}
      </div>
      <FormSectionHr className='tw-mx-0' />
      <div
        className='align-items-center flex-column flex-grow-1 flex-md-grow-0 gap-6 justify-content-end p-3 p-md-4 tw-flex'
        style={{ minWidth: 220 }}
      >
        {action}
      </div>
      {bottomChildren && (
        <div className='border-top p-3 tw-w-full tw-bg-white tw-text-xs tw-text-text-80'>
          {bottomChildren}
        </div>
      )}
    </div>
  )
}

const DetailsWrapper = ({ children }) => (
  <Card className='!tw-mx-0 !tw-mb-4 !tw-mt-0 !tw-p-0 tw-shadow md:!tw-p-6'>
    <CardBody className='!tw-m-0 !tw-p-0'>
      <div className='tw-rounded tw-p-4 md:tw-p-0'>{children}</div>
    </CardBody>
  </Card>
)

function Annexes({ annexes }) {
  if (!annexes || !Array.isArray(annexes)) return

  return annexes.map((annex) => {
    return <AnnexLine key={annex.name} annex={annex} />
  })
}
function AnnexLine({ annex }) {
  return (
    <tr key={'_order_' + annex.name}>
      <td style={{ whiteSpace: 'pre-line' }}>{annex.name}</td>

      <td style={{ textAlign: 'right' }}>
        {format(annex.created_at * 1000, 'MM/dd/yyyy hh:mm')}
      </td>
    </tr>
  )
}
function EditedAnnexes({ annexes, deleteAnnex, canEdit }) {
  if (!annexes || !Array.isArray(annexes)) return

  return annexes.map((annex) => {
    return (
      <EditedAnnexLine
        key={annex.name}
        annex={annex}
        deleteAnnex={deleteAnnex}
        canEdit={canEdit}
      />
    )
  })
}
function EditedAnnexLine({ annex, deleteAnnex, canEdit }) {
  return (
    <tr key={'_order_' + annex.name}>
      <td style={{ whiteSpace: 'pre-line' }}>{annex.name}</td>
      <td style={{ textAlign: 'right' }}>
        {format(new Date(), 'MM/dd/yyyy hh:mm')}
      </td>
      <td style={{ textAlign: 'right' }}>
        {canEdit ? (
          <i
            className='bx bx-trash text-danger font-size-18'
            style={{ paddingRight: '50px', cursor: 'pointer' }}
            onClick={() => deleteAnnex(annex)}
          />
        ) : !annex.id ? (
          <i
            className='bx bx-trash text-danger font-size-18'
            style={{ paddingRight: '50px', cursor: 'pointer' }}
            onClick={() => deleteAnnex(annex)}
          />
        ) : null}
      </td>
    </tr>
  )
}

function getKybModalText(status) {
  switch (status) {
    case 'pending': {
      return {
        content: (
          <div className='font-size-16 py-3 text-center text-secondary'>
            You need to confirm your company details.
          </div>
        ),
        caption: 'Proceed',
      }
    }
    case 'rejected': {
      return {
        content: (
          <div className='font-size-16 py-3 text-center text-secondary'>
            Your previous submission was rejected. Please confirm your company
            details.
          </div>
        ),
        caption: 'Proceed',
      }
    }
    case 'submitted': {
      return {
        content: (
          <div className='font-size-16 py-3 text-center text-secondary'>
            Your account is still pending verification.
          </div>
        ),
        caption: 'Ok',
      }
    }
    default: {
      return { content: '', caption: '' }
    }
  }
}
function DetailsModal({
  isOpen,
  toggle,
  onClosed = () => {},
  title,
  centered = true,
  data,
  onCancel,
  loading,
}) {
  return (
    <Modal
      isOpen={isOpen}
      centered={centered}
      toggle={toggle}
      onClosed={onClosed}
    >
      <ModalHeader toggle={toggle}>
        <div>
          <p className='rp-font-semibold font-size-20 mb-0'>{title}</p>
          <p className='text-secondary-80 font-size-14 mb-1'>
            {format(new Date(data?.updated_at), 'MMM dd, yyyy H:mm:ss')}
          </p>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className='d-flex border-bottom mb-2 flex-row align-items-center'>
          <p className='text-text-100 font-size-14 flex-1'>Reason</p>

          <p className='text-text-black font-size-14 rp-font-semibold flex-1'>
            {data?.reason}
          </p>
        </div>
        {data?.tentative_last_day ? (
          <div className='d-flex border-bottom mb-2 flex-row align-items-center'>
            <p className='text-text-100 font-size-14 flex-1'>
              Tentative last day
            </p>

            <p className='text-text-black font-size-14 rp-font-semibold flex-1'>
              {format(new Date(data?.tentative_last_day), 'dd MMMM yyyy')}
            </p>
          </div>
        ) : null}
        <Button
          outline
          block
          color='light'
          className='rp-font-bold'
          onClick={onCancel}
          loading={loading}
          disabled={loading}
        >
          Cancel Termination
        </Button>
      </ModalBody>
    </Modal>
  )
}

const CAROUSEL_KEY = {
  INSURANCE: 'insurance',
  BACKGROUND_CHECK: 'backgroundCheck',
}

function backgroundCheckDetails({ contractor, openBackgroundModal, status }) {
  const types = {
    [backgroundCheckType.request]: {
      title: `Request a background check on ${contractor}`,
      subTitle: `${contractor} will be notified to provide consent and the report will be available under Documents`,
      buttonText: 'Request',
      icon: <ArrowRight size={20} />,
      onClick: () => openBackgroundModal(),
    },
    [backgroundCheckType.requested]: {
      title: `The background check for ${contractor} is requested!`,
      subTitle: `${contractor} is notified to consent for the background check`,
      buttonText: 'Requested',
      icon: <Check size={20} />,
      class: '!tw-bg-systemGreen-10 !tw-text-systemGreen !tw-border-none',
    },
    [backgroundCheckType.inProgress]: {
      title: `The background check for ${contractor} is in progress!`,
      subTitle: `${contractor} consented for the background check`,
      buttonText: 'In Progress',
      icon: <DotsThreeOutline size={20} />,
      class: '!tw-bg-systemGold-20 !tw-text-systemGold-110 !tw-border-none',
    },
    [backgroundCheckType.complete]: {
      title: `The background check for ${contractor} is completed!`,
      subTitle: 'The report is ready for download',
    },
  }

  return types[status] || types[backgroundCheckType.request]
}

function getFeatureSlideKeys({
  showInsuranceAllowanceTab,
  insurancePlansLoading,
  showBackgroundCheckFeature,
}) {
  return [
    showInsuranceAllowanceTab && !insurancePlansLoading
      ? CAROUSEL_KEY.INSURANCE
      : null,
    showBackgroundCheckFeature ? CAROUSEL_KEY.BACKGROUND_CHECK : null,
  ].filter(Boolean)
}

function FeatureBanners({
  userProfile,
  contractor,
  bgCheckStatus,
  countryFeaturesData,
  backgroundCheck,
  showBackgroundCheckFeature,
  openBackgroundModal,
  insurancePlansLoading,
  showInsuranceAllowanceTab,
  openInsuranceTab,
}) {
  const { hasAccess } = usePermissions()
  const formattedTotalCost = getCurrencyFormatter().format(
    userProfile.company?.background_check_fee,
  )
  const insuranceCarousel = (
    <CarouselItem
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
      key={1}
    >
      <Card className='!tw-mb-0 tw-p-6 md:tw-min-h-[380px]'>
        <div className='tw-absolute tw-right-0 tw-top-0 tw-m-5'>
          <X
            onClick={() => {
              dismissBanner({ key: CAROUSEL_KEY.INSURANCE })
            }}
            className='tw-cursor-pointer'
            size={24}
            weight='regular'
          />
        </div>
        <div className='tw-mb-4 tw-self-start tw-rounded-full tw-bg-red-20 tw-p-4'>
          <ShieldPlus weight='fill' size={24} className='tw-text-red-100' />
        </div>

        <span className='tw-flex tw-text-text-80'>
          RemoteHealth by SafetyWing
          <a
            href='https://help.remotepass.com/en/articles/5705047-what-is-remotepass-health'
            target='_blank'
            rel='noreferrer'
          >
            <ArrowSquareOut className='tw-ml-1 tw-text-black' size={20} />
          </a>
        </span>

        <p className='tw-mb-2 tw-text-2xl tw-font-bold tw-text-black'>
          Offer a health benefits allowance to {contractor}
        </p>
        <p className='tw-mb-6 tw-text-sm tw-font-medium tw-text-text-80'>
          {contractor} will have the option to use the allowance to purchase
          health insurance directly on our platform.
        </p>

        <Button
          color='danger'
          className='tw-mt-auto tw-px-6 tw-py-4 tw-text-sm tw-font-bold'
          iconRight={<ArrowRight size={20} />}
          onClick={openInsuranceTab}
          id='health'
        >
          View Details
        </Button>
      </Card>
    </CarouselItem>
  )

  const details = backgroundCheckDetails({
    contractor,
    openBackgroundModal,
    status: [getBgCheckStatus(bgCheckStatus?.status, bgCheckStatus?.consent)],
  })
  const backgroundCheckCarousel = (
    <CarouselItem
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
      key={2}
    >
      <Card className='!tw-mb-0 tw-p-6 md:tw-min-h-[380px]'>
        <div className='tw-absolute tw-right-0 tw-top-0 tw-m-5'>
          <X
            onClick={() => {
              dismissBanner({ key: CAROUSEL_KEY.BACKGROUND_CHECK })
            }}
            className='tw-cursor-pointer'
            size={24}
            weight='regular'
          />
        </div>
        <div className='tw-mb-4 tw-self-start tw-rounded-full tw-bg-primary-20 tw-p-4'>
          <SealCheck weight='fill' size={24} className='tw-text-primary' />
        </div>

        <span className='tw-flex tw-text-text-80'>
          Softcheck by Certn
          <span className='tw-ml-1 tw-flex tw-text-black'>
            {formattedTotalCost}
            <a
              href='https://help.remotepass.com/en/articles/9330637-how-to-run-a-background-check-for-contractors-and-employees'
              target='_blank'
              rel='noreferrer'
            >
              <ArrowSquareOut className='tw-ml-1 tw-text-black' size={20} />
            </a>
          </span>
        </span>

        <p className='tw-mb-2 tw-text-2xl tw-font-bold tw-text-black'>
          {details?.title}
        </p>
        <p className='tw-mb-6 tw-text-sm tw-font-medium tw-text-text-80'>
          {details?.subTitle}
        </p>

        {bgCheckStatus?.status !== 'RETURNED' ? (
          !countryFeaturesData ? (
            <Loader />
          ) : (
            <>
              <Button
                className={cx(
                  'tw-mt-auto tw-px-6 tw-py-4 tw-text-sm tw-font-bold',
                  details?.class,
                )}
                iconRight={details?.icon}
                onClick={details?.onClick}
                disabled={
                  (countryFeaturesData?.length > 0 &&
                    !countryFeaturesData?.[0]?.background_check_enabled) ||
                  !hasAccess(permissions.RequestContractsDocuments)
                }
                id='check'
              >
                {details?.buttonText}
              </Button>
              {((countryFeaturesData?.length > 0 &&
                !countryFeaturesData?.[0]?.background_check_enabled) ||
                !hasAccess(permissions.RequestContractsDocuments)) && (
                <UncontrolledTooltip target='check'>
                  {!hasAccess(permissions.RequestContractsDocuments)
                    ? 'You do not have permission to manage documents. Please contact your administrator.'
                    : 'Unsupported country'}
                </UncontrolledTooltip>
              )}
            </>
          )
        ) : (
          <div className='tw-rounded tw-border tw-border-primary'>
            <DownloadReport
              className='tw-mt-auto tw-h-10 tw-w-full'
              applicantId={backgroundCheck?.applicant?.id}
              userId={backgroundCheck?.applicant?.userId}
            />
          </div>
        )}

        {getBgCheckStatus(bgCheckStatus?.status, bgCheckStatus?.consent) ===
          backgroundCheckType.requested && (
          <span className='tw-mt-2'>
            Invite expires on{' '}
            <strong>
              {format(
                add(
                  new Date(
                    backgroundCheck?.userActivityLog?.requestCheck?.date,
                  ),
                  { days: 14 },
                ),
                'do MMMM, yyyy',
              )}
            </strong>
          </span>
        )}
      </Card>
    </CarouselItem>
  )

  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const [slideKeys, setSlideKeys] = useState(() =>
    getFeatureSlideKeys({
      showInsuranceAllowanceTab,
      insurancePlansLoading,
      showBackgroundCheckFeature,
    }),
  )

  const slides = [
    {
      key: CAROUSEL_KEY.INSURANCE,
      carousel: insuranceCarousel,
    },
    {
      key: CAROUSEL_KEY.BACKGROUND_CHECK,
      carousel: backgroundCheckCarousel,
    },
  ].filter(({ key }) => slideKeys.includes(key))

  useEffect(() => {
    const newSlides = getFeatureSlideKeys({
      showInsuranceAllowanceTab,
      showBackgroundCheckFeature,
      insurancePlansLoading,
    })
    setSlideKeys(newSlides)
  }, [
    showInsuranceAllowanceTab,
    showBackgroundCheckFeature,
    insurancePlansLoading,
  ])

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === slides.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? slides.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = (newIndex) => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  function dismissBanner({ key: dismissKey } = {}) {
    if (animating) return
    setSlideKeys((prevKeys) => prevKeys.filter((key) => key !== dismissKey))
    if (slideKeys.length) goToIndex(0)
  }

  return !slides.length ? null : (
    <div>
      <Carousel
        activeIndex={activeIndex}
        next={next}
        interval={10000}
        previous={previous}
        ride='carousel'
        className='tw-mb-11'
      >
        {slides.map((slide) => slide.carousel)}
        {slides?.length > 1 ? (
          <CarouselIndicators
            items={slides.map((slide) => slide.carousel)}
            activeIndex={activeIndex}
            onClickHandler={goToIndex}
            className='!tw-bottom-[-40px] tw-my-2 [&>li]:!tw-size-2 [&>li]:tw-rounded-[50%] [&>li]:!tw-bg-secondary-70'
          />
        ) : null}
      </Carousel>
    </div>
  )
}

export default ContractDetail
