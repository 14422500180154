import { yupResolver } from '@hookform/resolvers/yup'
import React, { forwardRef } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import * as yup from 'yup'

import ControlledInput from '../../../../components/ControlledInput'
import ControlledSelect from '../../../../components/ControlledSelect'
import { mapCountryToOption } from '../../../../utils/map-to-option'

const schema = yup.object().shape({
  employee_first_name: yup
    .string()
    .required("Employee's first name is required"),
  employee_middle_name: yup.string().notRequired(),
  employee_last_name: yup.string().required('Employee last name is required'),
  employee_country_id: yup.string().required('Nationality is required'),
})

const EmployeeInfoForm = forwardRef(function InfoForm(
  { data, onSubmit, amendData },
  ref,
) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      employee_first_name:
        amendData?.employee_first_name || data?.employee.first_name,
      employee_middle_name:
        amendData?.employee_middle_name || data?.employee.middle_name,
      employee_last_name:
        amendData?.employee_last_name || data?.employee.last_name,
      employee_email: amendData?.employee_email || data?.employee.email,
      employee_country_id:
        parseInt(amendData?.employee_country_id) || data?.employee?.country?.id,
    },
    resolver: yupResolver(schema),
  })
  const { countries, other_countries: otherCountries } = useSelector(
    (state) => state.Layout.staticData ?? {},
  )
  const nationalities = countries
    ? [...countries, ...otherCountries].map((c) => mapCountryToOption(c))
    : []

  return (
    <form onSubmit={handleSubmit(onSubmit)} ref={ref}>
      <ControlledInput
        control={control}
        name='employee_first_name'
        label='First Name'
        error={!!errors?.employee_first_name}
      />
      <ControlledInput
        control={control}
        wrapperClassName='tw-mt-4'
        name='employee_middle_name'
        label='Middle Name'
      />
      <ControlledInput
        control={control}
        wrapperClassName='tw-mt-4'
        name='employee_last_name'
        label='Last Name'
      />
      <ControlledInput
        control={control}
        wrapperClassName='tw-mt-4'
        name='employee_email'
        label='Personal Email'
      />
      <ControlledSelect
        options={nationalities}
        className='tw-mb-4'
        label={
          <div className='tw-mt-4'>
            Nationality
            <span className='text-danger font-size-16 ml-1'>*</span>
          </div>
        }
        control={control}
        name='employee_country_id'
      />
    </form>
  )
})
export default EmployeeInfoForm
