import { stratify, tree } from 'd3-hierarchy'

import { getIncomers } from '@xyflow/react'
import { VIEW } from '.'

const layout = tree().separation(() => 1)

const counterSize = { width: 50, height: 30 }
const profileSize = { width: 220, height: 70 }
const departmentInfoSize = { width: 230, height: 50 }

export function d3HierarchyLayout({ nodes, edges, options } = {}) {
  // Getting the spacings with a default of 0 if not provided
  const { view, spacing: [spacingX, spacingY] = [0, 0] } = options ?? {}

  // Determine the largest node size to pass to the layout
  const largestNode =
    view === VIEW.DEPARTMENT ? departmentInfoSize : profileSize

  const nodeSize = [largestNode.width + spacingX, largestNode.height + spacingY]
  layout.nodeSize(nodeSize)

  const initialNodes = []
  for (const node of nodes) {
    initialNodes.push({ ...node, ...node.position })
  }

  function getParentId(node) {
    if (node.id === 'manager-root' || node.id === 'department-root') {
      return undefined
    }
    const incomers = getIncomers(node, nodes, edges)
    return incomers[0]?.id
  }

  const hierarchy = stratify()
    .id((d) => d.id)
    .parentId(getParentId)(initialNodes)

  const root = layout(hierarchy)
  const layoutNodes = new Map()

  // Map node positions from the layout
  for (const node of root) {
    layoutNodes.set(node.id, node)
  }

  const nextNodes = nodes.map((node) => {
    const { x, y } = layoutNodes.get(node.id)

    const nodeSizeForType = ['userProfile', 'companyProfile'].includes(
      node.type,
    )
      ? profileSize
      : node.type === 'departmentInfo'
        ? departmentInfoSize
        : counterSize

    const offsetPosition = {
      x: x - nodeSizeForType.width / 2,
      y: y - nodeSizeForType.height / 2,
    }

    return { ...node, position: offsetPosition }
  })

  return { nodes: nextNodes, edges }
}
