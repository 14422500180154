import React, { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import capitalize from 'lodash/capitalize'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import toastr from 'toastr'
import { ModalCloseButton } from '../../../../components/Common/modal-close-button'
import Button from '../../../../components/ui/button'
import { CONTRACT_TYPES } from '../../../../core/config/contract-types'
import { userTypes } from '../../../../helpers/enum'
import { useFetch } from '../../../../helpers/hooks'
import {
  inviteContractPart,
  inviteFullTimeEmployee,
  segmentBatch,
  segmentTrack,
} from '../../../../services/api'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import ControlledInput from '../../../../components/ControlledInput'
import { ControlledToggle } from '../../../../components/Forms/Toggle/controlled-toggle'
import { updateUserProfileInfo } from '../../../../store/profile/actions'

const inviteFormId = 'invite-form'

const ModalInvitation = ({ show, hide, message, type, data, onUpdate }) => {
  const user = useSelector((state) => state.userProfile?.userProfile)
  const dispatch = useDispatch()

  const { startFetch: inviteClient, isLoading } = useFetch({
    action: type === 'employee' ? inviteFullTimeEmployee : inviteContractPart,
    onComplete: (r, { email }) => {
      if (data?.save_custom_message) {
        dispatch(
          updateUserProfileInfo({
            custom_invite_message: data?.custom_text,
          }),
        )
      }
      const invitedUser = r.user
      const workerType =
        data?.type === CONTRACT_TYPES.FULL_TIME
          ? 'eor'
          : data?.type === CONTRACT_TYPES.DIRECT_EMPLOYEE
            ? 'de'
            : 'contractor'

      const trackData = {
        userId: invitedUser?.id,
        event: 'Invited-to-Contract',
        properties: {
          contract_id: data?.ref,
          contractor_first_name: invitedUser?.first_name,
          contractor_last_name: invitedUser?.last_name,
          client_first_name: user?.first_name,
          client_last_name: user?.last_name,
          invitation_url: r?.invitation_url,
          contract_name: data?.name,
          email,
          type,
          contract_type: data?.type,
          worker_type: workerType,
        },
      }
      if (invitedUser?.first_name) {
        segmentTrack(trackData).then()
      } else {
        segmentBatch({
          batch: [
            {
              type: 'identify',
              userId: invitedUser?.id,
              traits: {
                email,
              },
            },
            {
              type: 'track',
              ...trackData,
            },
          ],
        }).then()
      }

      window.analytics.track(
        user?.type === userTypes.COMPANY
          ? 'Invited contractor'
          : 'Invited client',
        user?.type === userTypes.COMPANY
          ? {
              contract_id: data?.ref,
              contractor_email: email,
            }
          : {
              contract_id: data?.ref,
              client_email: email,
            },
      )
      onUpdate()
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const defaultInviteText = useMemo(() => {
    const isContractor = user?.type === userTypes.CONTRACTOR
    const isClient = user?.type === userTypes.COMPANY
    const isDeContract = data?.type === CONTRACT_TYPES.DIRECT_EMPLOYEE
    const userDefaultText = user.custom_invite_message ?? undefined
    switch (true) {
      case Boolean(userDefaultText):
        return userDefaultText

      case isContractor:
        return 'I’m excited to work with you and invite you to join me on RemotePass for streamlined contract and payment processes. RemotePass is the platform I utilize for contracting and payments.'

      case isClient && isDeContract:
        return 'Hello! We’re excited to have you onboard. You’re invited to review your contract on RemotePass, where you’ll find the details of your employment. RemotePass is the platform we utilize for HR and payroll.'

      case isClient:
        return 'Hello! We’re excited to have you onboard. You’re invited to review and sign your contract on RemotePass, where you’ll find all the terms we’ve discussed. RemotePass is the platform we utilize for compliance and payments.'

      default:
        return 'Hello, welcome onboard. Join us on RemotePass our payroll and compliance platform, to review and sign your contract.'
    }
  }, [data?.type, user])

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      items: [{ customText: defaultInviteText, saveMessageInvitation: true }],
    },
    resolver: yupResolver(
      yup.object().shape({
        email: yup
          .string()
          .email()
          .required('Email is required')
          .label('Email'),
        customText: yup.string().label('Custom Text'),
        saveMessageInvitation: yup.boolean().label(''),
      }),
    ),
  })

  function onSubmit(values) {
    inviteClient({
      email: values?.email,
      contract_id: data?.id,
      custom_text: values?.customText,
      save_custom_message: Number(values?.saveMessageInvitation),
    })
  }

  function handleClose() {
    reset()
    hide()
  }
  return (
    <Modal
      className='custom-modal-style'
      isOpen={show}
      centered
      size='lg'
      toggle={handleClose}
    >
      <ModalHeader
        className='p-4'
        close={<ModalCloseButton toggle={handleClose} />}
      >
        {message}
      </ModalHeader>
      <ModalBody className='tw-flex tw-flex-col tw-p-4'>
        <p className='font-size-16 mb-4' style={{ color: 'var(--zinc-700)' }}>
          Enter the email address of the{' '}
          {user?.type === 'contractor'
            ? 'client'
            : data?.contractor_name?.toLowerCase()}
          . An invitation email will be sent to them.
        </p>
        <form onSubmit={handleSubmit(onSubmit)} id={inviteFormId}>
          <ControlledInput
            control={control}
            name='email'
            id='email'
            label='Email'
            placeholder={`${capitalize(
              user?.type === 'contractor' ? 'client' : data?.contractor_name,
            )} email address ...`}
            type='email'
            className='tw-mb-4'
            labelClassName='tw-mb-2 tw-text-base'
          />
          <ControlledInput
            control={control}
            id='scope'
            name='customText'
            type='textarea'
            defaultValue={defaultInviteText}
            label={
              <p className='tw-mb-0 tw-text-base'>
                Message <span className='font-weight-normal'>(Optional)</span>
              </p>
            }
            placeholder='Invite Message...'
            rows={6}
            className='tw-mb-4 tw-scroll-pb-3'
          />
          <div className='tw-rounded tw-border tw-border-secondary-40 tw-p-3'>
            <div className='tw-flex tw-items-center tw-justify-between tw-gap-4'>
              <div className='tw-text-wrap'>
                <p className='tw-mb-0 tw-text-sm tw-font-semibold'>
                  Save this message for the next invitation
                </p>
                <p className='font-size-14 tw-mb-0 tw-text-wrap'>
                  When this is toggled off, a standard RemotePass invitation
                  message will be displayed here for the next time.
                </p>
              </div>
              <ControlledToggle
                defaultValue={true}
                control={control}
                name='saveMessageInvitation'
                id='saveMessageInvitation'
              />
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter className='!tw-p-6 [&>*]:tw-min-w-[146px]'>
        <Button
          outline
          className='tw-flex-1 !tw-border-surface-30 !tw-text-black hover:!tw-bg-surface-130 md:tw-flex-grow-0'
          disabled={isLoading}
          onClick={handleClose}
          type='button'
        >
          Cancel
        </Button>
        <Button
          loading={isLoading}
          disabled={isLoading}
          formId={inviteFormId}
          type='submit'
          form
          className='tw-flex-1 md:tw-flex-grow-0'
        >
          Send invite
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalInvitation
