import { validatePhoneNumber } from '../../../services/api'

export async function getFullPhoneNumber({ token, mobile, setLoading }) {
  setLoading?.(true)

  let fullMobile = null
  let mobileNumber = null
  let mobileCode = null

  const { data: resp } = await validatePhoneNumber(token, { phone: mobile })

  if (resp?.success && resp?.data?.valid) {
    const code = resp.data?.country?.prefix.slice(1)
    const phone = resp.data?.phone?.slice(code.length)
    fullMobile = resp.data?.phone
    mobileNumber = phone
    mobileCode = code
  }

  setLoading?.(false)

  const data = {
    fullPhoneNumber: fullMobile,
    mobile: mobileNumber,
    mobileCountryCode: mobileCode,
    valid: !!mobileCode,
  }

  return data
}
