import React from 'react'
import { CaretDown, CaretUp, Lightning } from '@phosphor-icons/react'
import { Card } from 'reactstrap'
import { Controller, useFormContext } from 'react-hook-form'
import { cn } from 'ui'
import { TriggerTitle } from './trigger-title'
import { TriggerOccurrences } from './trigger-occurrences'
import { TriggerFilter } from './trigger-filter'
import { TriggerSelect } from './trigger-select'

export function Trigger(props) {
  const { index } = props
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext()
  const hasError = errors?.steps?.[index]
  const focused = watch('focusedNode') === 'trigger'
  return (
    <div
      className={cn(
        'tw-rounded-md',
        hasError ? 'tw-border-2 tw-border-red' : undefined,
        focused ? 'tw-border-2 tw-border-primary-60' : undefined,
      )}
    >
      <Card className='!tw-mb-0 tw-flex !tw-flex-row tw-items-center tw-justify-center tw-gap-2 tw-p-4'>
        <div
          className={cn(
            'tw-rounded tw-bg-secondary-20 tw-p-2',
            focused && 'tw-bg-primary-20',
            hasError && 'tw-bg-red-20',
          )}
        >
          <Lightning
            size={25}
            className={cn(
              'tw-text-secondary-100',
              focused && 'tw-text-primary',
              hasError && 'tw-text-red',
            )}
          />
        </div>
        <div className='tw-flex tw-flex-1 tw-flex-col'>
          <span className='tw-font-medium'>When this happens</span>
          <TriggerTitle index={index} />
        </div>
        <button
          onClick={() => setValue('focusedNode', focused ? null : 'trigger')}
          className='tw-rounded tw-bg-primary-20 tw-p-2'
        >
          {focused ? (
            <CaretUp size={25} className='tw-text-primary' />
          ) : (
            <CaretDown size={25} className='tw-text-primary' />
          )}
        </button>
      </Card>

      {focused ? (
        <div className='tw-flex tw-flex-col tw-gap-3.5 tw-p-3.5'>
          <Controller
            name={`steps.${index}.event`}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TriggerSelect onChange={onChange} error={error} value={value} />
            )}
          />
          <TriggerOccurrences index={index} />
          <TriggerFilter index={index} />
        </div>
      ) : null}
    </div>
  )
}
