import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm, useWatch } from 'react-hook-form'
import * as yup from 'yup'

import { getStepFormId } from '.'
import { ControlledRadioButtonV2 } from '../../../components/ui/radio-button/controlled-radio-button-v2'
import Shimmer from '../../../components/ui/shimmer'
import { useFetch, usePermissions } from '../../../helpers/hooks'
import { bulkUpdateContracts } from '../../../services/api-bulk-operations'
import { CONTRACT_TYPE_LABEL_MAP } from '../../CompanySetting/custom-fields'
import { FormSectionHr } from '../CreateContract/components/form-section-title'
import { useBulkOperationsFlowContext } from './bulk-operations-context'
import {
  BULK_ADJUSTMENT_EVENTS,
  useBulkContractActionEvents,
} from '../bulk-creation/events'
import permissions from '../../../helpers/permissions'
import FEATURE_FLAGS from '../../../config/feature-flags'

function createLoadingOption(value) {
  return { value, label: <Shimmer height={21} />, disabled: true }
}
const loadingOptions = Array.from({ length: 3 }, () => '').map((_, i) => {
  return createLoadingOption(`null-${i + 1}`)
})

export const UPDATE_TYPE = {
  ADDITION: 'addition',
  DEDUCTION: 'deduction',
  RECURRING_EXPENSES: 'recurring_expenses',
}

export default function OperationsStep1({ step, goToNextStep }) {
  const { operationsFormData, setOperationsFormData } =
    useBulkOperationsFlowContext()
  const { trackEvent } = useBulkContractActionEvents()

  const { handleSubmit, control, setValue } = useForm({
    defaultValues: { ...operationsFormData },
    resolver: yupResolver(
      yup.object().shape({
        update_type: yup
          .string()
          .oneOf(Object.values(UPDATE_TYPE))
          .label('Update type')
          .required(),
        contract_type: yup
          .string()
          .oneOf(Object.keys(CONTRACT_TYPE_LABEL_MAP))
          .label('Contract type')
          .when('update_type', {
            is: (val) => val === UPDATE_TYPE.RECURRING_EXPENSES,
            then: (schema) => schema.notRequired(),
            otherwise: (schema) => schema.required(),
          }),
      }),
    ),
  })

  const updateType = useWatch({ control, name: 'update_type' })

  function onSubmit(values) {
    setOperationsFormData(values)
    goToNextStep()
    trackEvent(BULK_ADJUSTMENT_EVENTS.SELECTED_ADJUSTMENT_TYPE, {
      operation_type: values.update_type,
      contract_type: values.contract_type,
    })
  }

  const { data: bulkUpdateContractsData, isLoading: isBulkUpdateLoading } =
    useFetch(
      {
        action: bulkUpdateContracts,
        autoFetch:
          !!updateType && updateType !== UPDATE_TYPE.RECURRING_EXPENSES,
        body: { adjustment_type: updateType },
      },
      [updateType],
    )

  const contractOptions = bulkUpdateContractsData?.map((contract) => ({
    value: contract.contract_type,
    label:
      CONTRACT_TYPE_LABEL_MAP?.[contract.contract_type] ??
      contract.contract_type,
    count: contract.count,
  }))

  const { hasAccess } = usePermissions()
  const updateTypes = [
    { value: UPDATE_TYPE.ADDITION, label: 'Addition' },
    { value: UPDATE_TYPE.DEDUCTION, label: 'Deduction' },
    hasAccess(permissions.manageCompanySettings) &&
      FEATURE_FLAGS.RECURRING_EXPENSES && {
        value: UPDATE_TYPE.RECURRING_EXPENSES,
        label: 'Recurring Expenses',
      },
  ].filter(Boolean)

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={getStepFormId(step)}>
      <ControlledRadioButtonV2
        control={control}
        label='Update type'
        labelDescription='Choose the type of adjustment you want to make.'
        name='update_type'
        id='update_type'
        options={updateTypes}
        transform={{
          output: (event) => {
            setValue('contract_type', null)
            return event
          },
        }}
      />

      {updateType !== UPDATE_TYPE.RECURRING_EXPENSES && (
        <>
          <FormSectionHr />
          <ControlledRadioButtonV2
            control={control}
            label='Contract type'
            labelDescription={
              !updateType ? (
                'Please select an adjustment type'
              ) : contractOptions?.length <= 0 && !isBulkUpdateLoading ? (
                <p className='tw-mb-0 tw-font-bold tw-text-systemBlue-100'>
                  No contracts found!
                </p>
              ) : (
                'Choose the contract type you wish to update.'
              )
            }
            name='contract_type'
            id='contract_type'
            options={
              isBulkUpdateLoading
                ? loadingOptions
                : contractOptions?.map(({ count, ...options }) => {
                    return {
                      ...options,
                      suffix:
                        count >= 1
                          ? `${count} contract${count === 1 ? '' : 's'}`
                          : '',
                      disabled: count <= 0,
                    }
                  })
            }
          />
        </>
      )}
    </form>
  )
}
