import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { cn } from '../lib/utils/cn';
const flagClasses = {
    sm: 'tw-w-2.5 tw-h-2.5 -tw-bottom-0.5 -tw-right-0.5 tw-border',
    md: 'tw-w-4 tw-h-4 -tw-bottom-1 -tw-right-1 tw-border-2',
    lg: 'tw-w-4 tw-h-4 -tw-bottom-0.5 -tw-right-0.5 tw-border-2',
    xl: 'tw-w-5 tw-h-5 -tw-bottom-1 -tw-right-1 tw-border-2',
    special: 'tw-w-6 tw-h-6 -tw-bottom-1 tw-right-0 tw-border-2',
};
const sizeClasses = {
    sm: 'tw-size-6',
    md: 'tw-size-8',
    lg: 'tw-size-10',
    xl: 'tw-size-12',
    special: 'tw-size-[4.5rem]',
};
export function Avatar(props) {
    var _a, _b;
    const { flag, flagIsAbsolute, customFlag, size = 'md', photo, name, label, className, id, icon, } = props;
    const initials = name === null || name === void 0 ? void 0 : name.split(' ').slice(0, 2).map((n) => n.charAt(0));
    return (_jsxs("div", { id: id, className: cn('tw-relative tw-flex tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-text-white', label === 'Client'
            ? 'tw-bg-systemGreen-60'
            : 'tw-bg-primary-20 tw-text-primary-100', (_a = sizeClasses[size]) !== null && _a !== void 0 ? _a : sizeClasses.md, className), children: [icon ? (icon) : photo ? (_jsx("img", { className: 'tw-h-full tw-w-full tw-rounded-full tw-object-cover', src: photo, alt: `${name} profile photo` })) : (_jsx("span", { className: cn(`tw-rounded-full tw-font-medium tw-uppercase tw-leading-none`, size === 'special'
                    ? 'tw-text-2xl'
                    : size === 'lg'
                        ? 'tw-text-sm'
                        : 'tw-text-xs'), children: initials })), !flag ? (customFlag !== null && customFlag !== void 0 ? customFlag : null) : (_jsx("img", { src: flagIsAbsolute ? flag : `/${flag}`, className: cn('tw-absolute tw-block tw-rounded-full tw-border-solid tw-border-white tw-object-cover', (_b = flagClasses[size]) !== null && _b !== void 0 ? _b : flagClasses.md), alt: 'flag' }))] }));
}
