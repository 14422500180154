import { Paperclip } from '@phosphor-icons/react'
import cx from 'classnames'
import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'

import { cn } from 'ui'
import { MAX_SIZE } from '../../pages/Contract/utils/constants'
import { getInputErrorMessage } from '../Forms/get-input-error-message'
import InputFeedback from '../ui/input-feedback'

export default function DropzoneInput({
  children,
  onDropAccepted,
  onDropRejected,
  accept,
  required,
  invalid,
  error,
  name,
  id,
  className,
  inputClassName,
  wrapperClassName,
  rootRef,
  disabled,
  multiple = false,
  style = {},
  unstyled = false,
  maxFiles,
  maxSize = MAX_SIZE,
  onFileDialogOpen,
}) {
  const [formErrors, setFormErrors] = useState('')

  const onDrop = useCallback(() => setFormErrors(''), [])
  if (!onDropRejected) {
    onDropRejected = (e) => {
      if (e?.[0]?.errors?.[0]?.code === 'file-too-large') {
        setFormErrors(`File is larger than ${(maxSize / 1000000).toFixed()}MB`)
      } else {
        setFormErrors(e?.[0]?.errors?.[0]?.message)
      }
    }
  }
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept,
    multiple,
    onDropAccepted,
    onDropRejected,
    disabled,
    maxFiles,
    maxSize,
    onDrop,
    onFileDialogOpen,
  })

  return (
    <div className={wrapperClassName}>
      <div
        {...getRootProps({
          className: cn(
            !unstyled && 'tw-rounded tw-p-2 tw-cursor-pointer',
            !unstyled && {
              'tw-text-primary-100 tw-bg-primary-20 tw-border-primary-100':
                !invalid && !disabled,
              'tw-bg-primary-10 tw-border-systemRed-100': invalid,
              'tw-text-secondary-80 tw-opacity-50 tw-bg-surface-10 tw-border-surface-40 tw-cursor-default':
                disabled,
            },
            !unstyled && 'tw-border-2 tw-border-dashed',
            className,
          ),
          ref: rootRef,
        })}
        style={style}
      >
        <input
          {...getInputProps()}
          required={required}
          className={inputClassName}
          name={name}
          id={id ?? name}
          disabled={disabled}
        />
        {typeof children === 'function'
          ? children({ acceptedFiles })
          : children}
      </div>

      {!error && !formErrors ? null : (
        <InputFeedback className='tw-mt-1'>
          {getInputErrorMessage(error || formErrors)}
        </InputFeedback>
      )}
    </div>
  )
}

export function FileList({ files, className, itemClassName }) {
  return (
    <div className={className}>
      {files?.map((file, i) => (
        <div
          key={i}
          className={cx('tw-flex tw-items-center tw-gap-2', itemClassName)}
        >
          <Paperclip size={16} weight='bold' />
          <span>{file.name}</span>
        </div>
      ))}
    </div>
  )
}
