import React, { forwardRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'

import { useForm } from 'react-hook-form'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import { contractTypes } from '../../../../helpers/enum'
import { useFetch } from '../../../../helpers/hooks'
import { getTemplateList } from '../../../../services/api'
import LabelContent from '../../CreateContract/components/label-content'
import ContractSelect, {
  CONTRACT_TYPE_KEY,
  CONTRACT_TYPE_MAP,
} from '../../CreateContract/ContractSelect'
import { ContractorLabel } from '../../CreateContract/create-contract-v3'

const ComplianceForm = forwardRef(function ComplianceForm(
  {
    onSubmit,
    data,
    isAdmin,
    amendData,
    uploaded,
    onUploadStatusChanged,
    type,
    isAmend,
  },
  ref,
) {
  const staticData = useSelector((state) => state.Layout.staticData)
  function getContractMedia() {
    if (amendData?.template) {
      return CONTRACT_TYPE_KEY.YOUR_TEMPLATE
    }
    if (amendData?.rw_file) {
      return CONTRACT_TYPE_KEY.RP_TEMPLATE
    }
    if (amendData?.file) {
      return CONTRACT_TYPE_KEY.UPLOADED
    }
    return CONTRACT_TYPE_KEY.RP_TEMPLATE
  }

  const { data: templates } = useFetch({
    action: getTemplateList,
    autoFetch: !isAdmin,
  })

  const [notice, setNotice] = useState(
    amendData?.notice_period || data?.notice_period || 15,
  )
  const [file, setFile] = useState(null)
  const [contractMedia, setContractMedia] = useState(
    data?.template_type || amendData?.template_type
      ? CONTRACT_TYPE_MAP[data?.template_type || amendData?.template_type]
      : getContractMedia(),
  )
  const [template, setTemplate] = useState(null)
  // const [taxResidence, setTaxResidence] = useState(
  //   amendData?.taxResidence ||
  //     (data?.tax_residence
  //       ? { label: data?.tax_residence.name, value: data?.tax_residence.id }
  //       : null),
  // )

  const [label, setLabel] = useState({ value: '1', label: 'Contractor' })

  const dataTemplate = templates?.find((e) => e?.id === data?.template_id)

  useEffect(() => {
    setTemplate(
      dataTemplate
        ? { value: dataTemplate?.id, label: dataTemplate?.name }
        : amendData?.template,
    )
  }, [amendData?.template, dataTemplate])

  useEffect(() => {
    return () => {
      setFile(null)
      setTemplate(null)
    }
  }, [contractMedia])

  useEffect(() => {
    if (staticData) {
      const initLabel = staticData?.contractor_types?.find(
        (e) =>
          e.name === amendData?.contractor_name ||
          e.name === data?.contractor_name,
      )
      if (initLabel) {
        setLabel({ value: initLabel?.id, label: initLabel?.name })
      }
    }
  }, [amendData?.contractor_name, data?.contractor_name, staticData])

  const { handleSubmit } = useForm()

  function handleOnSubmit(values) {
    onSubmit(
      { ...values, contractor_name: label?.label },
      file,
      notice,
      contractMedia,
      contractMedia ? template : null,
    )
  }

  return (
    <form ref={ref} onSubmit={handleSubmit(handleOnSubmit)}>
      {!isAmend && (
        <ContractSelect
          onContractChanged={(key) => {
            setContractMedia(key)
            if (key === CONTRACT_TYPE_KEY.RP_TEMPLATE) {
              setFile(null)
            }
          }}
          value={contractMedia}
          onFileUploaded={setFile}
          templates={templates}
          template={template}
          uploaded={uploaded}
          onTemplateSelected={setTemplate}
          onUploadStatusChanged={onUploadStatusChanged}
        />
      )}

      <div className='tw-mt-4 tw-grid tw-gap-4 md:tw-grid-cols-2'>
        {type !== contractTypes.FULL_TIME && (
          <div>
            <label>
              <LabelContent required>
                Notice period (days) to terminate the contract
              </LabelContent>
            </label>
            <InputGroup>
              <Input
                // name="notice_period"
                type='text'
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault()
                  }
                }}
                id='noticeDays'
                className='form-control'
                value={notice}
                onChange={(e) => {
                  setNotice(e.target.value)
                }}
              />
              <InputGroupAddon addonType='append'>
                <InputGroupText className='bg-transparent text-muted'>
                  Days
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </div>
        )}
        {type !== contractTypes.FULL_TIME && !isAmend && (
          <CustomSelect
            label={<ContractorLabel />}
            onChange={setLabel}
            value={label}
            options={staticData?.contractor_types?.map((e) => ({
              value: e.id,
              label: e.name,
            }))}
          />
        )}
      </div>
    </form>
  )
})

export default ComplianceForm
