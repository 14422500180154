import React from 'react'
import CustomDateRange from '../../components/Forms/custom-date-range'
import CustomSelect from '../../components/Forms/CustomSelect/CustomSelect'
import { useFetch } from '../../helpers/hooks'
import { getContractTimeOff } from '../../services/api-time-off-policies'
import { getOptionFromList } from '../../utils/map-to-option'

const statusOptions = [
  { label: 'Pending approval', value: 2 },
  { label: 'Approved', value: 3 },
  { label: 'Declined', value: 4 },
  { label: 'Cancelled', value: 6 },
]

export function TimeOffFilters({ contract, filters, handleFiltersChange }) {
  const contractId = contract?.id
  const { data: policies, isLoading: gettingPolicies } = useFetch(
    {
      action: getContractTimeOff,
      body: { contract_id: contractId },
      autoFetch: contractId !== undefined,
    },
    [contractId],
  )

  const policyTypeOptions =
    policies?.map((policy) => ({
      label: policy?.type?.name,
      value: policy?.type?.id,
    })) ?? []

  const policyOptions =
    policies?.map((policy) => ({
      label: policy?.policy?.name,
      value: policy?.policy?.id,
    })) ?? []

  return (
    <div className='tw-flex tw-flex-wrap tw-items-center tw-gap-3 tw-pb-4 md:tw-px-6 [&>*]:tw-min-w-full md:[&>*]:tw-min-w-56'>
      <CustomDateRange
        wrapperStyles={{ minWidth: 220 }}
        name='dateRange'
        value={[filters?.start_date, filters?.end_date]}
        placeholder='Select date range'
        clearable
        handleClear={() => {
          handleFiltersChange('start_date', null, { action: 'clear' })
          handleFiltersChange('end_date', null, { action: 'clear' })
        }}
        onChange={(newDates) => {
          const startDate = newDates?.[0]
          const endDate = newDates?.[1]
          handleFiltersChange('start_date', startDate, {
            action: 'clear',
          })
          handleFiltersChange('end_date', endDate, { action: 'clear' })
        }}
      />

      <CustomSelect
        name='status_id'
        placeholder='Status'
        options={statusOptions}
        value={getOptionFromList(statusOptions, filters?.status_id)}
        onChange={(newStatus) => {
          handleFiltersChange('status_id', newStatus?.value, {
            action: 'clear',
          })
        }}
        isClearable
      />

      <CustomSelect
        name='time_off_type_id'
        placeholder='Policy Type'
        options={policyTypeOptions}
        isDisabled={gettingPolicies}
        isLoading={gettingPolicies}
        value={getOptionFromList(policyTypeOptions, filters?.time_off_type_id)}
        onChange={(newPolicyType) => {
          handleFiltersChange('time_off_type_id', newPolicyType?.value, {
            action: 'clear',
          })
        }}
        isClearable
      />

      <CustomSelect
        name='time_off_policy_id'
        placeholder='Policy'
        options={policyOptions}
        isDisabled={gettingPolicies}
        isLoading={gettingPolicies}
        value={getOptionFromList(policyOptions, filters?.time_off_policy_id)}
        onChange={(newPolicy) => {
          handleFiltersChange('time_off_policy_id', newPolicy?.value, {
            action: 'clear',
          })
        }}
        isClearable
      />
    </div>
  )
}
