import { yupResolver } from '@hookform/resolvers/yup'
import {
  CalendarCheck,
  CalendarX,
  Check,
  Eye,
  HourglassHigh,
  X,
} from '@phosphor-icons/react'
import { format } from 'date-fns'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Modal, ModalBody, ModalFooter, ModalHeader, NavItem } from 'reactstrap'
import * as yup from 'yup'
import { useLocation, useHistory } from 'react-router-dom'

import { ModalCloseButton } from '../../components/Common/modal-close-button'
import BadgeX from '../../components/Table/BadgeX'
import ControlledCheckbox from '../../components/controlled-checkbox'
import { PermissionTooltip } from '../../components/permission-tooltip'
import Button from '../../components/ui/button'
import DataTable, { getData } from '../../components/ui/data-table'
import Loader from '../../components/ui/loader'
import Pagination from '../../components/ui/pagination'
import FEATURE_FLAGS from '../../config/feature-flags'
import { BE_CONTRACT_CATEGORY, userTypes } from '../../helpers/enum'
import { useFetch, usePermissions } from '../../helpers/hooks'
import permissions from '../../helpers/permissions'
import { getCycles } from '../../services/api'
import { getCurrencyFormatter } from '../../utils/formatters/currency'
import {
  isValidDate,
  rpFormatDate,
} from '../../utils/formatters/date-picker-date-format'
import { formatDays } from '../../utils/formatters/format-days'
import ContractRef from '../AdminPanel/components/ContractRef'
import {
  SettingSectionHeading,
  SettingSectionSubHeading,
} from '../CompanySetting/components/settings-section-heading'
import { PERMISSION_GROUP } from '../CompanySetting/manage-role'
import CardsList from '../Contract/components/tab/card-list'
import TabEmpty from '../Contract/components/tab/tab-empty'
import { DetailsInfoList } from '../review-center/review-layout-details-components'
import { CyclesFields } from './add-time-off'
import { getTimeOffStatusColor } from './helpers'
import { TimeOffItemDetailsMenu } from './time-off-details-menu'
import { TimeOffFilters } from './time-off-filters'
import { useTimeOffActions } from './use-time-off-actions'
import { PageNav } from '../../components/page-nav'
import { TIMEOFF_EVENTS } from '../new-time-off-policy/events'
import { track } from '../../utils/analytics'

export function formatDate(date) {
  return isValidDate(date) ? format(new Date(date), 'dd/MM/yyyy') : date
}
export function formatDateV2(date) {
  return rpFormatDate(date, 'yyyy-MM-dd', 'dd/MM/yyyy')
}

export function formatTimeOffDate({ date, isHalf }) {
  return `${formatDateV2(date)}${isHalf ? ' (Half Day)' : ''}`
}

export function getDeductionValue({ currencyCode, total }) {
  const formatter = getCurrencyFormatter(currencyCode)

  const isPositive = !Number.isNaN(total) && Number(total) > 0

  return isPositive ? formatter.format(total) : 'Non-deductible'
}

export const timeOffFiltersKeys = [
  'month',
  'status_id',
  'time_off_type_id',
  'is_archived',
  'start_date',
  'end_date',
]

const getRoute = (params, location, timeOffType) => {
  params.set('timeOffType', timeOffType)
  return `${location.pathname}?${params.toString()}`
}

export default function TimeOffList({
  timeOffsList,
  paginator,
  filters,
  handleFiltersChange,
  update,
  contract,
  gettingTimeOffList,
  paidDays,
  unPaidDays,
  isFiltering,
}) {
  const location = useLocation()
  const history = useHistory()
  const params = new URLSearchParams(location.search)
  const timeOffType = params.get('timeOffType') ?? 'active'

  const userProfile = useSelector((state) => state?.userProfile?.userProfile)
  const contractorType = userProfile?.contractor_type
  const isDeEmployee = contractorType === BE_CONTRACT_CATEGORY.DIRECT_EMPLOYEE
  const isFteEmployee = contractorType === BE_CONTRACT_CATEGORY.EMPLOYEE
  const isEmployee = isDeEmployee || isFteEmployee

  const tabsData = [
    { label: 'Active Policies', key: 'active' },
    { label: 'Archive', key: 'archive' },
  ]

  const columns = useMemo(() => {
    const contractId = contract?.id

    return [
      isEmployee || contractId
        ? null
        : {
            Header: 'Contract ID',
            accessor: 'contract_ref',
            Cell: ({ cellData }) => {
              return (
                <ContractRef
                  contractId={cellData}
                  isAdmin={false}
                  target={null}
                />
              )
            },
          },
      {
        Header: 'Policy',
        Cell: ({ rowData }) => rowData.policy?.name || rowData.type?.name,
      },
      {
        Header: 'Start Date',
        accessor: 'from',
        Cell: ({ cellData, rowData }) => {
          return formatTimeOffDate({
            date: cellData,
            isHalf: rowData?.is_half_start_date,
          })
        },
      },
      {
        Header: 'End Date',
        accessor: 'to',
        Cell: ({ cellData, rowData }) => {
          return formatTimeOffDate({
            date: cellData,
            isHalf: rowData?.is_half_end_date,
          })
        },
      },
      {
        Header: 'Duration',
        accessor: 'days',
        Cell: ({ cellData }) => {
          return formatDays(cellData)
        },
      },
      {
        Header: 'Deduction',
        accessor: 'applied',
        Cell: ({ rowData }) => {
          return getDeductionValue({
            currencyCode: rowData?.currency?.code,
            total: rowData?.total,
          })
        },
      },
      {
        Header: 'Status',
        accessor: 'status.name',
        Cell: ({ cellData }) => (
          <BadgeX
            className='px-2 py-1.5'
            status={getTimeOffStatusColor(cellData)}
          >
            {cellData}
          </BadgeX>
        ),
      },
      {
        Header: '',
        accessor: 'id',
        Cell: (props) => (
          <ActionCell {...props} update={update} contract={contract} />
        ),
      },
    ].filter(Boolean)
  }, [contract, isEmployee, update])

  useEffect(() => {
    if (isClient) {
      handleFiltersChange(
        'is_archived',
        timeOffType === 'archive' ? '1' : '0',
        { action: 'clear' },
      )
    }
  }, [timeOffType])

  const isListEmpty = !timeOffsList || timeOffsList?.length <= 0

  const timeOffFilters = Object.entries(filters).filter(([key]) => {
    return timeOffFiltersKeys.includes(key)
  })
  const hasFilters =
    timeOffFilters.length > 0 &&
    timeOffFilters.filter(([, value]) => !!value).length > 0

  const user = useSelector((state) => state.Account?.user)
  const isClient = user?.type === userTypes.COMPANY

  if (isListEmpty && !hasFilters && !gettingTimeOffList) {
    return (
      <TabEmpty
        icon={
          <HourglassHigh size={250} color='var(--primary)' weight='duotone' />
        }
        title='No time off found with the selected filters'
        subtitle='Try changing the filters or selecting a different date range'
      />
    )
  }

  return (
    <>
      <>
        <div className='tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-3 tw-pb-4 md:tw-flex-nowrap md:tw-px-6'>
          <div className='tw-flex tw-w-full tw-flex-wrap tw-items-center tw-justify-between tw-gap-2'>
            <span>
              <SettingSectionHeading>History</SettingSectionHeading>
              <SettingSectionSubHeading className='tw-mb-0 tw-flex tw-justify-between'>
                {isClient
                  ? 'Browse this worker’s time off history'
                  : 'Browse your time off history'}
              </SettingSectionSubHeading>
            </span>

            {(!gettingTimeOffList || isFiltering) && (
              <span className='tw-flex tw-flex-wrap tw-gap-2 tw-text-xs'>
                <span className='tw-flex tw-items-center tw-gap-2 tw-rounded tw-border tw-border-surface-30 tw-px-4 tw-py-2'>
                  <CalendarCheck size={20} />
                  Paid days
                  <span className='tw-font-semibold'>
                    {formatDays(String(paidDays))}
                  </span>
                </span>

                <span className='tw-flex tw-items-center tw-gap-2 tw-rounded tw-border tw-border-surface-30 tw-px-4 tw-py-2'>
                  <CalendarX size={20} />
                  Unpaid days
                  <span className='tw-font-semibold'>
                    {formatDays(String(unPaidDays))}
                  </span>
                </span>
              </span>
            )}
          </div>
        </div>

        {isClient && (
          <PageNav className='!tw-my-6 tw-w-fit'>
            {tabsData.map(({ key, label }) => {
              return (
                <NavItem key={key}>
                  <PageNav.Link
                    isActive={timeOffType === key}
                    tag='button'
                    onClick={() =>
                      history.replace(getRoute(params, location, key))
                    }
                  >
                    {label}
                  </PageNav.Link>
                </NavItem>
              )
            })}
          </PageNav>
        )}

        <TimeOffFilters
          filters={filters}
          handleFiltersChange={handleFiltersChange}
          contract={contract}
        />
      </>

      {gettingTimeOffList ? (
        <Loader minHeight='max(50vh, 550px)' />
      ) : isListEmpty ? (
        <TabEmpty
          icon={
            <HourglassHigh size={250} color='var(--primary)' weight='duotone' />
          }
          title='No time off found with the selected filters'
          subtitle='Try changing the filters or selecting a different date range'
        />
      ) : (
        <>
          <CardsList
            columns={columns.filter((col) => col.Header !== 'Status')}
            data={timeOffsList}
            className='d-md-none'
            itemHeader={(row, index) => {
              const statusColumn =
                columns.find((col) => col.Header === 'Status') ?? {}
              const statusData = getData(row, statusColumn?.accessor)

              const statusChild = statusColumn.Cell ? (
                <statusColumn.Cell
                  cellData={statusData}
                  index={index}
                  rowData={row}
                  accessor={statusColumn?.accessor}
                />
              ) : (
                statusData
              )

              return statusChild
            }}
          />

          <DataTable
            columns={columns}
            data={timeOffsList}
            className='d-none d-md-table font-size-14'
            responsive
            striped
            headClassName='first:tw-pl-6'
            bodyCellClassName='first:tw-pl-6'
          />

          {!paginator ? null : (
            <Pagination
              innerClass='pagination mb-0 mt-3 mx-3 justify-content-end'
              itemsCountPerPage={paginator?.per_page}
              totalItemsCount={paginator?.total ?? 10}
              activePage={filters?.page}
              onChange={(v) => handleFiltersChange('page', v)}
            />
          )}
        </>
      )}
    </>
  )
}

export const pendingApprovalId = 2
function ActionCell({ rowData, update, contract }) {
  const userProfile = useSelector((state) => state.userProfile?.userProfile)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  function toggleMenu() {
    setIsMenuOpen((open) => !open)
  }

  const contractId = contract?.id

  const {
    handleApproveTimeOff,
    handleDeclineTimeOff,
    handleDeleteTimeOff,
    handleCancelTimeOff,
    actionIds,
  } = useTimeOffActions({
    refreshTimeOff: () => {
      update?.()
    },
  })

  const isLoading = Object.values(actionIds).includes(rowData?.id)

  const { hasAccess } = usePermissions()
  const canManageTimeOff =
    hasAccess([
      permissions.addTimeOff,
      permissions.deleteTimeOff,
      permissions.rejectTimeOff,
      permissions.approveTimeOff,
    ]) ||
    contract?.can_approve ||
    contract?.can_approve_timeoff

  return (
    <div className='tw-flex tw-items-center tw-gap-2'>
      <Button
        size='sm'
        color='link'
        onClick={toggleMenu}
        className='!tw-px-1 !tw-text-primary-100'
        icon={<Eye size={16} />}
      >
        Details
      </Button>

      {!isMenuOpen ? null : (
        <TimeOffItemDetailsMenu
          isOpen={isMenuOpen}
          toggle={() => setIsMenuOpen((open) => !open)}
          item={rowData}
          isLoading={isLoading}
          onSubmit={(data) => {
            handleApproveTimeOff({ ...rowData, cycles: data?.cycles })
            track(TIMEOFF_EVENTS.APPROVE, {
              deducted: !!rowData.cycles?.length,
              source: 'webapp',
              approver_country: userProfile?.country?.name,
              requester_contract_type: contract?.type,
            })
          }}
          onDecline={() => handleDeclineTimeOff(rowData)}
          onDelete={() => handleDeleteTimeOff(rowData)}
          onCancel={() => handleCancelTimeOff(rowData)}
          contract={{
            id: contractId,
            currency: rowData?.currency,
            can_approve: contract?.can_approve,
            can_approve_time_off: contract?.can_approve_timeoff,
          }}
          canManageTimeOff={canManageTimeOff}
        />
      )}
    </div>
  )
}

const cyclesSchema = ({ isEmptyCycles = false }) =>
  yup.object().shape({
    cycles: yup.array().when(['deduct'], {
      is: (deduct) => deduct && !isEmptyCycles,
      then: (schema) =>
        schema
          .of(
            yup
              .number()
              .typeError('Please enter a valid amount')
              .required('Please enter a valid amount')
              .min(1, 'Please enter a positive amount'),
          )
          .required('Please enter a valid amount'),
    }),
  })

export function ReviewTimeOffItem({
  contract,
  item,
  onSubmit,
  showDeduction = false,
  formId,
}) {
  const isoToBeFormat = format(new Date(item?.created_at ?? null), 'yyyy-MM-dd')

  const timeOffInfo = [
    item?.policy_name && {
      label: 'Policy',
      value: item?.policy_name,
    },
    item?.created_at && {
      label: 'Requested on',
      value: formatDateV2(isoToBeFormat),
    },
    {
      label: 'From',
      value:
        (item?.is_half_start_date ? 'Half of ' : '') + formatDateV2(item?.from),
    },
    {
      label: 'To',
      value:
        (item?.is_half_end_date ? 'Half of ' : '') + formatDateV2(item?.to),
    },
    { label: 'Total days', value: formatDays(item.days) },
    item.balance && {
      label: 'Balance',
      value: formatDays(item.balance),
      tip: 'Time off balance after the request is approved.',
    },
  ].filter(Boolean)

  return (
    <>
      <DetailsInfoList items={timeOffInfo} className='tw-p-0' />

      {!showDeduction ? null : (
        <DeductTimeOffForm
          item={item}
          contract={contract}
          onSubmit={onSubmit}
          formId={formId}
          className='tw-mb-3 tw-mt-6 tw-flex tw-flex-col tw-gap-4 tw-rounded tw-border tw-border-surface-30 tw-p-4'
        />
      )}
    </>
  )
}

export function DeductTimeOffForm({
  item,
  className,
  contract,
  onSubmit,
  formId,
}) {
  const currencySymbol = contract?.currency?.symbol

  const { data: cyclesData, isLoading: gettingCyclesData } = useFetch(
    {
      action: getCycles,
      initResult: [],
      autoFetch: true,
      body: {
        contract_id: contract?.id,
        from: item?.from,
        to: item?.to,
        is_half_start_date: item?.is_half_start_date,
        is_half_end_date: item?.is_half_end_date,
        policy_id: item?.policy?.id,
      },
    },
    [
      contract?.id,
      item?.from,
      item?.to,
      item?.is_half_start_date,
      item?.is_half_end_date,
    ],
  )

  const isEmpty = !cyclesData?.cycles || cyclesData?.cycles?.length <= 0

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(cyclesSchema({ isEmptyCycles: isEmpty })),
    defaultValues: { deduct: false },
  })
  const isDeduct = useWatch({ control, name: 'deduct' })

  function onSubmitApprove(data) {
    const formatted = {
      cycles: data?.cycles?.map((cycle, index) => {
        return {
          amount: cycle,
          id: cyclesData?.cycles[index]?.id,
        }
      }),
    }

    onSubmit(data?.deduct === false ? {} : formatted)
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmitApprove)}
      className={className}
      id={formId}
    >
      <ControlledCheckbox
        control={control}
        name='deduct'
        id='deduct'
        className='tw-cursor-pointer'
        leftLabel={
          <>
            <div className='tw-font-bold'>Deduct time off amount</div>
            <div className='tw-text-wrap tw-text-text-60'>
              The deducted time off amount will be deducted from the relative
              cycle
            </div>
          </>
        }
      />

      {!isDeduct ? null : (
        <CyclesFields
          control={control}
          setValue={setValue}
          name='cycles'
          errorsArray={errors?.cycles}
          cycles={cyclesData?.cycles}
          gettingCyclesData={gettingCyclesData}
          contract={contract}
          currencySymbol={currencySymbol}
        />
      )}
    </form>
  )
}
