import showdown from 'showdown'
import TurndownService from 'turndown'

const turndownService = new TurndownService()

turndownService.addRule('strikethrough', {
  filter: ['del', 's', 'strike'],
  replacement: function (content) {
    return '~~' + content + '~~'
  },
})

turndownService.addRule('underline', {
  filter: ['u'],
  replacement: function (content) {
    return '__' + content + '__'
  },
})

turndownService.addRule('empty-paragraph', {
  filter: 'br',
  replacement: () => {
    return '\n\n'
  },
})

const converter = new showdown.Converter()

export function makeMarkDown(body) {
  return turndownService.turndown(body).replaceAll(/\\_/g, '_')
}

export function makeHtml(body) {
  return converter.makeHtml(
    body
      .replaceAll(/\n\n /g, '<br/>')
      .replaceAll(/__([^_]+)__/g, '<u>$1</u>')
      .replaceAll(/~~([^~]+)~~/g, '<s>$1</s>'),
  )
}
