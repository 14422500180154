import React, { Fragment } from 'react'
import { EnvelopeSimple, PlusCircle } from '@phosphor-icons/react'
import slackLogo from '../../../../assets/images/brands/slack.svg'
import { cn } from 'ui'
import { useHistory } from 'react-router-dom'
import Button from '../../../../components/ui/button'
import { useFetch } from '../../../../helpers/hooks'
import { duplicateAutomation } from '../../../../services/api-automations'
import toastr from 'toastr'
import { ACTION_TYPE } from './tools/constants'
import { AutomationMetrics } from './tools/automation-metrics'

export function AutomationsCard(props) {
  const {
    title,
    id,
    description,
    steps,
    buttonText = 'Set Up',
    custom = false,
    channels,
    containerClassName,
  } = props
  const history = useHistory()
  const { isLoading, startFetch } = useFetch({
    action: duplicateAutomation,
    isOpenApi: true,
    body: { templateId: id },
    onComplete: (data) => {
      history.push(`/automations/add?id=${data?._id}`)
      AutomationMetrics.logDuplicated({
        Source: 'Edition',
        Type: 'template',
        Trigger: steps.find((s) => s.type === 'TRIGGER'),
        Steps: steps.find((s) => s.type !== 'TRIGGER'),
      })
    },
    onError: (err) => toastr.error(err),
  })
  return (
    <div
      className={cn(
        'tw-flex tw-flex-col tw-justify-end tw-rounded tw-border tw-border-surface-30 tw-bg-white tw-px-3 tw-py-2',
        containerClassName,
      )}
    >
      <div className='tw-flex tw-flex-row'>
        <div className='tw-flex-fill tw-flex tw-flex-row tw-gap-x-2'>
          {custom ? (
            <div className='tw-bg-secondary-20 tw-p-2'>
              <PlusCircle fill='black' size={24} />
            </div>
          ) : null}
          {channels?.map((c) => (
            <Fragment key={c}>
              {c === ACTION_TYPE.EMAIL ? (
                <div className='tw-bg-secondary-20 tw-p-2'>
                  <EnvelopeSimple fill='black' size={24} />
                </div>
              ) : c === ACTION_TYPE.SLACK ? (
                <div className='tw-bg-secondary-20 tw-p-2'>
                  <img src={slackLogo} style={{ width: 20, height: 20 }} />
                </div>
              ) : null}
            </Fragment>
          ))}
        </div>
      </div>
      <div className='tw-my-6 tw-flex tw-flex-1 tw-flex-col'>
        <span className='tw-font-bold'>{title}</span>
        <span className='tw-mr-10'>{description}</span>
      </div>
      <Button
        className='tw-mb-2 !tw-bg-white'
        loading={isLoading}
        onClick={() =>
          !custom ? startFetch() : history.push(`/automations/add`)
        }
        text={buttonText}
        textClassName='tw-text-black'
        color='surface-30'
        outline
      />
    </div>
  )
}
