import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { WarningOctagon } from '@phosphor-icons/react'
import Button from '../../../../../components/ui/button'
import { useIncompatibleTrigger } from '../hooks/use-incompatible-trigger'

export function IncompatibleSetupModal({
  onUpdatePress,
  onCancelPress,
  trigger,
}) {
  const toggle = () => setIncompatibleModal((prevState) => !prevState)
  const [incompatibleModal, setIncompatibleModal] = useState(false)
  const incompatibleTrigger = useIncompatibleTrigger({
    trigger,
  })

  useEffect(() => {
    if (
      incompatibleTrigger.isStepsIncompatible ||
      incompatibleTrigger.isFiltersIncompatible
    ) {
      setIncompatibleModal(true)
    }
  }, [incompatibleTrigger])
  return (
    <Modal centered isOpen={incompatibleModal} toggle={toggle}>
      <ModalHeader
        toggle={() => {
          toggle()
          onCancelPress()
        }}
      >
        <WarningOctagon size={25} className='tw-text-systemRed-100' />
      </ModalHeader>
      <ModalBody className='tw-flex tw-flex-col'>
        <span className='tw-text-base tw-font-bold'>
          Some filters are no longer compatible
        </span>
        <span className='tw-text-s tw-text-secondary'>
          Compatible filters will remain applied, but incompatible ones will be
          removed.
        </span>
      </ModalBody>
      <ModalFooter>
        <Button
          className='tw-mb-2 !tw-border-surface-30 !tw-bg-white'
          textClassName='!tw-text-black'
          onClick={() => {
            onCancelPress()
            toggle()
          }}
        >
          Cancel
        </Button>
        <Button
          color='primary'
          onClick={() => {
            onUpdatePress(incompatibleTrigger)
            toggle()
          }}
        >
          Update Filters
        </Button>
      </ModalFooter>
    </Modal>
  )
}
