import {
  Info,
  ListDashes,
  MagicWand,
  PlusCircle,
  TrashSimple,
  WarningOctagon,
} from '@phosphor-icons/react'
import React, { cloneElement } from 'react'
import { useFieldArray, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { cn } from 'ui'
import { useBillData } from '.'
import ControlledCurrencyInput from '../../../components/ControlledCurrencyInput'
import ControlledDatePicker from '../../../components/ControlledDatePicker'
import ControlledInput from '../../../components/ControlledInput'
import ControlledSelect from '../../../components/ControlledSelect'
import Alert from '../../../components/ui/alert'
import { Box } from '../../../components/ui/box'
import Button from '../../../components/ui/button'
import Shimmer from '../../../components/ui/shimmer'
import { useHideIntercomForPage, usePermissions } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'
import { mapCurrencyToOption } from '../../../utils/map-to-option'
import {
  FormSectionHr,
  FormSectionTitle,
} from '../../Contract/CreateContract/components/form-section-title'
import { CalculationItem } from '../../new-external-invoice'
import { ControlledVendorSelect } from './controlled-vendor-select'
import { track } from '../../../utils/analytics'

export default function CreateBillForm({
  control,
  setValue,
  disabled,
  nextDisabled,
  nextLoading,
  isScanned = () => false,
  foundItemsCount,
  showFoundItems = false,
  onExtractItems,
}) {
  const { currencies = [] } = useSelector(
    (state) => state?.Layout?.staticData ?? {},
  )
  useHideIntercomForPage()

  const { categoriesList, loadingData, hasIntegration } = useBillData()
  const { id: userID } = useSelector((state) => state.Account?.user)
  const {
    currency_id: currencyId,
    total_amount: totalAmount,
    items,
    issue_date: issueDate,
    due_date: dueDate,
    vendor_id: vendorId,
    category_id: categoryId,
  } = useWatch({ control })

  const { hasAccess } = usePermissions()
  const canManageVendors = hasAccess(permissions.ManageVendors)

  const selectedVendor = vendorId?.raw
  const selectedVendorCategoryId = selectedVendor?.category?.id
  const selectedCurrency = currencies.find((c) => c.id === currencyId)
  const formatter = getCurrencyFormatter(selectedCurrency?.code)
  const totalFormatted = formatter.format(totalAmount)

  const totalCalculated = items?.reduce(
    (acc, item) => acc + Number(item.amount),
    0,
  )
  const totalCalculatedFormatted = formatter.format(totalCalculated)
  const difference = totalAmount > 0 ? totalAmount - totalCalculated : 0
  const differenceFormatted = formatter.format(Math.abs(difference))
  const totalsMakeSense = difference === 0 || !items || items?.length <= 0

  const { fields, append, remove } = useFieldArray({ control, name: 'items' })

  const trackAddInvoice = () => {
    const logData = {
      userID,
      'Vendor Id': vendorId?.value,
      'Vendor Name': vendorId?.label,
      Category: categoriesList?.find((c) => c.value === categoryId)?.label,
      category_id: categoryId,
      isNewVendor: vendorId?.__isNew__ ? 1 : 0,
      'Issue Date': issueDate,
      'Due Date': dueDate,
      'Total Amount': totalAmount,
      Currency: selectedCurrency?.name,
      items,
      isVendorBankDetailsExist: vendorId?.row?.bank_account?.id ? 1 : 0,
    }
    track('Clicked Continue Bill Creation', logData)
  }
  if (loadingData) {
    return (
      <div className='tw-space-y-4 tw-p-6'>
        <FormSectionTitle title='Details' Icon={Info} />
        <Shimmer width='100%' height='56px' />
        <Shimmer width='100%' height='56px' />
        <Shimmer width='100%' height='56px' />
        <Shimmer width='100%' height='56px' />
        <Shimmer width='100%' height='56px' />
        <Shimmer width='100%' height='56px' />
        <Shimmer width='100%' height='56px' />
      </div>
    )
  }

  return (
    <fieldset
      disabled={disabled}
      className='tw-grid tw-grid-rows-[1fr_auto] [&>*:nth-child(2)]:tw-overflow-auto [&>*:nth-child(2)]:tw-overscroll-contain'
    >
      <div className='tw-space-y-4 tw-p-6'>
        <FormSectionTitle title='Details' Icon={Info} />

        <ScannedNoticeWrapper
          isScanned={isScanned({ name: 'vendor_id' })}
          selectStyles={
            !isScanned({ name: 'vendor_id' })
              ? null
              : {
                  control: () => ({
                    borderColor: 'var(--cyan-110)',
                    backgroundColor: 'var(--cyan-10)',
                  }),
                }
          }
        >
          <ControlledVendorSelect
            control={control}
            name='vendor_id'
            inputId='vendor_id'
            label='Vendor'
            transform={{
              output: (value) => {
                const vendorCategoryId = value?.raw?.category?.id
                if (!vendorCategoryId) {
                  return value
                }

                setValue('category_id', vendorCategoryId)
                fields.forEach((field, index) => {
                  if (!field.category_id) {
                    setValue(`items.${index}.category_id`, vendorCategoryId)
                  }
                })

                return value
              },
            }}
            disabled={disabled}
            canManageVendors={canManageVendors}
          />
        </ScannedNoticeWrapper>

        <ControlledSelect
          control={control}
          name='category_id'
          inputId='category_id'
          label={!hasIntegration ? 'Category' : 'GL Account'}
          options={categoriesList}
          disabled={disabled}
          transform={{
            output: (value) => {
              const vendorCategoryId = value?.value
              if (!vendorCategoryId) {
                return value
              }

              fields.forEach((field, index) => {
                if (!field.category_id) {
                  setValue(`items.${index}.category_id`, vendorCategoryId)
                }
              })

              return value
            },
          }}
        />

        <ScannedNoticeWrapper
          isScanned={isScanned({ name: 'details' })}
          className={
            !isScanned({ name: 'details' })
              ? null
              : '!tw-border-cyan-110 !tw-bg-cyan-10'
          }
        >
          <ControlledInput
            control={control}
            name='details'
            id='details'
            label='Invoice number (optional)'
            placeholder='Invoice number (optional)'
          />
        </ScannedNoticeWrapper>

        <ScannedNoticeWrapper
          isScanned={isScanned({ name: 'issue_date' })}
          inputClassName={
            !isScanned({ name: 'issue_date' })
              ? null
              : '!tw-border-cyan-110 !tw-bg-cyan-10'
          }
        >
          <ControlledDatePicker
            control={control}
            name='issue_date'
            id='issue_date'
            label='Issue date'
            placeholder='Issue date'
            maxDate={new Date(dueDate)}
            disabled={disabled}
          />
        </ScannedNoticeWrapper>

        <ScannedNoticeWrapper
          isScanned={isScanned({ name: 'due_date' })}
          inputClassName={
            !isScanned({ name: 'due_date' })
              ? null
              : '!tw-border-cyan-110 !tw-bg-cyan-10'
          }
        >
          <ControlledDatePicker
            control={control}
            name='due_date'
            id='due_date'
            label='Due date'
            placeholder='Due date'
            minDate={new Date(issueDate)}
            disabled={disabled}
          />
        </ScannedNoticeWrapper>

        <ScannedNoticeWrapper
          isScanned={isScanned({ name: 'total_amount' })}
          className={
            !isScanned({ name: 'total_amount' })
              ? null
              : '!tw-border-cyan-110 !tw-bg-cyan-10'
          }
        >
          <ControlledCurrencyInput
            control={control}
            name='total_amount'
            id='total_amount'
            label='Total amount'
            placeholder='Total amount'
            prefix={`${selectedCurrency?.symbol ?? ''} `}
            allowNegativeValue={false}
            disabled={disabled}
          />
        </ScannedNoticeWrapper>

        <ScannedNoticeWrapper
          isScanned={isScanned({ name: 'currency_id' })}
          selectStyles={
            !isScanned({ name: 'currency_id' })
              ? null
              : {
                  control: () => ({
                    borderColor: 'var(--cyan-110)',
                    backgroundColor: 'var(--cyan-10)',
                  }),
                }
          }
        >
          <ControlledSelect
            control={control}
            name='currency_id'
            inputId='currency_id'
            label='Currency'
            options={currencies.map((c) => mapCurrencyToOption(c, 'id'))}
            disabled={disabled}
          />
        </ScannedNoticeWrapper>

        <FormSectionHr />

        <FormSectionTitle title='Items' Icon={ListDashes} />

        {!showFoundItems ? null : (
          <Alert
            innerTag='div'
            customIcon={
              <MagicWand size={20} className='tw-text-cyan-110' aria-hidden />
            }
            className='!tw-border-0 !tw-bg-cyan-20'
          >
            <h4 className='tw-mb-0 tw-text-xs tw-font-semibold tw-text-black'>
              {foundItemsCount} line item{foundItemsCount === 1 ? '' : 's'}{' '}
              found
            </h4>
            <p className='tw-mb-0 tw-text-xs tw-font-medium tw-text-text-80'>
              Our system detected the following item
              {foundItemsCount === 1 ? '' : 's'} from the invoice, adjust
              accordingly.
            </p>
            <Button
              color='link'
              type='button'
              className='!tw-px-0 !tw-text-cyan-110 hover:!tw-bg-cyan-20'
              size='sm'
              onClick={onExtractItems}
            >
              Extract items
            </Button>
          </Alert>
        )}

        {fields?.map((field, index) => {
          return (
            <section
              key={field.id}
              className='tw-space-y-4 tw-rounded tw-border tw-border-surface-30 tw-bg-surface-10 tw-p-4'
            >
              <div className='tw-flex tw-min-h-6 tw-items-center tw-justify-between'>
                <h6 className='tw-mb-0'>Item {index + 1}</h6>

                {items.length <= 1 ? null : (
                  <Button
                    icon={<TrashSimple size={16} />}
                    type='button'
                    onClick={() => remove(index)}
                    aria-label={`Remove item ${index + 1}`}
                    className='!tw-border-0 !tw-p-1'
                    color='danger'
                    outline
                  />
                )}
              </div>

              <ControlledInput
                control={control}
                name={`items.${index}.description`}
                id={`items.${index}.description`}
                label='Description'
                placeholder='Description'
              />

              <div className='tw-grid tw-gap-4 xl:tw-grid-cols-3'>
                <ControlledSelect
                  control={control}
                  name={`items.${index}.category_id`}
                  inputId={`items.${index}.category_id`}
                  label={!hasIntegration ? 'Category' : 'GL Account'}
                  placeholder={!hasIntegration ? 'Category' : 'GL Account'}
                  options={categoriesList}
                  wrapperClassName='xl:tw-col-span-2'
                  disabled={disabled}
                />

                <ControlledCurrencyInput
                  control={control}
                  name={`items.${index}.amount`}
                  id={`items.${index}.amount`}
                  label='Amount'
                  placeholder='Amount'
                  prefix={`${selectedCurrency?.symbol ?? ''} `}
                  allowNegativeValue={false}
                />
              </div>
            </section>
          )
        })}

        <Box
          borderDashed
          tag='button'
          type='button'
          className={cn(
            'tw-flex tw-w-full tw-items-center tw-gap-2 tw-border-primary-100 tw-bg-primary-10 tw-font-bold tw-text-primary-100 tw-transition-colors',
            {
              'hover:tw-bg-primary-30 focus-visible:tw-ring-2 focus-visible:tw-ring-primary-80 focus-visible:tw-ring-offset-2':
                !disabled,
            },
          )}
          onClick={() => {
            const newIem = { ...defaultBillInvoiceItem }
            if (selectedVendorCategoryId) {
              newIem.category_id = selectedVendorCategoryId
            }
            append(newIem)
          }}
        >
          <PlusCircle size={24} />
          <span>Add item</span>
        </Box>

        <div className='tw-mt-4 tw-space-y-2 tw-rounded tw-border-b tw-border-surface-30 tw-bg-surface-10 tw-p-6'>
          {[
            ...(!items || items?.length <= 0
              ? []
              : items.map((item, index) => {
                  return {
                    label: item.description || `Item ${index + 1}`,
                    value: formatter.format(item.amount),
                    valueClassName: 'tw-text-sm',
                  }
                })),
            {
              label: 'Total amount',
              value:
                !items || items?.length <= 0
                  ? totalFormatted
                  : totalCalculatedFormatted,
              labelClassName: 'tw-text-sm tw-font-bold tw-text-black',
              valueClassName: 'tw-text-xl tw-font-bold tw-text-black',
            },
          ].map((item) => (
            <CalculationItem key={item.label} {...item} />
          ))}
          {totalsMakeSense ? null : (
            <div className='!tw-mt-0 tw-flex tw-items-center tw-justify-end tw-gap-1 tw-text-xs tw-text-systemRed-100'>
              {difference > 0 ? (
                <span>{differenceFormatted} left to itemize</span>
              ) : (
                <span>
                  Total of line items exceeds the bill amount by{' '}
                  {differenceFormatted}
                </span>
              )}
              <WarningOctagon size={16} />
            </div>
          )}
        </div>
      </div>

      <div className='tw-sticky tw-bottom-0 tw-z-10 !tw-flex tw-border-t tw-border-t-surface-30 tw-bg-white tw-p-6'>
        <Button
          type='submit'
          className='tw-ml-auto'
          disabled={!totalsMakeSense || nextDisabled}
          loading={nextLoading}
          onClick={trackAddInvoice}
        >
          Continue
        </Button>
      </div>
    </fieldset>
  )
}

function ScannedNoticeWrapper({ children, className, isScanned, ...props }) {
  if (isScanned) {
    return (
      <div className='tw-relative'>
        {cloneElement(children, { className, ...props })}

        <MagicWand
          className='tw-absolute tw-bottom-[calc(42px/2)] tw-left-0 tw-size-7 -tw-translate-x-1/2 tw-translate-y-1/2 tw-rounded-full tw-bg-white tw-p-1 tw-text-base tw-text-cyan-110'
          aria-label='Scanned from the invoice'
        />
      </div>
    )
  }

  return cloneElement(children, { className, ...props })
}

export const defaultBillInvoiceItem = {
  description: '',
  amount: null,
  currency_id: null,
  category_id: null,
}
