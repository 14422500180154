import { yupResolver } from '@hookform/resolvers/yup'
import {
  CalendarBlank,
  CaretRight,
  CirclesThreePlus,
  Eye,
  HourglassHigh,
  LinkBreak,
  PlusCircle,
  PlusMinus,
  Spinner,
  Swap,
  TreePalm,
  UserCircleGear,
  Warning,
} from '@phosphor-icons/react'
import { addDays, format } from 'date-fns'
import React, { useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { InputGroup, InputGroupAddon, InputGroupText, Label } from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import { ActionsDropdown, cn } from 'ui'
import ConfirmationModal from '../../../components/Common/ConfirmationModal'
import { ModalCloseButton } from '../../../components/Common/modal-close-button'
import ControlledInput from '../../../components/ControlledInput'
import ControlledSelect from '../../../components/ControlledSelect'
import PolicyAccrualInfo from '../../../components/policy-accrual-info'
import PolicyRequestSettings from '../../../components/policy-request-settings'
import Alert from '../../../components/ui/alert'
import BadgeV2 from '../../../components/ui/badge-v2'
import Button from '../../../components/ui/button'
import Loader from '../../../components/ui/loader'
import Shimmer from '../../../components/ui/shimmer'
import { SideMenu } from '../../../components/ui/side-menu'
import { CONTRACT_TYPES } from '../../../core/config/contract-types'
import { userTypes } from '../../../helpers/enum'
import { useFetch } from '../../../helpers/hooks'
import {
  adjustTimeOffBalance,
  getBalanceHistory,
  unassignContractsToPolicy,
} from '../../../services/api-time-off-policies'
import { track } from '../../../utils/analytics'
import { formatDays } from '../../../utils/formatters/format-days'
import isNill from '../../../utils/is-nill'
import { policyTypeColor } from '../../new-time-off-policy'
import { TIMEOFF_EVENTS } from '../../new-time-off-policy/events'
import { DetailsInfoList } from '../../review-center/review-layout-details-components'
import { AddTimeOffWithData, TimeOffSummary } from '../../time-off/add-time-off'
import { useTimeOffFetch } from '../../time-off/helpers'
import {
  TIME_OFF_ACTIONS,
  useTimeOffPermissionCheck,
} from '../hooks/useTimeOffPermissionCheck'
import {
  TimeOffPolicyCarryOverTypes,
  TimeOffPolicyTypes,
} from '../utils/constants'
import {
  AssignPolicyAction,
  BalanceItem,
  getBalanceAccessories,
} from './assign-time-off-policy'
import { ChangePolicySideMenu } from './change-policy-side-menu'
import { TenureRulesList } from '../../CompanySetting/components/time-off-policies-tab/view-policy'

export const POLICY_ACTION_TYPE = {
  ADDITION: 'addition',
  DEDUCTION: 'deduction',
}
export function policyTypeCardColors({ typeId }) {
  const colors = {
    secondary: 'tw-border-l-secondary-100',
    systemGold: 'tw-border-l-systemGold-100',
    systemRed: 'tw-border-l-systemRed-100',
    green: 'tw-border-l-green-100',
    systemBlue: 'tw-border-l-systemBlue-100',
    cyan: 'tw-border-l-cyan-100',
    surface: 'tw-border-l-surface-100',
  }

  return colors[policyTypeColor({ typeId })]
}

export function TimeOffStats({
  contract,
  policies,
  isLoadingPolicies,
  refreshPolicies,
  className,
  companyPolicies,
  fetchingCompanyPolicies,
  updateTimeOff,
  updateContract,
}) {
  const user = useSelector((state) => state.Account?.user)

  const isClient = user?.type === userTypes.COMPANY

  const refresh = () => {
    refreshPolicies?.()
    updateTimeOff?.()
    updateContract?.()
  }

  return (
    <div
      className={cn(
        'tw-mb-8 tw-border-b tw-border-surface-30 tw-pb-8',
        className,
      )}
    >
      {isLoadingPolicies || !policies ? (
        <Loader className='tw-mt-6 tw-w-full' minHeight='98px' />
      ) : policies?.length > 0 ? (
        <div className='tw-mt-6 tw-grid tw-gap-4 tw-px-6 md:tw-grid-cols-4'>
          {policies?.map((item) => {
            return (
              <PolicyStatItem
                policy={item}
                contract={contract}
                isClient={isClient}
                key={`policy-${item?.id}`}
                onSuccess={refresh}
              />
            )
          })}
        </div>
      ) : (
        <div className='tw-min-h-[98px]'>
          <Alert
            className='tw-mx-6 tw-mt-6 !tw-bg-systemGold-20'
            innerClassName='tw-text-xs !tw-text-text-80'
            color='warning'
            customIcon={
              <Warning size={20} className='tw-text-systemGold-110' />
            }
          >
            This contract does not have a time off policy assigned.
            {!isClient ? null : ' Time off cannot be added for this worker.'}
          </Alert>

          {!isClient ? null : (
            <span className='tw-mx-6 tw-mt-6 tw-flex tw-flex-wrap tw-gap-4'>
              {fetchingCompanyPolicies ? (
                <Shimmer height={74} width='100%' className='md:!tw-w-[49%]' />
              ) : companyPolicies?.length <= 0 ? null : (
                <AssignPolicyAction contract={contract} onSuccess={refresh}>
                  {({ disabled, onClick, id }) => (
                    <button
                      type='button'
                      className='tw-flex tw-flex-1 tw-items-center tw-gap-x-4 tw-rounded tw-border tw-border-surface-30 tw-p-4'
                      onClick={onClick}
                      id={id}
                      disabled={disabled}
                    >
                      <span className='tw-flex tw-items-center tw-justify-center tw-bg-systemGreen-10 tw-p-2'>
                        <UserCircleGear
                          size={24}
                          className='tw-fill-systemGreen'
                        />
                      </span>
                      <span className='tw-flex tw-flex-1 tw-flex-col'>
                        <span className='tw-text-left tw-text-sm tw-font-bold'>
                          Assign policy
                        </span>
                        <span className='tw-text-left tw-text-xs tw-text-text-80'>
                          Select the policies you want to assign to this
                          contract
                        </span>
                      </span>
                      <CaretRight size={20} className='tw-fill-secondary' />
                    </button>
                  )}
                </AssignPolicyAction>
              )}

              <Link
                className={cn(
                  'tw-flex tw-flex-1 tw-items-center tw-gap-x-4 tw-rounded tw-border tw-border-surface-30 tw-p-4',
                  {
                    'md:tw-flex-[0.5]':
                      !companyPolicies?.length && !fetchingCompanyPolicies,
                  },
                )}
                to={{
                  pathname: '/time-off-policies/add',
                  state: { contractRef: contract.ref, fromTimeOffTab: true },
                }}
              >
                <span className='tw-flex tw-items-center tw-justify-center tw-bg-systemGreen-10 tw-p-2'>
                  <CirclesThreePlus size={24} className='tw-fill-primary' />
                </span>
                <span className='tw-flex tw-flex-1 tw-flex-col'>
                  <span className='tw-text-left tw-text-sm tw-font-bold'>
                    Create policy
                  </span>
                  <span className='tw-text-left tw-text-xs tw-text-text-80'>
                    Create a new policy and configure it according to your needs
                  </span>
                </span>
                <CaretRight size={20} className='tw-fill-secondary' />
              </Link>
            </span>
          )}
        </div>
      )}
    </div>
  )
}

function PolicyDetailsMenu({
  policy,
  toggle,
  isOpen,
  openAdjustPanel,
  contract,
  isClient,
  refresh,
}) {
  const isAccrued = policy?.type?.is_accrued === 1
  const [showRequest, setShowRequest] = useState(false)

  const { data: balanceHistory, isLoading } = useFetch({
    action: getBalanceHistory,
    autoFetch: policy?.id,
    body: { id: policy?.id },
  })

  const { canPerformAction } = useTimeOffPermissionCheck()
  const forAdjust =
    isClient && isAccrued && canPerformAction(TIME_OFF_ACTIONS.ADJUST_BALANCE)

  if (showRequest) {
    return (
      <TimeOffStatsAddTimeOff
        contract={contract}
        refresh={refresh}
        toggle={() => setShowRequest(false)}
        policy={policy}
        isClient={isClient}
      />
    )
  }

  return (
    <SideMenu
      onClose={toggle}
      isOpen={isOpen}
      className='tw-max-w-[480px] tw-text-black'
      itemListClassName={cn(
        'tw-grid [&>*:nth-child(2)]:tw-overflow-auto [&>*:nth-child(2)]:tw-overscroll-contain tw-grid-rows-[auto_1fr_auto]',
      )}
    >
      <SideMenu.Header toggle={toggle}>{policy?.policy?.name}</SideMenu.Header>

      <SideMenu.Body>
        <div className='tw-flex tw-rounded tw-border tw-border-surface-30 tw-p-4'>
          <span className='tw-mr-4 tw-flex tw-flex-1 tw-flex-col'>
            <HourglassHigh size={24} className='tw-mb-2 tw-fill-text-60' />
            <div className='tw-flex-1 tw-text-xl tw-font-semibold tw-capitalize tw-text-black'>
              {formatDays(
                isAccrued
                  ? policy?.days
                  : policy?.policy?.max_annual_days
                    ? policy?.policy?.max_annual_days -
                      Number(policy?.days_used)
                    : policy?.days_used,
              )}
            </div>
            <span className='tw-text-xs tw-text-text-80'>
              {isAccrued || policy?.policy?.max_annual_days
                ? 'Current balance'
                : 'Used days'}
            </span>
          </span>

          <span className='tw-flex tw-flex-1 tw-flex-col tw-items-end tw-justify-end'>
            {isAccrued ? (
              isLoading ? (
                <Spinner
                  size={20}
                  className='tw-animate-spin tw-transition-transform tw-duration-300'
                />
              ) : (
                !!balanceHistory?.length && (
                  <BadgeV2
                    className={
                      getBalanceAccessories(
                        balanceHistory[0].type,
                        parseFloat(balanceHistory[0].days),
                      ).className
                    }
                  >
                    <span className='tw-flex tw-items-center tw-justify-center tw-gap-[2px] tw-text-sm'>
                      {parseFloat(balanceHistory[0].days)}
                      {
                        getBalanceAccessories(
                          balanceHistory[0].type,
                          parseFloat(balanceHistory[0].days),
                        ).icon
                      }
                    </span>
                  </BadgeV2>
                )
              )
            ) : null}
            <span className='tw-mt-1 tw-text-xs tw-text-text-80'>
              Last update{' '}
              {format(new Date(policy?.policy.updated_at), 'dd/MM/yyyy')}
            </span>
          </span>
        </div>
        <hr className='-tw-mx-4 tw-my-6 md:-tw-mx-6' />

        <div className='tw-text-base tw-font-bold'>Details</div>
        <DetailsInfoList
          className='tw-p-0'
          items={[
            {
              icon: <TreePalm size={24} />,
              label: 'Type',
              value: policy.type?.name,
            },
            {
              icon: <CalendarBlank size={24} />,
              label: 'Time off amount',
              value: policy?.policy?.tenure_rules?.length
                ? null
                : policy?.policy?.accrual_days ||
                    policy?.policy?.max_annual_days
                  ? formatDays(
                      policy?.policy?.accrual_days ??
                        policy?.policy?.max_annual_days,
                    ) + ' / Year'
                  : 'Unlimited',
              extraDetails: policy?.tenure_rules?.length ? (
                <TenureRulesList policy={policy} />
              ) : null,
            },
          ].filter(Boolean)}
        />

        <hr className='-tw-mx-4 tw-mt-6 md:-tw-mx-6' />
        {isAccrued && (
          <PolicyAccrualInfo policy={policy?.policy} bodyClassName='tw-px-0' />
        )}
        <PolicyRequestSettings
          policy={policy?.policy}
          isOpenByDefault={
            policy?.type?.id !== TimeOffPolicyTypes.VACATION_POLICY_TYPE
          }
          bodyClassName='tw-px-0'
        />

        {!!balanceHistory?.length && isAccrued && (
          <>
            <hr className='-tw-mx-4 tw-my-6 md:-tw-mx-6' />
            <div className='tw-text-base tw-font-bold'>History</div>

            {balanceHistory?.map((history) => {
              const formattedCreatedAt = !history.credited_at
                ? null
                : format(new Date(history.credited_at * 1000), 'dd/MM/yyyy')

              return (
                <BalanceItem
                  key={history.id}
                  note={history.note}
                  created={formattedCreatedAt}
                  days={parseFloat(history.days)}
                  type={history.type}
                  newBalance={history.new_balance}
                />
              )
            })}
          </>
        )}
      </SideMenu.Body>

      {(forAdjust || !isClient) && (
        <SideMenu.Footer>
          <Button color='light' outline onClick={toggle} className='tw-mr-2'>
            {forAdjust ? 'Cancel' : 'Close'}
          </Button>
          <Button
            color='primary'
            icon={forAdjust && <PlusMinus color='white' />}
            onClick={() =>
              forAdjust ? openAdjustPanel({ policy }) : setShowRequest(true)
            }
          >
            {forAdjust ? 'Adjust balance' : 'Request Time Off'}
          </Button>
        </SideMenu.Footer>
      )}
    </SideMenu>
  )
}

function getTypeLabel(type) {
  switch (type) {
    case POLICY_ACTION_TYPE.ADDITION:
      return 'Add'
    case POLICY_ACTION_TYPE.DEDUCTION:
      return 'Deduct'
    default:
      return ''
  }
}

function AdjustPolicyBalance({ toggle, isOpen, policy }) {
  const schema = yup.object().shape({
    type: yup.string().required('Type field is required'),
    days: yup
      .number()
      .required('Days field is required')
      .when('type', {
        is: (val) => val === POLICY_ACTION_TYPE.DEDUCTION,
        then: (schema) =>
          schema.max(
            policy?.days,
            'Days to deduct is greater than the current balance',
          ),
      })
      .typeError('Days field is required'),
    note: yup.string().required('Note field is required'),
  })

  const { startFetch: adjustBalance, isLoading: isAdjusting } = useFetch({
    action: adjustTimeOffBalance,
    onComplete: () => toggle(true),
    onError: (error) => {
      toastr.error(error)
    },
  })

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ resolver: yupResolver(schema) })

  function onSubmit(values) {
    adjustBalance({ balance_id: policy?.id, ...values })
  }

  const { type, days } = useWatch({ control })
  const currentBalance = parseFloat(policy?.days)
  const newBalance =
    type === POLICY_ACTION_TYPE.ADDITION
      ? currentBalance + parseFloat(days || 0)
      : currentBalance - parseFloat(days || 0)

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SideMenu
        onClose={toggle}
        isOpen={isOpen}
        className='!tw-w-full tw-max-w-[480px]'
        itemListClassName={cn(
          'tw-grid [&>*:nth-child(2)]:tw-overflow-auto [&>*:nth-child(2)]:tw-overscroll-contain tw-grid-rows-[auto_1fr_auto]',
        )}
      >
        <SideMenu.Header toggle={toggle}>Adjust Balance</SideMenu.Header>
        <SideMenu.Body>
          <div className='tw-flex tw-h-full tw-grow tw-flex-col tw-justify-between'>
            <div>
              <ControlledSelect
                control={control}
                name='type'
                label='I would like to'
                options={[
                  { value: POLICY_ACTION_TYPE.ADDITION, label: 'Add' },
                  currentBalance > 0 && {
                    value: POLICY_ACTION_TYPE.DEDUCTION,
                    label: 'Deduct',
                  },
                ].filter(Boolean)}
                wrapperClassName='tw-mb-4'
                error={errors?.type}
              />

              <Label for='days'>Days</Label>
              <InputGroup className='tw-flex !tw-flex-nowrap'>
                <ControlledInput
                  control={control}
                  wrapperClassName='tw-mb-4 tw-flex-grow'
                  className='!tw-rounded-br-none !tw-rounded-tr-none !tw-border-r-0'
                  name='days'
                  type='number'
                />
                <InputGroupAddon addonType='append'>
                  <InputGroupText className='!tw-rounded-bl-none !tw-rounded-tl-none !tw-border-l-0'>
                    Working days
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>

              <ControlledInput
                label='Note'
                control={control}
                name='note'
                type='textarea'
              />
            </div>

            {isNill(type) || isNill(days) || days.length === 0 ? null : (
              <TimeOffSummary
                items={[
                  {
                    label: `Amount to ${getTypeLabel(type)}`,
                    value: formatDays(days),
                  },
                  {
                    label: 'Current balance',
                    value: formatDays(currentBalance),
                  },
                  {
                    label: 'New balance',
                    value: formatDays(newBalance),
                    valueClassName: 'tw-font-bold',
                  },
                ]}
              />
            )}
          </div>
        </SideMenu.Body>
        <SideMenu.Footer>
          <Button color='light' outline onClick={toggle} disabled={isAdjusting}>
            Cancel
          </Button>
          <Button loading={isAdjusting} disabled={isAdjusting} type='submit'>
            Save
          </Button>
        </SideMenu.Footer>
      </SideMenu>
    </form>
  )
}

export function getPolicyExpirationDate({
  carryoverExpirationDays,
  carryoverExpiration,
}) {
  if (carryoverExpiration !== 'expires' && !carryoverExpirationDays) {
    return 'Never expires'
  }

  // Current year
  const currentYear = new Date().getFullYear()
  // Next year, 1st of January
  const nextYearStartDate = new Date(currentYear + 1, 0, 1)

  const carryoverExpirationDate = carryoverExpirationDays
    ? format(addDays(nextYearStartDate, carryoverExpirationDays), 'dd/MM/yyyy')
    : null

  return carryoverExpirationDate
}

export function getPolicyEarningDate({
  contractStartDate = null,
  earningStartDays,
}) {
  const startDate = new Date(contractStartDate)
  const startDateFormatted = format(startDate, 'dd/MM/yyyy')

  const withStartDays = earningStartDays
    ? format(addDays(startDate, earningStartDays), 'dd/MM/yyyy')
    : null

  if (!contractStartDate) {
    return earningStartDays
      ? `${formatDays(earningStartDays)} after contract start date`
      : 'Start date'
  }

  return earningStartDays ? withStartDays : startDateFormatted
}

export function getCarryOverType(carryOverType, carryOverDays) {
  return carryOverType === TimeOffPolicyCarryOverTypes.LIMITED
    ? formatDays(carryOverDays)
    : carryOverType === TimeOffPolicyCarryOverTypes.UNLIMITED
      ? 'Unlimited carryover'
      : 'No carryover'
}

export function getCarryOverExpiration(carryOverExpirationDays) {
  return carryOverExpirationDays
    ? formatDays(carryOverExpirationDays) + ' after carryover'
    : 'Never expires'
}

function UnassignPolicyConfirmationModal({
  toggle,
  isOpen,
  policy,
  contract,
  onSuccess,
}) {
  const { startFetch: unAssignPolicy, isLoading: isUnAssigning } = useFetch({
    action: unassignContractsToPolicy,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error('Failed to unassign policy')
      } else {
        toastr.success('Policy unassigned successfully')
        toggle?.()
        onSuccess?.()
        track(TIMEOFF_EVENTS.UNASSIGNED_WORKER, {
          policy_type: policy.type.name,
          accrual_frequency: policy.accrual_frequency ?? null,
          carryover_used:
            policy.carryover_type === TimeOffPolicyCarryOverTypes.LIMITED,
          source: 'Contract',
          is_tenure_used:
            policy?.policy_type !== TimeOffPolicyTypes.VACATION_POLICY_TYPE
              ? undefined
              : policy?.tenure_rules?.length > 0,
          is_limited:
            policy?.policy_type === TimeOffPolicyTypes.VACATION_POLICY_TYPE
              ? undefined
              : policy?.max_annual_days !== null,
        })
      }
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  return (
    <ConfirmationModal
      toggle={toggle}
      isOpen={isOpen}
      content={
        <>
          <div className='tw-relative tw-flex tw-items-center tw-justify-between tw-pb-2'>
            <Warning size={24} className='tw-text-systemGold-100' />
            <ModalCloseButton toggle={toggle} />
          </div>
          <h4 className='tw-mb-2 tw-text-xl tw-font-semibold tw-text-secondary-120'>
            Are you sure you want to unassign policy?
          </h4>
          <p className='tw-mb-0 tw-text-sm tw-text-text-80'>
            Time off cannot be requested under this policy in the future
          </p>
        </>
      }
      caption='Yes, unassign'
      negativeCaption='No, close'
      buttonColor='danger'
      confirmLoading={isUnAssigning}
      onConfirm={() =>
        unAssignPolicy({
          policyId: policy?.policy?.id,
          contracts: [contract?.id],
        })
      }
    />
  )
}

function TimeOffDaysInfo({ policy }) {
  const isAccrued = policy?.type?.is_accrued === 1
  const top = isAccrued
    ? `${formatDays(policy.days)} available`
    : policy.policy.max_annual_days
      ? `${formatDays(policy.policy.max_annual_days - Number(policy?.days_used))} available`
      : `${formatDays(policy.days_used)} used`
  const bottom =
    isAccrued || policy.policy.max_annual_days
      ? `${formatDays(policy?.days_used)} used`
      : 'Non-accrued'

  return (
    <>
      <div className='tw-text-xs tw-font-bold'>{top}</div>
      <div className='tw-text-xs tw-font-medium tw-text-text-80'>{bottom}</div>
    </>
  )
}

function PolicyStatItem({ policy, contract, isClient, onSuccess }) {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const { canPerformAction } = useTimeOffPermissionCheck()

  const isAccrued = policy?.type?.is_accrued === 1
  const isDefaultOrAccrued = policy?.is_accrual

  function toggleConfirmationModal() {
    setShowConfirmationModal((prev) => !prev)
  }

  const [showPolicyPanel, setShowPolicyPanel] = useState({
    details: false,
    adjust: false,
    policy: null,
  })
  const [isAddTimeOffOpen, setIsAddTimeOffOpen] = useState(null)

  function handleOpenAdjustPanel({ policy }) {
    setShowPolicyPanel((prev) => ({
      ...prev,
      policy: policy ?? prev.policy,
      adjust: true,
      details: false,
    }))
  }

  function viewPolicy(policy) {
    setShowPolicyPanel({ details: true, adjust: false, policy })
  }

  function changePolicy(policy) {
    setShowPolicyPanel({ details: false, adjust: false, change: true, policy })
  }

  return (
    <div
      className={cn(
        'tw-relative tw-rounded tw-border tw-border-l-2 tw-border-surface-30 tw-p-4',
        policyTypeCardColors({ typeId: policy?.type?.id }),
      )}
    >
      <div className='tw-flex tw-items-start tw-justify-between tw-gap-2'>
        <div className='tw-mb-2 tw-text-sm tw-font-bold'>
          {policy?.policy?.name}
        </div>

        <ActionsDropdown
          data={[
            canPerformAction(TIME_OFF_ACTIONS.VIEW) && {
              label: 'View details',
              onClick: () => viewPolicy(policy),
              icon: <Eye size={16} />,
            },
            !isClient && {
              label: 'Request time off',
              icon: <PlusCircle size={16} />,
              onClick: () => setIsAddTimeOffOpen({ policy }),
            },
            isClient &&
              isAccrued &&
              canPerformAction(TIME_OFF_ACTIONS.ADJUST_BALANCE) && {
                label: 'Adjust balance',
                onClick: () => handleOpenAdjustPanel({ policy }),
                icon: <PlusMinus size={16} />,
              },
            isClient &&
              canPerformAction(TIME_OFF_ACTIONS.SUBMIT_TIME_OFF) && {
                label: 'Submit time off',
                onClick: () => setIsAddTimeOffOpen({ policy }),
                icon: <PlusCircle size={16} />,
              },
            isClient &&
              canPerformAction(TIME_OFF_ACTIONS.CHANGE_POLICY) && {
                label: 'Change policy',
                onClick: () => changePolicy(policy),
                icon: <Swap size={16} />,
              },
            isClient &&
              canPerformAction(TIME_OFF_ACTIONS.UNASSIGN_POLICY) && {
                label: 'Unassign policy',
                onClick: () => {
                  toggleConfirmationModal()
                },
                icon: <LinkBreak size={16} />,
                className: isDefaultOrAccrued
                  ? 'tw-text-disabled tw-cursor-not-allowed'
                  : 'tw-text-systemRed-100',
                disabled: isDefaultOrAccrued,
              },
          ].filter(Boolean)}
        />

        <UnassignPolicyConfirmationModal
          toggle={toggleConfirmationModal}
          isOpen={showConfirmationModal}
          policy={policy}
          contract={contract}
          onSuccess={onSuccess}
        />
      </div>
      <TimeOffDaysInfo policy={policy} />

      {showPolicyPanel.details && (
        <PolicyDetailsMenu
          policy={showPolicyPanel.policy}
          isClient={isClient}
          toggle={(reload) => {
            setShowPolicyPanel((prev) => ({ ...prev, details: false }))
            if (reload === true) {
              onSuccess?.()
            }
          }}
          contract={contract}
          openAdjustPanel={handleOpenAdjustPanel}
          isOpen={showPolicyPanel.details}
          refresh={onSuccess}
        />
      )}
      {showPolicyPanel.adjust && (
        <AdjustPolicyBalance
          policy={showPolicyPanel.policy}
          toggle={(reload) => {
            setShowPolicyPanel((prev) => ({ ...prev, adjust: false }))
            if (reload === true) {
              onSuccess?.()
            }
          }}
          isOpen={showPolicyPanel.adjust}
        />
      )}
      {showPolicyPanel.change && (
        <ChangePolicySideMenu
          policy={showPolicyPanel.policy}
          contract={contract}
          toggle={({ reload } = {}) => {
            setShowPolicyPanel((prev) => ({ ...prev, change: false }))
            if (reload === true) {
              onSuccess?.()
            }
          }}
          isOpen={showPolicyPanel.change}
          refresh={onSuccess}
        />
      )}

      {!isAddTimeOffOpen ? null : (
        <TimeOffStatsAddTimeOff
          contract={contract}
          refresh={onSuccess}
          toggle={() => setIsAddTimeOffOpen(null)}
          policy={isAddTimeOffOpen?.policy}
          isClient={isClient}
        />
      )}
    </div>
  )
}

function TimeOffStatsAddTimeOff({
  policy,
  contract,
  refresh,
  toggle,
  isClient,
}) {
  const location = useLocation()
  const isAdmin = location.pathname?.startsWith('/admin/')
  const isEmployee = contract.type === CONTRACT_TYPES.FULL_TIME
  const contractId = contract?.id

  const { timeOffs } = useTimeOffFetch({
    isAdmin,
    isEmployee,
    isContractDetails: isClient,
    contractId,
    autoFetch: !!contractId,
  })

  return (
    <AddTimeOffWithData
      contract={contract}
      timeOffs={timeOffs?.list}
      onSubmit={refresh}
      isOpen
      setIsOpen={toggle}
      policy={policy}
    />
  )
}
