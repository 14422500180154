import { FileCloud, Spinner, Trash } from '@phosphor-icons/react'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import toastr from 'toastr'

import { cn } from 'ui'
import DropzoneInput from '../../../components/Common/dropzone-input'
import CustomSelect from '../../../components/Forms/CustomSelect/CustomSelect'
import { useFetch } from '../../../helpers/hooks'
import { RpTemplate, YourTemplate } from '../../../helpers/SvgIcons'
import { uploadContractFileV2 } from '../../../services/api'
import { MAX_SIZE, MAX_SIZE_READABLE } from '../utils/constants'

export const CONTRACT_TYPE_KEY = {
  RP_TEMPLATE: 'rp-template',
  UPLOADED: 'uploaded',
  YOUR_TEMPLATE: 'your-template',
}

export const CONTRACT_TYPE_MAP = {
  default: CONTRACT_TYPE_KEY.RP_TEMPLATE,
  uploaded: CONTRACT_TYPE_KEY.UPLOADED,
  custom: CONTRACT_TYPE_KEY.YOUR_TEMPLATE,
}

export default function ContractSelect({
  onContractChanged,
  value,
  onFileUploaded,
  templates = [],
  onTemplateSelected = () => {},
  template,
  uploaded,
  onUploadStatusChanged = () => {},
}) {
  const [selected, setSelected] = useState(value)
  const [dropFiles, setDropFiles] = useState([])

  const templateOptions = templates?.map((t) => ({
    value: t?.id,
    label: t?.name,
  }))

  const options = [
    {
      key: CONTRACT_TYPE_KEY.RP_TEMPLATE,
      title: 'Use RemotePass’s Template',
      description: "Use RemotePass's legally compliant contract template",
      icon: <RpTemplate />,
      activeIcon: <RpTemplate isActive />,
    },
    {
      key: CONTRACT_TYPE_KEY.UPLOADED,
      title: 'Upload My Contract',
      description: 'Click here to upload your signed contract\n *PDF only',
      icon: <YourTemplate />,
      activeIcon: <YourTemplate isActive />,
    },
  ]
  if (templates?.length > 0) {
    options.push({
      key: CONTRACT_TYPE_KEY.YOUR_TEMPLATE,
      title: 'Use your templates',
      description: 'Click here to select one of your existing templates',
      icon: <YourTemplate />,
      activeIcon: <YourTemplate isActive />,
    })
  }

  const upload = useFetch({
    action: uploadContractFileV2,
    onComplete: (data) => {
      onFileUploaded(data.path)
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  useEffect(() => {
    onUploadStatusChanged(upload.isLoading)
  }, [onUploadStatusChanged, upload.isLoading])

  function removeAll() {
    setDropFiles([])
  }

  const cancelRef = useRef()

  function handleFileDropped(files) {
    setDropFiles(files)
    const [file] = files

    if (file.size > MAX_SIZE) {
      toastr.error(`The file may not be greater than ${MAX_SIZE_READABLE}`)
    } else {
      upload.startFetch(
        { file },
        true,
        new axios.CancelToken((c) => (cancelRef.current = c)),
      )
    }
  }

  return (
    <div>
      <div
        className={cn(
          'tw-grid tw-min-h-40 tw-gap-4',
          templates?.length > 0 ? 'tw-grid-cols-3' : 'tw-grid-cols-2',
        )}
      >
        {options.map((option) => {
          const isSelected = option.key === selected
          return (
            <button
              key={option.key}
              className='tw-group tw-flex tw-flex-col tw-rounded tw-border tw-border-surface-30 tw-p-4 tw-text-start data-[selected=true]:tw-border-primary-100 data-[selected=true]:tw-text-primary-100'
              data-selected={isSelected}
              type='button'
              onClick={() => {
                setSelected(option.key)
                onContractChanged(option.key)
              }}
            >
              <div className='tw-mb-3'>
                {isSelected ? option.activeIcon : option.icon}
              </div>
              <h6 className='tw-mb-2 tw-text-lg/5 tw-font-medium tw-text-current'>
                {option.title}
              </h6>
              <p className='tw-mb-0 tw-text-xs tw-text-text-60 group-data-[selected=true]:tw-text-current'>
                {option.description}
              </p>
            </button>
          )
        })}
      </div>

      {selected === CONTRACT_TYPE_KEY.UPLOADED ? (
        <DropzoneInput
          name='contractFile'
          accept={{ 'application/pdf': ['.pdf'] }}
          onDropAccepted={handleFileDropped}
          wrapperClassName='tw-mt-4'
          className='tw-px-6 tw-py-8'
          onFileDialogOpen={() => {
            onFileUploaded(null)
            cancelRef.current?.()
          }}
        >
          {({ acceptedFiles }) => {
            if (acceptedFiles.length >= 1 || uploaded) {
              return (
                <div className='tw-flex tw-text-text-100'>
                  <div>{acceptedFiles[0]?.name || 'File is uploaded'}</div>
                  <div className='tw-ms-auto tw-flex tw-items-center tw-gap-1'>
                    {!upload.isLoading ? (
                      <button
                        className='tw-text-systemRed-100'
                        onClick={removeAll}
                      >
                        <Trash aria-label='Remove file' />
                      </button>
                    ) : (
                      <Spinner
                        className='tw-size-4 tw-animate-spinTwo tw-text-primary-100'
                        aria-hidden
                      />
                    )}
                  </div>
                </div>
              )
            }

            return (
              <div className='tw-text-center'>
                <FileCloud
                  size={40}
                  weight='duotone'
                  className='tw-inline-block tw-text-primary-100'
                />

                <div className='tw-mt-4'>
                  <h4 className='md:tw-hidden'>Click to upload files.</h4>
                  <h3 className='tw-hidden md:tw-block'>
                    Drop files here or click to upload.
                  </h3>
                  <p className='tw-text-xs tw-text-disabled'>
                    *PDF only. Max file size {MAX_SIZE_READABLE}
                  </p>
                </div>
              </div>
            )
          }}
        </DropzoneInput>
      ) : selected === CONTRACT_TYPE_KEY.YOUR_TEMPLATE ? (
        <CustomSelect
          wrapperClassName='tw-mt-4'
          label='Templates'
          options={templateOptions}
          onChange={onTemplateSelected}
          value={template}
        />
      ) : null}
    </div>
  )
}
