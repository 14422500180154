import { yupResolver } from '@hookform/resolvers/yup'
import { subDays } from 'date-fns'
import React, { forwardRef, useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { Controller, useForm } from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import {
  Col,
  Container,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'
import { CreditCard } from '@phosphor-icons/react'

import { cn } from 'ui'
import { ReactComponent as CardIllustration } from '../../../assets/images/card-request.svg'
import ConfirmationModal from '../../../components/Common/ConfirmationModal'
import DropzoneInput from '../../../components/Common/dropzone-input'
import customStyles from '../../../components/Common/react-select-custom-styles'
import ControlledSelect from '../../../components/ControlledSelect'
import CustomSelect from '../../../components/Forms/CustomSelect/CustomSelect'
import ControlledRadioList from '../../../components/controlled-radio-list'
import Button from '../../../components/ui/button'
import { useFetch } from '../../../helpers/hooks'
import {
  getCardEnumerations,
  getContractList,
  requestNewCard as requestNewCardAction,
} from '../../../services/api'
import { getErrorMessage } from '../../../utils/get-errors'
import { mapCurrencyToOption } from '../../../utils/map-to-option'
import KYCModal from '../../Contract/components/KYCModal'
import CardEdd, {
  addEddValidation,
  getDefaultEddValues,
} from '../organisms/card-edd'
import { UserInfosForm } from '../screens/cards-kyc'
import { CARD_TYPE_ENUM } from '../utils/card-types-status'
import { commonAddressSchema } from '../utils/common-address-field-schema'
import {
  birthDateFormat,
  formatRequestData,
  isDataValid,
} from '../utils/format-request-data'
import { getFullPhoneNumber } from '../utils/get-full-phone-number'
import {
  addInfoFormValidation,
  getDefaultInfoValues,
  infoFormFields,
} from '../utils/info-form-fields'
import useUpdateAddress from '../utils/use-update-address'
import EmptyContent from './empty-content'
import { FormContentRenderer } from './form-content-renderer'
import './phone-input.css'

export const CARD_ADVANTAGES = [
  {
    title: 'Get paid instantly with No fees',
    description:
      'Request a card and use your RemotePass wallet to top up your card balance with zero fees',
  },
  {
    title: 'Spend and Pay anywhere ',
    description: 'Hold your balance in USD and spend globally',
  },
  {
    title: 'Manage your card and transactions',
    description:
      'Manage your cards and your transactions directly on RemotePass',
  },
]

export default function RequestNewCard({ afterRequestNewCard, preConditions }) {
  const [showModal, setShowModal] = useState(false)
  const [showKYCModal, setShowKYCModal] = useState(false)
  const [showConditionsModal, setConditionsModal] = useState(false)

  const history = useHistory()

  const userProfile = useSelector((state) => state?.userProfile?.userProfile)
  const { kyc_doc_missing: kycDocMissing, kyc_verified: kycVerified } =
    userProfile ?? {}

  const kycDocumentsEmpty = preConditions?.kyc_documents?.length <= 0

  const issueWithDocuments = kycDocMissing || kycDocumentsEmpty

  const needsKyc =
    kycVerified !== 1 ||
    issueWithDocuments ||
    !preConditions ||
    preConditions?.success === false

  const isNationalityNotSupported =
    !preConditions?.nationality_card_ordering_enabled

  const isCountryNotSupported =
    (!needsKyc &&
      !preConditions?.card_configurations?.is_virtual_card_supported) ||
    isNationalityNotSupported

  const notSupportedCountryName = isNationalityNotSupported
    ? userProfile?.Country_of_Citizenship?.name
    : userProfile?.country?.name

  function handleInitiateRequest() {
    // eslint-disable-next-line no-constant-condition
    if (needsKyc && false) {
      setShowKYCModal(true)
    } else if (
      !preConditions?.is_qualified ||
      (!preConditions?.card_configurations?.is_virtual_card_supported &&
        !preConditions?.card_configurations?.is_physical_card_supported)
    ) {
      setConditionsModal(true)
    } else {
      setShowModal(true)
    }
  }

  const kycModalMessage = issueWithDocuments
    ? 'To create a RemotePass Card, you need to re-verify your identity.'
    : 'To create a RemotePass Card, you need to verify your identity.'

  const falsePreConditions = preConditions?.pre_conditions
    ?.filter((c) => c.result === false)
    .map((c) => c.title)

  return (
    <EmptyContent
      className='mb-1 py-4 px-4 px-md-0'
      minHeight='calc(100vh - 300px)'
    >
      <CardIllustration className='px-4' />

      <div className='text-center'>
        <p className='text-gray-h' style={{ fontWeight: 600, fontSize: 32 }}>
          RemotePass Card allows you to:
        </p>

        {CARD_ADVANTAGES.map((e, i) => (
          <div key={i} className='d-flex py-3 gap-14'>
            <div
              className='bg-soft-success rounded-circle align-items-center justify-content-center mt-1'
              style={{
                height: 24,
                width: 24,
                paddingLeft: 2,
                paddingTop: 2,
              }}
            >
              <i className='bx bx-check text-success font-size-20 text-center' />
            </div>
            <div>
              <p
                className='font-size-20 text-gray-h text-left mb-0'
                style={{ fontWeight: 600 }}
              >
                {e?.title}
              </p>
              <p
                className='mb-0 font-size-16 text-left'
                style={{ color: 'var(--gray-600)', fontWeight: 400 }}
              >
                {e?.description}
              </p>
            </div>
          </div>
        ))}
      </div>

      <Button
        type='button'
        onClick={handleInitiateRequest}
        disabled={isCountryNotSupported}
        color={isCountryNotSupported ? 'secondary' : 'primary'}
      >
        {isCountryNotSupported && !!notSupportedCountryName
          ? notSupportedCountryName + ' is not yet supported'
          : 'Request Your Card'}
      </Button>

      <a
        className='text-primary'
        target='_blank'
        rel='noreferrer'
        href='https://help.remotepass.com/en/collections/3728752-remotepass-card'
      >
        Learn more
      </a>

      <NewCardModalForm
        isOpen={showModal}
        toggle={() => setShowModal((o) => !o)}
        afterRequestNewCard={afterRequestNewCard}
        preConditions={preConditions}
      />

      <KYCModal
        isOpen={showKYCModal}
        toggle={() => setShowKYCModal((o) => !o)}
        proceed={() => {
          setShowKYCModal(false)
          history.push(`/settings#kyc`, {
            needsReverification: issueWithDocuments,
          })
        }}
        message={kycModalMessage}
      />

      <ConfirmationModal
        toggle={() => setConditionsModal(false)}
        isOpen={showConditionsModal}
        title='Not qualified'
        content={
          !preConditions?.card_configurations?.is_virtual_card_supported &&
          !preConditions?.card_configurations?.is_physical_card_supported ? (
            <p>{`${userProfile?.country?.name} is not yet supported for the card program.`}</p>
          ) : (
            <div>
              <h5>You should:</h5>
              <ul className='font-size-14 pl-4 mb-0'>
                {falsePreConditions?.map((condition) => {
                  return <li key={condition}>{condition}</li>
                })}
              </ul>
            </div>
          )
        }
        onConfirm={() => setConditionsModal(false)}
        withCancel={false}
      />
    </EmptyContent>
  )
}

const imagesAndPdfType = 'image/jpeg,image/gif,image/png,application/pdf'

export const UI_STATE = {
  IDLE: 'IDLE',
  UPLOADING: 'UPLOADING',
  UPLOADED: 'UPLOADED',
}
export const cardFormFields = [
  {
    name: 'card_type',
    type: 'cc_card_type',
    fullCol: true,
    rules: { required: 'This field is required' },
  },
]
const REQUIRED_DOCUMENTS = {
  ID_CARD_FRONT: 'ID CARD - FRONT',
  ID_CARD_BACK: 'ID CARD - BACK',
  PASSPORT_FRONT: 'PASSPORT - FRONT',
}
const formFields = [
  { title: '', fields: cardFormFields },
  { title: 'Personal information:', fields: infoFormFields },
]
let schema = commonAddressSchema.concat(
  yup.object().shape({
    terms: yup
      .boolean()
      .oneOf([true], 'You must accept the terms and conditions')
      .required('You must accept the terms and conditions'),
  }),
)

formFields.forEach(({ fields }) => {
  schema = addInfoFormValidation(schema, fields)
})

export const fullName = (user) =>
  [user?.first_name, user?.middle_name, user?.last_name]
    .filter(Boolean)
    .join(' ')
export const defaultSourceOfWealth = 'employment'
export const defaultCompanyNature = 'technology'

const newCardFormId = 'newCardFormId'
export function NewCardModalForm({
  isOpen,
  toggle,
  afterRequestNewCard,
  preConditions,
}) {
  const userProfile = useSelector((state) => state?.userProfile?.userProfile)
  const user = useSelector((state) => state.Account?.user)

  const [uiState, setUiState] = useState(UI_STATE.IDLE)
  const [validationCompleted, setValidationCompleted] = useState(false)

  const [validatingPhone, setValidatingPhone] = useState(false)

  const { data: enumerations } = useFetch({
    action: getCardEnumerations,
    autoFetch: true,
  })

  if (preConditions?.card_configurations?.is_edd) {
    schema = addEddValidation(schema)
  }

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      preferred_name: '',

      // prefilled from user
      ...getDefaultInfoValues(userProfile),

      // prefilled Edd values
      ...getDefaultEddValues(userProfile),

      // We only support Virtual Card for now
      card_type: CARD_TYPE_ENUM.VIRTUAL,

      // Will add 'mobile_country_code' on request time
      mobile_country_code: '',
    },
  })

  const { data: contracts } = useFetch(
    {
      action: getContractList,
      autoFetch: preConditions?.card_configurations?.is_edd,
      body: { status: [4] },
    },
    [preConditions?.card_configurations?.is_edd],
  )

  useEffect(() => {
    const nationalityCode = userProfile?.Country_of_Citizenship?.iso3
    const nationalityFound = enumerations?.nationalities?.find(
      (n) => n.code === nationalityCode,
    )
    const defaultNationality = {
      value: nationalityFound?.nationality_name,
      label: nationalityFound?.description,
    }

    const countryCode = userProfile?.country?.iso3
    const countryFound = enumerations?.countries?.find(
      (n) => n.iso3 === countryCode,
    )
    const defaultCountry = mapCountryToOptionCards(countryFound)

    setValue('nationality', defaultNationality)
    setValue('country', defaultCountry)

    if (preConditions?.card_configurations?.is_edd) {
      const firstContract = contracts?.[0]
      const companyName = firstContract?.company_name
      const contractName = firstContract?.name

      setValue('edd_companyName', companyName)
      setValue('edd_employment', contractName)
    }
  }, [
    enumerations?.nationalities,
    enumerations?.countries,
    userProfile?.Country_of_Citizenship?.iso3,
    userProfile?.country?.iso3,
    preConditions?.card_configurations?.is_edd,
    setValue,
    contracts,
  ])

  const { updateContractorAddress } = useUpdateAddress()

  const { startFetch: requestNewCard, isLoading: isRequestingNewCard } =
    useFetch({
      action: requestNewCardAction,
      onComplete: (_, body) => {
        toggle()
        document.body.classList.remove('modal-open')

        // Update address on request success
        const residentialAddress = body?.user_residential_address
        updateContractorAddress(residentialAddress)

        if (typeof afterRequestNewCard === 'function') {
          afterRequestNewCard()
        }
      },
      onError: (err) => {
        toastr.error(getErrorMessage(err))
      },
    })

  function onCardCreate(data) {
    if (
      !validationCompleted &&
      preConditions?.card_configurations?.required_documents?.includes(
        REQUIRED_DOCUMENTS.ID_CARD_FRONT ||
          REQUIRED_DOCUMENTS.ID_CARD_BACK ||
          REQUIRED_DOCUMENTS.PASSPORT_FRONT,
      )
    ) {
      toastr.error('Please upload missing documents')
    } else {
      requestNewCard(data)
    }
  }

  async function handleFormValid(data) {
    const enrichedData = { ...data }

    if (uiState !== UI_STATE.UPLOADED) {
      toastr.error('Please upload missing documents')
      return
    }

    const validData = isDataValid(enrichedData)

    if (!validData) {
      return
    }

    // Add mobile country code
    const { valid, mobile, mobileCountryCode } = await getFullPhoneNumber({
      token: user?.token,
      mobile: enrichedData?.mobile,
      setLoading: setValidatingPhone,
    })

    if (valid) {
      enrichedData.mobile = mobile
      enrichedData.mobile_country_code = mobileCountryCode
    } else {
      toastr.error('Your phone number is not valid')
      return
    }

    const formattedData = formatRequestData(enrichedData, {
      isEdd: preConditions?.card_configurations?.is_edd,
    })

    onCardCreate(formattedData)
  }

  function handleFormErrors(err) {
    // eslint-disable-next-line no-console
    console.log(err)
    toastr.error('Please check the form for errors')
  }

  const isLoading = isRequestingNewCard || validatingPhone

  return (
    <Modal isOpen={isOpen} toggle={toggle} size='lg' scrollable>
      <ModalHeader>Request a RemotePass Card</ModalHeader>
      <ModalBody>
        <CardForm
          onSubmit={handleSubmit(handleFormValid, handleFormErrors)}
          errors={errors}
          control={control}
          isLoading={isLoading}
          allFields={formFields}
          watch={watch}
          formId={newCardFormId}
          uiState={uiState}
          setUiState={setUiState}
          enumerations={enumerations}
          setValidationCompleted={setValidationCompleted}
          preConditions={preConditions}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          type='button'
          disabled={isLoading}
          color='light'
          outline
          onClick={toggle}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          disabled={isLoading}
          loading={isLoading}
          color='primary'
          formId={newCardFormId}
        >
          Request my card
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const eighteenYears = 18 * 365
const eighteenYearsAgo = subDays(new Date(), eighteenYears)

const hundredYears = 100 * 365
const hundredYearsAgo = subDays(new Date(), hundredYears)

export const Divider = () => (
  <hr className='mx-n4' style={{ borderTopColor: 'var(--gray-200)' }} />
)

export function CardForm({
  formId,
  onSubmit,
  allFields,
  isLoading,
  control,
  errors,
  uiState,
  setUiState,
  enumerations,
  preConditions,
}) {
  return (
    <form id={formId} onSubmit={onSubmit} className='px-2'>
      <FormContentRenderer
        fieldData={enumerations ?? {}}
        formFields={allFields}
        isLoading={isLoading}
        control={control}
        errors={errors}
        preConditions={preConditions}
      />

      <Divider />

      <UserInfosForm
        errors={errors}
        control={control}
        uiState={uiState}
        setUiState={setUiState}
        enumerations={enumerations}
      />

      {preConditions?.card_configurations?.is_edd ? (
        <>
          <hr
            className='mx-n4 mt-5'
            style={{ borderTopColor: 'var(--gray-200)' }}
          />

          <h5 className='d-inline-block mb-3 pt-3' style={{ color: '#424652' }}>
            Enhanced Due Diligence
          </h5>

          <CardEdd control={control} errors={errors} />
        </>
      ) : null}

      <UserConsent control={control} errors={errors} />
    </form>
  )
}

export function UserConsent({ control, errors }) {
  return (
    <Container className='py-4'>
      <Row>
        <Col className='px-1'>
          <FormGroup className='mb-0'>
            <Label
              className='font-size-14 mb-0 d-flex align-items-center rp-checkbox-label rp-font-normal gap-10 rounded-sm'
              style={{ color: 'var(--slate-700)' }}
            >
              <ControlledInput
                type='checkbox'
                name='terms'
                control={control}
                className='flex-shrink-0 rp-checkbox-input d-inline-block'
                error={errors?.terms}
                showError={false}
              />

              <span style={{ lineHeight: 1 }}>
                I accept the{' '}
                <a
                  href='https://www.remotepass.com/terms-of-use'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='!hover:underline'
                >
                  terms and conditions
                </a>{' '}
                of the card program.
              </span>
            </Label>

            {!errors?.terms?.message ? null : (
              <small className='form-text text-danger'>
                {errors?.terms?.message}
              </small>
            )}
          </FormGroup>
        </Col>
      </Row>
    </Container>
  )
}

// TODO: move to a separate file
export function FormField(props) {
  const { label, name, className, error, disabled, rules } = props

  return (
    <FormGroup className={className} disabled={disabled}>
      {!label ? null : (
        <Label htmlFor={name} style={{ lineHeight: '24px' }}>
          {label}
          {rules?.required && (
            <span className='text-danger font-size-16 ml-1'>*</span>
          )}
        </Label>
      )}

      <RenderFormField {...props} error={error?.message} />

      {!error?.message ? null : (
        <FormText id={name + '-error-msg'} color='danger'>
          {error?.message}
        </FormText>
      )}
    </FormGroup>
  )
}

function RenderFormField({
  type,
  name,
  control,
  error,
  disabled,
  rules,
  data,
  preConditions,
  options,
  placeholder,
  ...rest
}) {
  const controllerProps = {
    control,
    error,
    name,
    required: !!rules?.required,
    rules,
    disabled,
    data,
    options,
    placeholder,
  }

  switch (type) {
    case 'date': {
      return <ControlledDatePicker {...controllerProps} />
    }
    case 'tel': {
      return <ControlledPhoneInput {...controllerProps} />
    }
    case 'email': {
      return <ControlledInput {...controllerProps} type={type} />
    }
    case 'text': {
      return <ControlledInput {...controllerProps} />
    }
    case 'radio': {
      return (
        <ControlledRadioList
          {...controllerProps}
          {...rest}
          label=''
          error={null}
        />
      )
    }
    case 'select': {
      return (
        <ControlledSelect
          {...controllerProps}
          isMulti={rest?.isMulti}
          otherOption={rest?.otherOption}
        />
      )
    }
    case 'country': {
      return (
        <ControlledCountrySelect
          {...controllerProps}
          countries={data?.countries || []}
        />
      )
    }
    case 'nationality': {
      return (
        <ControlledCountrySelect
          {...controllerProps}
          countries={data?.nationalities || []}
        />
      )
    }
    case 'gender': {
      return (
        <ControlledGenderSelect
          {...controllerProps}
          genders={data?.genders || []}
        />
      )
    }
    case 'title': {
      return (
        <ControlledGenderSelect
          {...controllerProps}
          genders={data?.titles || []}
          placeholder='Select a title'
        />
      )
    }
    case 'file': {
      return <ControlledFileInput {...controllerProps} />
    }
    case 'cc_card_type': {
      const cardConfig = preConditions?.card_configurations
      const physicalCardFee = cardConfig?.order_physical_card_fees ?? 15
      const virtualCardFee = cardConfig?.order_virtual_card_fees ?? 5

      return (
        <ControlledCCardType
          {...controllerProps}
          options={data?.card_types?.map(({ type, name: label }) => {
            const value = type
            const disabled =
              // ? !cardConfig?.is_physical_card_supported
              value === CARD_TYPE_ENUM.PHYSICAL
                ? true
                : !cardConfig?.is_virtual_card_supported

            const fee =
              value === CARD_TYPE_ENUM.PHYSICAL
                ? physicalCardFee
                : virtualCardFee

            return {
              value,
              label,
              disabled,
              body: `One-time $${fee} fee per card`,
            }
          })}
        />
      )
    }
    case 'currency': {
      return (
        <ControlledCurrencySelect {...controllerProps} placeholder='Currency' />
      )
    }
    default: {
      return null
    }
  }
}

function ControlledInput({
  control,
  defaultValue,
  error,
  showError = true,
  disabled,
  name,
  rules,
  placeholder,
  className,
  type = 'text',
  style,
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { value, onChange, name, onBlur, ref } }) => {
        return (
          <>
            <Input
              value={value}
              onChange={onChange}
              name={name}
              id={name}
              onBlur={onBlur}
              ref={ref}
              invalid={!!error}
              disabled={disabled}
              placeholder={placeholder}
              className={className}
              type={type}
              style={style}
            />

            {!error?.message ? null : !showError ? null : (
              <small className='form-text text-danger'>{error.message}</small>
            )}
          </>
        )
      }}
    />
  )
}

export function mapCountryToOptionCards(c) {
  if (!c) return {}
  return {
    label: c.name ?? c.description,
    value: c.id ?? c.code,
    flag: c?.svg ? `/${c.svg}` : ``,
  }
}

function mapGenderToOption(c) {
  if (!c) return {}
  return { label: c.description, value: c.code }
}

function ControlledCountrySelect({
  control,
  defaultValue,
  error,
  name,
  disabled,
  rules,
  countries,
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { onChange, value, name } }) => {
        return (
          <CustomSelect
            name={name}
            value={value}
            // styles={customStyles}
            onChange={onChange}
            hasError={!!error}
            aria-invalid={!!error}
            isDisabled={disabled}
            aria-errormessage={name + '-error-msg'}
            placeholder='Select a country'
            options={countries?.map(mapCountryToOptionCards)}
          />
        )
      }}
    />
  )
}

export function ControlledCurrencySelect({
  control,
  defaultValue,
  name,
  error,
  disabled,
  rules,
  ...otherProps
}) {
  const currencies = useSelector(
    (state) => state.Layout?.staticData?.currencies ?? [],
  )

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { onChange, value, name } }) => {
        return (
          <CustomSelect
            name={name}
            value={value}
            styles={customStyles}
            onChange={onChange}
            hasError={!!error}
            aria-invalid={!!error}
            isDisabled={disabled}
            aria-errormessage={name + '-error-msg'}
            options={currencies?.map((c) => mapCurrencyToOption(c))}
            {...otherProps}
          />
        )
      }}
    />
  )
}

function ControlledPhoneInput({
  control,
  defaultValue,
  error,
  disabled,
  name,
  rules,
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { onChange, value, name } }) => {
        return (
          <PhoneInput
            country='ae'
            value={value}
            onChange={onChange}
            disabled={disabled}
            defaultErrorMessage={error}
            inputProps={{ name, required: rules?.required }}
          />
        )
      }}
    />
  )
}

function ControlledGenderSelect({
  control,
  defaultValue,
  error,
  name,
  disabled,
  rules,
  genders,
  placeholder,
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { onChange, value, name } }) => {
        return (
          <CustomSelect
            name={name}
            value={value}
            onChange={onChange}
            hasError={!!error}
            aria-invalid={!!error}
            isDisabled={disabled}
            aria-errormessage={name + '-error-msg'}
            placeholder={placeholder || 'Select a gender'}
            options={genders?.map(mapGenderToOption)}
          />
        )
      }}
    />
  )
}

export const CustomRadio = ({
  isDisabled,
  options,
  onChange,
  value,
  children,
  name,
  className,
  hasError,
}) => {
  return (
    <div className='tw-flex tw-flex-wrap tw-gap-4'>
      {options?.map(({ value: _value, disabled, label, body }) => {
        const isOptionDisabled = isDisabled || disabled
        const isSelected = _value === value
        return (
          <label key={_value} className='tw-flex-grow'>
            <input
              name={name}
              value={_value}
              type='radio'
              style={{
                appearance: 'none',
                position: 'absolute',
                opacity: 0,
                width: 0,
                height: 0,
              }}
              onChange={() => {
                if (!isOptionDisabled) {
                  onChange(_value)
                }
              }}
              disabled={isOptionDisabled}
              checked={isSelected}
            />
            <div
              className={cn(
                'tw-flex tw-cursor-pointer tw-items-center tw-gap-4 tw-rounded-md tw-border tw-border-surface-30 tw-p-4',
                {
                  'tw-border-2 tw-border-primary tw-bg-white tw-text-primary hover:tw-bg-primary/10':
                    isSelected,
                  'tw-bg-disabled/10 tw-text-text-60 hover:tw-cursor-not-allowed':
                    isOptionDisabled,
                  'tw-border-red': hasError,
                },
                className,
              )}
            >
              {children({ isSelected, isOptionDisabled, label, body })}
            </div>
          </label>
        )
      })}
    </div>
  )
}

function CCardType({
  options,
  name,
  onChange,
  value: globalValue,
  isDisabled,
}) {
  return (
    <CustomRadio
      isDisabled={isDisabled}
      name={name}
      onChange={onChange}
      options={options}
      value={globalValue}
    >
      {({ isSelected, isOptionDisabled, label, body }) => (
        <>
          <CreditCard
            size={32}
            weight='fill'
            className={cn('tw-fill-black', {
              'tw-rotate-[-5deg] tw-fill-primary': isSelected,
              'tw-fill-text-60': isOptionDisabled,
            })}
          />
          <span>
            <p className='tw-mb-0 tw-text-base'>{label}</p>
            <p className='tw-mb-0 tw-text-sm'>{body}</p>
          </span>
        </>
      )}
    </CustomRadio>
  )
}

function ControlledCCardType({
  control,
  defaultValue,
  name,
  disabled,
  options,
  rules,
  ...otherProps
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { onChange, value } }) => {
        return (
          <CCardType
            options={options}
            value={value}
            onChange={onChange}
            isDisabled={disabled}
            {...otherProps}
          />
        )
      }}
    />
  )
}

function ControlledDatePicker({
  control,
  defaultValue,
  name,
  error,
  disabled,
  rules,
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { onChange, value } }) => {
        return (
          <ReactDatePicker
            minDate={hundredYearsAgo}
            maxDate={eighteenYearsAgo}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode='select'
            showPopperArrow={false}
            disabled={disabled}
            dateFormat={birthDateFormat}
            customInput={<CustomDateInput error={error} />}
            selected={value}
            onChange={onChange}
          />
        )
      }}
    />
  )
}

const CustomDateInput = forwardRef(function CustomDateInput(
  { value, onClick, error, disabled },
  ref,
) {
  return (
    <div className='position-relative'>
      <Input
        onClick={onClick}
        invalid={!!error}
        ref={ref}
        value={value}
        disabled={disabled}
        readOnly={disabled}
      />
      <i
        className='bx bx-calendar-alt font-size-18 position-absolute'
        style={{ top: '50%', right: 12, transform: 'translate(0px, -50%)' }}
      />
    </div>
  )
})

function getAcceptText(key) {
  switch (key) {
    case 'application/pdf': {
      return 'PDF only'
    }
    case imagesAndPdfType: {
      return 'PDF and images'
    }

    default: {
      return null
    }
  }
}

export function ControlledFileInput({
  control,
  defaultValue,
  error,
  disabled,
  name,
  rules,
  onUpload,
}) {
  const { accept, ...theRules } = rules ?? {}
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={theRules}
      render={({ field: { onChange, value, name } }) => {
        function handleReset(e) {
          e.stopPropagation()
          onChange(null)
        }
        function handleChange(acceptedFiles) {
          const file = acceptedFiles[0]
          onChange(file)
          onUpload?.(file)
        }

        const fileType = getAcceptText(accept)

        return (
          <DropzoneInput
            onDropAccepted={handleChange}
            name={name}
            required={rules?.required}
            invalid={!!error}
            disabled={disabled}
            accept={accept}
          >
            <div
              style={{ minHeight: '2rem', gap: '0.5rem' }}
              className='d-flex align-items-center'
            >
              {value ? (
                <>
                  <span className='text-body'>{value.name}</span>
                  <button
                    className='rp-btn-nostyle bg-soft-danger d-flex p-1 rounded'
                    onClick={handleReset}
                    type='button'
                  >
                    <i className='bx bx-trash text-danger font-size-16' />
                  </button>
                </>
              ) : (
                <>
                  <i className='bx bx-upload font-size-28' />
                  <span className='text-body'>
                    Drop file or click to upload
                    {!fileType ? null : ` (${fileType})`}
                  </span>
                </>
              )}
            </div>
          </DropzoneInput>
        )
      }}
    />
  )
}
