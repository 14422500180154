import { ActionsDropdown } from 'ui'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import {
  WarningOctagon,
  Copy,
  PencilSimple,
  TrashSimple,
} from '@phosphor-icons/react'
import { useFetch } from '../../../../../helpers/hooks'
import {
  deleteAutomation,
  duplicateAutomation,
} from '../../../../../services/api-automations'
import Button from '../../../../../components/ui/button'
import toastr from 'toastr'
import { AutomationMetrics } from '../tools/automation-metrics'

export function AutomationMenuColumn({ rowData, refetch }) {
  const history = useHistory()
  const [open, setOpen] = useState()
  const toggle = () => setOpen((prv) => !prv)
  const { isLoading: isDeleting, startFetch: _deleteAutomation } = useFetch({
    action: deleteAutomation,
    isOpenApi: true,
    autoFetch: false,
    onComplete: () => refetch(),
    onError: (err) => toastr.error(err),
  })
  const { isLoading: isDuplicating, startFetch: duplicate } = useFetch({
    action: duplicateAutomation,
    isOpenApi: true,
    body: { templateId: rowData._id },
    onComplete: () => {
      AutomationMetrics.logDuplicated({
        Source: 'List',
        Type: 'scratch',
        Trigger: rowData?.workflow_steps?.find((s) => s.type === 'TRIGGER'),
        Steps: rowData?.workflow_steps?.filter((s) => s.type !== 'TRIGGER'),
      })
      refetch()
    },
    onError: (err) => toastr.error(err),
  })
  return (
    <>
      <ActionsDropdown
        loading={isDuplicating}
        data={[
          {
            icon: <Copy />,
            label: 'Duplicate',
            onClick: () => duplicate(),
          },
          {
            icon: <PencilSimple />,
            label: 'Edit automation',
            onClick: () => history.push(`/automations/add?id=${rowData._id}`),
          },
          {
            icon: <TrashSimple className='tw-text-red' />,
            label: 'Delete automation',
            className: 'tw-text-red',
            onClick: () => setOpen(true),
          },
        ]}
      />
      <Modal centered isOpen={open} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <WarningOctagon size={25} className='tw-text-systemRed-100' />
        </ModalHeader>
        <ModalBody className='d-flex flex-column'>
          <span className='tw-text-base tw-font-bold'>
            {`Delete “${rowData.name}”?`}
          </span>
          <span className='tw-text-s tw-text-secondary'>
            This action is permanent and cannot be undone. Are you sure you want
            to delete this automation?
          </span>
        </ModalBody>
        <ModalFooter>
          <Button
            className='tw-mb-2 !tw-bg-white'
            textClassName='!tw-text-black'
            color='surface-30'
            outline={true}
            onClick={toggle}
          >
            No, cancel
          </Button>
          <Button
            color='danger'
            loading={isDeleting}
            onClick={() => {
              _deleteAutomation({
                id: rowData._id,
              })
            }}
          >
            Yes, delete
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}
