import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Input,
} from 'reactstrap'
import React, { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import omit from 'lodash/omit'
import { PlusCircle } from '@phosphor-icons/react'
import Button from '../../../../../components/ui/button'
import entries from 'lodash/entries'

export function AddFilterButtons(props) {
  const { watch } = useFormContext()
  const triggerCategory = watch(`steps.0`)?.event?.category
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const filters = (props?.filters?.all ?? []).filter((i) =>
    i.categories?.includes(triggerCategory),
  )

  return (
    <Controller
      name='filters'
      render={({ field: { value, onChange } }) => (
        <Dropdown
          isOpen={dropdownOpen}
          toggle={() => setDropdownOpen((prevState) => !prevState)}
        >
          <DropdownToggle
            tag={(props) => (
              <Button
                disabled={!filters?.length}
                outline={!dropdownOpen}
                circle
                iconRight={
                  <PlusCircle
                    size={20}
                    style={{ marginLeft: '-5px' }}
                    weight='fill'
                  />
                }
                size='sm'
                {...props}
              />
            )}
          >
            Add Filter
          </DropdownToggle>
          <DropdownMenu className='tw-max-h-[300px] tw-w-[250px] tw-overflow-auto'>
            <div className='tw-flex tw-flex-col tw-gap-2 tw-px-2 tw-py-2'>
              {filters.map((f, index) => {
                const checked = value?.[f?.filter]
                return (
                  <DropdownItem
                    disabled={!checked && entries(value)?.length >= 10}
                    onClick={() => {
                      const payload = checked
                        ? omit(value, [f.filter])
                        : {
                            ...value,
                            [`${f.filter}`]: [],
                          }
                      onChange(payload)
                    }}
                    key={`${f.title}-${index}`}
                    header={f?.type === 'header'}
                  >
                    {f.type !== 'header' ? (
                      <Input defaultChecked={checked} type='checkbox' />
                    ) : null}

                    {f.title}
                  </DropdownItem>
                )
              })}
            </div>
          </DropdownMenu>
        </Dropdown>
      )}
    />
  )
}
