import { Info, Plus } from '@phosphor-icons/react'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router'
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from 'reactstrap'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

import Toggle from '../../components/Forms/Toggle/Toggle'
import { StyledH3 } from '../../components/Typo'
import Button from '../../components/ui/button'
import Loader from '../../components/ui/loader'
import FEATURE_FLAGS from '../../config/feature-flags'
import { PAYSEND, WITHDRAW_METHODS } from '../../core/config/payment-methods'
import { BE_CONTRACT_CATEGORY } from '../../helpers/enum'
import { useFetch } from '../../helpers/hooks'
import {
  connectPaypal,
  getFullTimeContractDetails,
  updateContractorProfile,
  verifyCoinbase,
  verifyPaypal,
} from '../../services/api'
import KYCModal from '../Contract/components/KYCModal'
import BankAccounts from '../withdrawProcess/BankAccounts'
import CashPointAccount from '../withdrawProcess/CashPointAccount'
import CoinbaseAccounts from '../withdrawProcess/CoinbaseAccounts'
import PaypalAccounts from '../withdrawProcess/PaypalAccounts'
import { AddNewCardModalForm } from '../withdrawProcess/credit-cards'
import PayoneerAccount from '../withdrawProcess/payoneer-account'
import coinbaseIllustration from './../../assets/images/coinbase_icon.svg'
import payoneerIllustration from './../../assets/images/payoneer-logo.png'
import paySendIllustration from './../../assets/images/top-up-card.svg'
import warningIllustration from './../../assets/images/warning.png'
import CashPointCard from './components/CashPointCard'
import CoinbaseMethodCard from './components/CoinbaseMethodCard'
import PaypalMethodCard from './components/PaypalMethodCard'
import WithdrawMethodCard from './components/WithdrawMethodCard'
import CardMethod from './components/card-method'
import PayoneerMethodCard from './components/payoneer-method-card'

const METHODS = {
  BANK: {
    label: 'Bank',
    value: WITHDRAW_METHODS.BANK_TRANSFER.id,
    key: 'bank',
  },
  PAYPAL: {
    label: WITHDRAW_METHODS.PAYPAL.name,
    value: WITHDRAW_METHODS.PAYPAL.id,
    key: 'paypal',
  },
  CASH_POINT: {
    label: WITHDRAW_METHODS.CASH_POINT.name,
    value: WITHDRAW_METHODS.CASH_POINT.id,
    key: 'cashPoint',
  },
  PAYONEER_EMAIL: {
    label: 'Payoneer Email',
    value: 300,
    key: 'payoneer-email',
  },
  COINBASE: {
    label: WITHDRAW_METHODS.COINBASE.name,
    value: WITHDRAW_METHODS.COINBASE.id,
    key: 'coinbase',
  },
  PAYONEER: {
    label: WITHDRAW_METHODS.PAYONEER.name,
    value: WITHDRAW_METHODS.PAYONEER.id,
    key: 'payoneer',
  },
  PAYSEND: {
    label: WITHDRAW_METHODS.PAYSEND.name,
    value: WITHDRAW_METHODS.PAYSEND.id,
    key: 'paysend',
  },
}

const modalTitle = Object.values(METHODS).reduce((acc, entry) => {
  acc[entry.value] = entry.label
  return acc
}, {})

const methodOptions = ({
  isEmployee,
  isDeEmployee,
  isLebanon,
  showNewPayoneerIntegration,
  showCoinbase,
  showPaysend,
}) => [
  {
    value: METHODS.BANK.value,
    icon: <i className='bx bxs-bank font-size-16 align-middle mr-2' />,
    label: METHODS.BANK.label,
    show: true,
  },
  {
    value: METHODS.PAYPAL.value,
    icon: <i className='bx bxl-paypal font-size-17 align-middle mr-2' />,
    label: METHODS.PAYPAL.label,
    show: !isEmployee && !isDeEmployee,
  },
  {
    value: METHODS.CASH_POINT.value,
    icon: <i className='bx bxs-bank font-size-17 align-middle mr-2' />,
    label: METHODS.CASH_POINT.label,
    show: isLebanon && !isEmployee && !isDeEmployee,
  },
  {
    value: METHODS.PAYONEER.value,
    icon: (
      <img
        className='mr-2 tw-inline-block'
        style={{ width: 16 }}
        src={payoneerIllustration}
      />
    ),
    label: METHODS.PAYONEER.label,
    show: showNewPayoneerIntegration && !isEmployee && !isDeEmployee,
  },
  {
    value: METHODS.COINBASE.value,
    icon: (
      <img
        className='mr-2 tw-inline-block'
        style={{ width: 16, height: 16 }}
        src={coinbaseIllustration}
      />
    ),
    label: METHODS.COINBASE.label,
    show: showCoinbase && !isEmployee && !isDeEmployee,
  },
  {
    value: METHODS.PAYSEND.value,
    icon: (
      <img
        className='mr-2 tw-inline-block'
        style={{ width: 16, height: 16 }}
        src={paySendIllustration}
      />
    ),
    label: METHODS.PAYSEND.label,
    show: showPaysend && !isEmployee && !isDeEmployee,
  },
]

const WithdrawalMethod = ({ profile, onUpdate, loading }) => {
  const {
    'bank-accounts': bankAccounts = [],
    paypal_accounts: paypalAccounts = [],
    payoneer_accounts: payoneerAccounts = [],
    cash_points: cashPointsAccounts = [],
    coinbase_accounts: coinbaseAccounts = [],
    paysend_card_accounts: paysendCardAccounts = [],
  } = profile ?? {}
  const employeeActivity = useFetch({
    action: getFullTimeContractDetails,
    autoFetch: profile?.contractor_type === 'employee',
  })
  const [method, setMethod] = useState(0)
  const [menu, setMenu] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showWarnAutoWithdrawChange, setShowWarnAutoWithdrawChange] =
    useState(null)
  const [selected, setSelected] = useState({
    id: profile?.prefered_withdraw_method_id,
    type: profile?.prefered_withdraw_method_type,
  })

  const [isAutoWithdraw, setIsAutoWithdraw] = useState(
    profile?.auto_withdraw === 1,
  )
  const [showKycModal, setShowKycModal] = useState(false)

  const user = useSelector((state) => state?.Account?.user)
  const userProfile = useSelector((state) => state?.userProfile?.userProfile)

  const contractorType = userProfile?.contractor_type

  const isDeEmployee = contractorType === BE_CONTRACT_CATEGORY.DIRECT_EMPLOYEE
  const isEmployee = contractorType === BE_CONTRACT_CATEGORY.EMPLOYEE

  const employeesCantManageAW =
    (isEmployee || isDeEmployee) &&
    bankAccounts?.filter((b) => b.active === 1).length > 0

  const location = useLocation()

  const { type } = useParams()
  const code = new URLSearchParams(location.search).get('code')

  const isLebanon = profile?.Country_of_Tax_Residence?.name === 'Lebanon'
  const [showAutoWithdrawInfoModal, setShowAutoWithdrawInfoModal] =
    useState(false)
  const [showAutoWithdrawWarning, setShowAutoWithdrawWarning] = useState(false)

  const [loadingCard, setLoadingCard] = useState(null)

  const history = useHistory()

  const savedMethods = useMemo(() => {
    return [
      ...bankAccounts.map((e) => ({ ...e, type: METHODS.BANK.value })),
      ...paypalAccounts.map((e) => ({
        ...e,
        type: METHODS.PAYPAL.value,
      })),
      ...cashPointsAccounts.map((e) => ({
        ...e,
        type: METHODS.CASH_POINT.value,
      })),
      ...payoneerAccounts.map((e) => ({ ...e, type: METHODS.PAYONEER.value })),
      ...coinbaseAccounts.map((e) => ({ ...e, type: METHODS.COINBASE.value })),
      ...paysendCardAccounts.map((e) => ({
        ...e,
        type: METHODS.PAYSEND.value,
      })),
    ].map((item) => ({ ...item, id: item.id?.toString() }))
  }, [
    bankAccounts,
    cashPointsAccounts,
    coinbaseAccounts,
    payoneerAccounts,
    paypalAccounts,
    paysendCardAccounts,
  ])

  const updateProfile = useFetch({
    action: updateContractorProfile,
    onComplete: (data) => {
      onUpdate()
      if (data.auto_withdraw === '0') {
        setShowAutoWithdrawWarning(true)
      }
      if (data.default_account?.length > 0) {
        setShowAutoWithdrawInfoModal(true)
      }
      setLoadingCard(null)
    },
    onError: (err) => {
      toastr.error(err)
      onUpdate()
    },
  })

  useEffect(() => {
    setSelected({
      id: profile?.prefered_withdraw_method_id,
      type: profile?.prefered_withdraw_method_type,
    })
    setIsAutoWithdraw(profile?.auto_withdraw === 1)
  }, [profile])

  useEffect(() => {
    window.analytics.track('Viewed withdraw methods', {
      email_id: user?.email,
    })
  }, [])

  function updateAutoWithdraw(value) {
    updateProfile.startFetch({ auto_withdraw: value ? 1 : 0 })
    window.analytics.track(
      value ? 'Enabled auto-withdraw' : 'Disabled auto-withdraw',
      { email_id: user?.email },
    )
  }

  const handleAutoWithdrawCheck = (value) => {
    if (value) {
      if (isEmployee || isDeEmployee) {
        updateAutoWithdraw(value)
      } else if (!selected.id) {
        toastr.error('To set auto withdraw you need a default withdraw method')
      } else if (
        ![METHODS.BANK.key, METHODS.PAYONEER.key, METHODS.PAYSEND.key].includes(
          selected.type,
        )
      ) {
        toastr.error(
          'The default withdraw method must be a bank, payoneer account, or a card',
        )
      } else if (selected.id) {
        updateAutoWithdraw(value)
      }
    } else {
      updateAutoWithdraw(value)
    }
  }

  const connect = useFetch({
    action: connectPaypal,
    onComplete: (data) => {
      if (data?.url) {
        window.location.replace(data?.url)
      }
    },
  })

  useFetch({
    action: verifyPaypal,
    autoFetch: type === METHODS.PAYPAL.key,
    body: { code },
    onComplete: () => {
      onUpdate()
      onAccountAdded()
      history.replace(
        location?.pathname.includes('settings')
          ? '/settings/paypal'
          : '/withdrawal-process/paypal',
      )
    },
    onError: (err) => {
      toastr.error(err)
      history.replace(
        location?.pathname.includes('settings')
          ? '/settings/paypal'
          : '/withdrawal-process/paypal',
      )
    },
  })
  useFetch({
    action: verifyCoinbase,
    autoFetch: type === 'coinbase' && !!code,
    body: { code, type: 'settings' },
    onComplete: () => {
      onUpdate()
      history.replace(
        location?.pathname.includes('settings')
          ? '/settings/coinbase'
          : '/withdrawal-process/coinbase',
      )
    },
    onError: (err) => {
      toastr.error(err)
      history.replace(
        location?.pathname.includes('settings')
          ? '/settings/coinbase'
          : '/withdrawal-process/coinbase',
      )
    },
  })

  const onAccountAdded = () => {
    setShowAutoWithdrawInfoModal(true)
  }
  function handleSetCashPointAsDefault() {
    const acc = showWarnAutoWithdrawChange
    setLoadingCard(acc.id)
    updateProfile.startFetch({
      prefered_withdraw_method_id: acc?.id,
      prefered_withdraw_method_type: METHODS.CASH_POINT.key,
    })
    setShowWarnAutoWithdrawChange(null)
    setSelected({ id: acc.id, type: METHODS.CASH_POINT.key })
  }

  const { kyc_doc_missing: kycDocMissing, kyc_verified: kycVerified } =
    userProfile ?? {}

  const requiredInfoKeys = [
    'Country_of_Citizenship',
    'Country_of_Tax_Residence',
    'address',
    'document',
    'birth_date',
    'city',
  ]
  const requiredInfo = Object.entries(userProfile).reduce(
    (acc, [key, value]) => {
      if (requiredInfoKeys.includes(key)) {
        acc[key] = value
      }
      return acc
    },
    {},
  )
  const isMissingRequiredInfo = Object.values(requiredInfo).some(
    (item) => !item,
  )

  const needsKyc = kycVerified !== 1 && !kycDocMissing

  function handleMenuToggle() {
    if (needsKyc) {
      setShowKycModal(true)
    } else {
      setMenu((open) => !open)
    }
  }

  function handleMakeDefault(acc, key) {
    if (!FEATURE_FLAGS.PAYSEND_INTEGRATION && acc?.method_id === PAYSEND.id) {
      toastr.error(
        'Setting this method as default is <strong>temporarily</strong> disabled',
      )

      return
    }

    updateProfile.startFetch({
      prefered_withdraw_method_id: acc?.id,
      prefered_withdraw_method_type: key,
    })
    setLoadingCard(acc.id)
    setSelected({ id: acc.id, type: key })
  }

  return (
    <div>
      {loading ? (
        <Loader minHeight='100vh' />
      ) : (
        <Row className='m-0 p-0'>
          <Col xs={12} md={6} className='m-md-0 p-md-0 px-md-3'>
            <StyledH3
              min='16px'
              max='24px'
              mid='18px'
              className='text-dark text-weight-bold tw-my-4 md:tw-my-6'
            >
              {`Saved ${isDeEmployee ? 'Bank Accounts' : 'Methods'}`}
            </StyledH3>
            <div className='tw-flex tw-flex-col tw-gap-6'>
              {React.Children.toArray(
                savedMethods.map((acc, i) => {
                  switch (acc.type) {
                    case METHODS.BANK.value: {
                      return (
                        <WithdrawMethodCard
                          deactivated={!acc?.active}
                          withTag
                          withRemove
                          isLoading={loadingCard === acc.id}
                          onUpdate={onUpdate}
                          card={acc}
                          index={i}
                          selected={String(acc.id) === String(selected?.id)}
                          onSelect={() =>
                            handleMakeDefault(acc, METHODS.BANK.key)
                          }
                        />
                      )
                    }

                    case METHODS.PAYPAL.value: {
                      return (
                        <PaypalMethodCard
                          isPayoneer={acc.type === 3}
                          withRemove
                          withTag
                          isLoading={loadingCard === acc.id}
                          onUpdate={onUpdate}
                          key={`acc${i}`}
                          account={acc}
                          index={i}
                          selected={
                            JSON.stringify({
                              id: acc.id,
                              type: METHODS.PAYPAL.key,
                            }) === JSON.stringify(selected)
                          }
                          onSelect={() => {
                            updateProfile.startFetch({
                              prefered_withdraw_method_id: acc?.id,
                              prefered_withdraw_method_type: METHODS.PAYPAL.key,
                            })
                            setLoadingCard(acc?.id)
                          }}
                        />
                      )
                    }

                    case METHODS.PAYONEER.value: {
                      if (!FEATURE_FLAGS.NEW_PAYONEER_INTEGRATION) {
                        return (
                          <PaypalMethodCard
                            isPayoneer
                            withRemove
                            withTag
                            onUpdate={onUpdate}
                            key={`acc${i}`}
                            account={acc}
                            isLoading={loadingCard === acc.id}
                            index={i}
                            selected={
                              JSON.stringify({
                                id: acc.id,
                                type: METHODS.PAYPAL.key,
                              }) === JSON.stringify(selected)
                            }
                            onSelect={() => {
                              updateProfile.startFetch({
                                prefered_withdraw_method_id: acc?.id,
                                prefered_withdraw_method_type:
                                  METHODS.PAYPAL.key,
                              })
                              setLoadingCard(acc?.id)
                            }}
                          />
                        )
                      }
                      return (
                        <PayoneerMethodCard
                          key={`acc${i}`}
                          account={acc}
                          onUpdate={onUpdate}
                          isLoading={loadingCard === acc.id}
                          selected={
                            JSON.stringify({
                              id: acc.id,
                              type: METHODS.PAYONEER.key,
                            }) === JSON.stringify(selected)
                          }
                          onSelect={() => {
                            updateProfile.startFetch({
                              prefered_withdraw_method_id: acc.id,
                              prefered_withdraw_method_type:
                                METHODS.PAYONEER.key,
                            })
                            setLoadingCard(acc?.id)
                          }}
                        />
                      )
                    }

                    case METHODS.COINBASE.value: {
                      return (
                        <CoinbaseMethodCard
                          withRemove
                          withTag
                          onUpdate={onUpdate}
                          key={`acc${i}`}
                          account={acc}
                          index={i}
                          selected={
                            JSON.stringify({
                              id: acc.id,
                              type: METHODS.COINBASE.key,
                            }) === JSON.stringify(selected)
                          }
                          onSelect={() => {
                            updateProfile.startFetch({
                              prefered_withdraw_method_id: acc?.id,
                              prefered_withdraw_method_type:
                                METHODS.COINBASE.key,
                            })
                            setLoadingCard(acc?.id)
                          }}
                        />
                      )
                    }

                    case METHODS.CASH_POINT.value: {
                      return (
                        <CashPointCard
                          withTag
                          withRemove
                          onUpdate={onUpdate}
                          card={acc}
                          index={i}
                          isLoading={loadingCard === acc.id}
                          selected={
                            JSON.stringify({
                              id: acc.id,
                              type: METHODS.CASH_POINT.key,
                            }) === JSON.stringify(selected)
                          }
                          onSelect={() => setShowWarnAutoWithdrawChange(acc)}
                        />
                      )
                    }

                    case METHODS.PAYSEND.value: {
                      const account = { ...acc, id: acc?.account_id }

                      return (
                        <CardMethod
                          account={account}
                          withRemove
                          onUpdate={onUpdate}
                          isLoading={loadingCard === account.id}
                          selected={String(account.id) === String(selected?.id)}
                          makeDefault={() =>
                            handleMakeDefault(account, METHODS.PAYSEND.key)
                          }
                        />
                      )
                    }

                    default: {
                      return 'Method not supported ' + acc.type
                    }
                  }
                }),
              )}
            </div>

            <div className='tw-flex tw-justify-center'>
              <Dropdown
                className='tw-my-6 tw-flex tw-justify-center'
                isOpen={menu}
                toggle={handleMenuToggle}
              >
                <span id='withdraw-method-dropdown-toggle'>
                  <DropdownToggle
                    id='withdraw-method-dropdown m-4'
                    tag={Button}
                    icon={<Plus size={16} />}
                    disabled={isMissingRequiredInfo}
                  >
                    Add new account
                  </DropdownToggle>

                  {!isMissingRequiredInfo ? null : (
                    <UncontrolledTooltip target='withdraw-method-dropdown-toggle'>
                      Please complete your general info.
                    </UncontrolledTooltip>
                  )}
                </span>
                <DropdownMenu>
                  {methodOptions({
                    isEmployee,
                    isDeEmployee,
                    isLebanon,
                    showNewPayoneerIntegration:
                      payoneerAccounts.length <= 0 &&
                      FEATURE_FLAGS.NEW_PAYONEER_INTEGRATION,
                    showCoinbase:
                      userProfile?.withdraw_method_ids?.includes('9'),
                    showPaysend: FEATURE_FLAGS.PAYSEND_INTEGRATION,
                  })?.map(({ icon, label, show, value }) => {
                    if (!show) return null

                    return (
                      <DropdownItem
                        onClick={() => {
                          setMethod(value)
                          if (value === METHODS.PAYPAL.value) {
                            connect.startFetch({ type: 'settings' })
                          } else {
                            setShowModal(true)
                          }
                        }}
                        key={value}
                      >
                        {icon}
                        {label}
                      </DropdownItem>
                    )
                  })}
                </DropdownMenu>
              </Dropdown>
            </div>
          </Col>

          {!isDeEmployee && (
            <Col xs={12} md={6} className='tw-px-6 tw-py-6'>
              <StyledH3
                min='16px'
                max='24px'
                mid='18px'
                className='text-dark text-weight-bold tw-mb-4'
              >
                Auto-withdraw
              </StyledH3>
              <p
                className='font-size-14 text-secondary mb-4'
                style={{ fontWeight: '400' }}
              >
                By activating the auto-withdraw option, your funds will be
                automatically transferred to your default payment account every
                time you receive a new payment.
              </p>
              <label className='tw-flex tw-cursor-pointer'>
                <Toggle
                  check={isAutoWithdraw}
                  change={(e) => handleAutoWithdrawCheck(e.target.checked)}
                  disabled={
                    updateProfile.isLoading || loading || employeesCantManageAW
                  }
                />

                <span
                  className='font-size-14 text-secondary tw-mb-4 md:tw-mb-6'
                  style={{ fontWeight: '400' }}
                >
                  Auto-withdraw to default account
                </span>
              </label>
            </Col>
          )}
        </Row>
      )}

      <Modal
        className={
          [METHODS.PAYSEND.value].includes(method)
            ? undefined
            : 'newAccountModal'
        }
        isOpen={showModal}
        centered
        role='dialog'
        size={[METHODS.COINBASE.value].includes(method) ? 'sm' : undefined}
        toggle={
          method === METHODS.PAYONEER.value ? null : () => setShowModal(false)
        }
      >
        <ModalHeader toggle={() => setShowModal(false)}>
          Add {modalTitle[method]} account
        </ModalHeader>

        <ModalBody className={`${method === 0 ? 'pb-0' : 'p-0'}`}>
          {method === METHODS.BANK.value && (
            <BankAccounts
              noCards
              isEdit
              currency={
                profile?.contractor_type === 'employee'
                  ? employeeActivity?.data?.salary_currency?.code
                  : profile?.currency?.code
              }
              isEmployee={profile?.contractor_type === 'employee'}
              onAdd={(_, res) => {
                onUpdate()
                onAccountAdded()
                setShowModal(false)
                if (isEmployee || isDeEmployee) {
                  handleAutoWithdrawCheck(true)
                }
                window.analytics.track('Added withdraw methods', {
                  withdraw_account_name: res.accountHolderName,
                  withdraw_method: 'bank Transfer',
                  currency: res.currency,
                  country: res.country,
                  is_default: false,
                })
              }}
              onCancel={() => setShowModal(false)}
            />
          )}
          {method === METHODS.PAYPAL.value && (
            <PaypalAccounts
              isEdit
              noCards
              onAdd={() => {
                onUpdate()
                onAccountAdded()
                setShowModal(false)
                window.analytics.track('Added withdraw methods', {
                  withdraw_method: METHODS.PAYPAL.label,
                  is_default: false,
                })
              }}
              onCancel={() => setShowModal(false)}
            />
          )}
          {method === METHODS.CASH_POINT.value && (
            <CashPointAccount
              onCancel={() => setShowModal(false)}
              onAdd={() => {
                onUpdate()
                onAccountAdded()
                setShowModal(false)
              }}
            />
          )}
          {method === METHODS.PAYONEER_EMAIL.value && (
            <PaypalAccounts
              isPayoneer
              isEdit
              noCards
              onAdd={() => {
                onUpdate()
                onAccountAdded()
                setShowModal(false)
                window.analytics.track('Added withdraw methods', {
                  withdraw_method: METHODS.PAYONEER_EMAIL.key,
                  is_default: false,
                })
              }}
              onCancel={() => setShowModal(false)}
            />
          )}

          {method === METHODS.PAYONEER.value && <PayoneerAccount />}

          {method === METHODS.COINBASE.value && (
            <CoinbaseAccounts
              isEdit
              noCards
              onAdd={() => {
                onUpdate()
                onAccountAdded()
                setShowModal(false)
                window.analytics.track('Added withdraw methods', {
                  withdraw_method: METHODS.COINBASE.label,
                  is_default: false,
                })
              }}
              onCancel={() => setShowModal(false)}
            />
          )}

          {method === METHODS.PAYSEND.value && (
            <AddNewCardModalForm
              toggle={() => setShowModal(false)}
              onAdd={() => {
                onUpdate()
                onAccountAdded()
                setShowModal(false)
              }}
            />
          )}
        </ModalBody>
      </Modal>

      <Modal
        isOpen={showAutoWithdrawInfoModal}
        role='dialog'
        centered
        toggle={() => setShowAutoWithdrawInfoModal(false)}
      >
        <ModalBody className='tw-justify-center tw-p-4'>
          <div className='tw-flex tw-justify-center tw-py-6'>
            <Info size={100} weight='duotone' color='var(--primary)' />
          </div>
          <h1 className='text-center font-size-16' style={{ fontWeight: 700 }}>
            The default account was set successfully.
          </h1>
          <p className='text-center'>
            Your funds will be automatically sent your default account every
            time you receive a payment.
          </p>
        </ModalBody>
        <ModalFooter className='justify-content-center'>
          <button
            onClick={() => setShowAutoWithdrawInfoModal(false)}
            className='btn btn-primary'
          >
            Okay
          </button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={showAutoWithdrawWarning}
        centered
        toggle={() => setShowAutoWithdrawWarning(false)}
      >
        <ModalBody className='tw-justify-center tw-p-4'>
          <div className='tw-flex tw-justify-center tw-py-6'>
            <img
              style={{ height: 100, width: 100 }}
              src={warningIllustration}
              alt=''
            />
          </div>
          <h1
            className='font-size-16 tw-text-center'
            style={{ fontWeight: '700' }}
          >
            Auto-withdraw is now disabled
          </h1>
          <p className='tw-text-center'>
            You will need to withdraw your funds manually. Note that you must
            withdraw your funds within 30 days of receipt.
          </p>
        </ModalBody>
        <ModalFooter className='justify-content-center'>
          <button
            onClick={() => setShowAutoWithdrawWarning(false)}
            className='btn btn-primary'
          >
            Okay
          </button>
        </ModalFooter>
      </Modal>

      <KYCModal
        isOpen={showKycModal}
        toggle={() => setShowKycModal(false)}
        proceed={() => {
          history.push('/settings#kyc')
          setShowKycModal(false)
        }}
        message='You need to complete your KYC to add a new account.'
      />

      <WarnAutoWithdrawChange
        isOpen={!!showWarnAutoWithdrawChange}
        toggle={() => setShowWarnAutoWithdrawChange(null)}
        onConfirm={handleSetCashPointAsDefault}
      />
    </div>
  )
}

function WarnAutoWithdrawChange({ isOpen, toggle, onConfirm }) {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>Auto-withdraw will be disabled</ModalHeader>

      <ModalBody>
        By changing your default account, auto-withdraw will be disabled. You
        will need to withdraw your funds manually. Note that you must withdraw
        your funds within 30 days of receipt.
      </ModalBody>

      <ModalFooter>
        <Button onClick={toggle} color='light' outline>
          Cancel
        </Button>
        <Button onClick={onConfirm}>Proceed</Button>
      </ModalFooter>
    </Modal>
  )
}

export default WithdrawalMethod
