import React from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import toastr from 'toastr'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { PaperPlaneRight } from '@phosphor-icons/react'

import { useFetch } from '../../../helpers/hooks'
import ControlledInput from '../../../components/ControlledInput'
import { ControlledToggle } from '../../../components/Forms/Toggle/controlled-toggle'
import { sendContractImportInvites } from '../../../services/api-bulk-operations'
import { ModalCloseButton } from '../../../components/Common/modal-close-button'
import Button from '../../../components/ui/button'
import { updateUserProfileInfo } from '../../../store/profile/actions'

const inviteFormId = 'invite-form'

const BulkInvitationModal = ({ show, hide, message, data, onUpdate }) => {
  const user = useSelector((state) => state.userProfile?.userProfile)
  const history = useHistory()
  const dispatch = useDispatch()

  const { startFetch: sendInvites, isLoading: isSendingInvites } = useFetch({
    action: sendContractImportInvites,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error('Failed to send invites')
      } else {
        toastr.success(
          'Your workers will be notified via email',
          `${data?.processes?.length} invitations sent!`,
        )
        if (data?.save_custom_message) {
          dispatch(
            updateUserProfileInfo({
              custom_invite_message: data?.custom_message,
            }),
          )
        }
        onUpdate(false)
        history.push('/contracts')
      }
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  const userDefaultText = user?.custom_invite_message ?? undefined

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      items: [{ customText: userDefaultText, saveMessageInvitation: true }],
    },
    resolver: yupResolver(
      yup.object().shape({
        customText: yup.string().label('Custom Text'),
        saveMessageInvitation: yup.boolean().label(''),
      }),
    ),
  })

  function onSubmit(values) {
    sendInvites({
      id: data?.id,
      custom_message: values?.customText,
      save_custom_message: Number(values?.saveMessageInvitation),
    })
  }

  function handleClose() {
    reset()
    hide()
    history.push('/contracts')
  }

  return (
    <Modal
      className='custom-modal-style'
      isOpen={show}
      centered
      size='lg'
      toggle={handleClose}
    >
      <ModalHeader
        className='p-4'
        close={<ModalCloseButton toggle={handleClose} />}
      >
        {message}
      </ModalHeader>
      <ModalBody className='tw-flex tw-flex-col tw-p-4'>
        <>
          <p className='tw-mb-2 tw-text-xl tw-font-semibold tw-text-secondary-120'>
            You imported {data?.successFullImports?.length} contracts
            successfully
          </p>

          <p className='tw-text-sm tw-font-medium tw-text-text-80'>
            Do you want to send invites to all the workers to their respective
            emails?
          </p>
        </>

        <form onSubmit={handleSubmit(onSubmit)} id={inviteFormId}>
          <ControlledInput
            control={control}
            id='scope'
            name='customText'
            type='textarea'
            defaultValue={userDefaultText}
            label={
              <p className='tw-mb-0 tw-text-base'>
                Message <span className='tw-font-normal'>(Optional)</span>
              </p>
            }
            placeholder='Invite Message...'
            rows={6}
            className='tw-mb-4 tw-scroll-pb-3'
          />
          <div className='tw-rounded tw-border tw-border-secondary-40 tw-p-3'>
            <div className='tw-flex tw-items-center tw-justify-between tw-gap-4'>
              <div className='tw-text-wrap'>
                <p className='tw-mb-0 tw-text-sm tw-font-semibold'>
                  Save this message for the next invitation
                </p>
                <p className='font-size-14 tw-mb-0 tw-text-wrap'>
                  When this is toggled off, a standard RemotePass invitation
                  message will be displayed here for the next time.
                </p>
              </div>
              <ControlledToggle
                defaultValue={true}
                control={control}
                name='saveMessageInvitation'
                id='saveMessageInvitation'
              />
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter className='!tw-p-6 [&>*]:tw-min-w-[146px]'>
        <Button
          outline
          className='tw-flex-1 !tw-border-surface-30 !tw-text-black hover:!tw-bg-surface-130 md:tw-flex-grow-0'
          disabled={isSendingInvites}
          onClick={handleClose}
          type='button'
        >
          Do it later
        </Button>
        <Button
          loading={isSendingInvites}
          disabled={isSendingInvites}
          formId={inviteFormId}
          type='submit'
          form
          className='tw-flex-1 md:tw-flex-grow-0'
          icon={<PaperPlaneRight size={20} />}
        >
          Invite all workers
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default BulkInvitationModal
