import React, { createContext, useContext } from 'react'
import { useFetch } from '../../../../../helpers/hooks'
import toastr from 'toastr'
import {
  getSlackRecipients,
  getSlackUrl,
  getSlackWorkspaces,
} from '../../../../../services/api-automations'

export const SlackConnectionContext = createContext()

export function SlackConnectionProvider(props) {
  const { children } = props
  const {
    startFetch: fetchSlackWorkspaces,
    data = [],
    isLoading,
  } = useFetch({
    action: getSlackWorkspaces,
    autoFetch: true,
    isOpenApi: true,
  })

  const {
    data: slackRecipients,
    isLoading: isLoadingRecipients,
    startFetch: fetchSlackRecipients,
  } = useFetch({
    action: getSlackRecipients,
    autoFetch: false,
    isOpenApi: true,
  })

  const { startFetch: fetchSlackUrl, isLoading: isLoadingUrl } = useFetch({
    action: getSlackUrl,
    autoFetch: false,
    isOpenApi: true,
    onComplete: ({ authUrl }) => {
      if (authUrl) {
        const openWindow = window.open(
          authUrl,
          'SlackAuthWindow',
          'popup=true,left=400,top=100,width=620,height=620',
        )
        if (!openWindow) {
          toastr.info(
            'Window not opened, might be due to built-in popup blockers',
          )
        } else {
          openWindow.opener.addEventListener('focus', () => {
            fetchSlackWorkspaces()
          })
        }
      }
    },
  })

  const handleWorkspaceChange = (id) => fetchSlackRecipients({ id })

  return (
    <SlackConnectionContext.Provider
      value={{
        handleWorkspaceChange,
        isLoading,
        isLoadingUrl,
        isLoadingRecipients,
        workspaces: data,
        recipients: slackRecipients,
        connectSlack: fetchSlackUrl,
        fetchSlackWorkspaces,
      }}
    >
      {children}
    </SlackConnectionContext.Provider>
  )
}

export function useSlackConnection() {
  return useContext(SlackConnectionContext)
}
